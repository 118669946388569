import { ColumnsType } from 'antd/es/table';
import { TTopFCForTable } from '@/types/request';

export const topFCColumns: ColumnsType<TTopFCForTable> = [
  {
    title: 'ФИО ФК',
    dataIndex: 'name',
    key: 'name',
    width: 250,
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              background: '#F1F2F4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p
              style={{ fontSize: '10px', fontWeight: '600', color: '#687588' }}
            >
              {record?.name[0] || ''}
            </p>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <h2
              style={{ fontSize: '12px', fontWeight: '600', color: '#111827' }}
            >
              {record?.name || ''}
            </h2>
          </div>
        </div>
      );
    }
  },
  {
    title: 'Заведено заявок',
    dataIndex: 'totalcount',
    key: 'totalcount',
    width: 130,
    sorter: (a, b) => Number(a.totalcount) - Number(b.totalcount),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {record?.totalcount || ''}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Одобрено/Профинансировано',
    dataIndex: 'approvedcount',
    key: 'approvedcount',
    width: 100,
    sorter: (a, b) => Number(a.approvedcount) - Number(b.approvedcount),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {record?.approvedcount || ''}
          </p>
        </div>
      );
    }
  }
];
