import { BundledEditor } from './Bundle.js';

type TTextEditorProps = {
  basicHTML: string;
  onChange: (data: any) => void;
};
export const TextEditor = ({ basicHTML, onChange }: TTextEditorProps) => {
  return (
    <>
      <BundledEditor
        value={basicHTML}
        // @ts-ignore
        onEditorChange={(e) => {
          onChange(e);
        }}
        init={{
          height: 600,
          width: '100%',
          selector: 'textarea#default',
          menubar: true,
          advcode_inline: true,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'wordcount',
            'advcode'
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help | code',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    </>
  );
};
