import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { getFlashLogsByParam } from '@/store/admin/adminLogs.slice';
import Column from 'antd/es/table/Column';
import { TFlashLog } from '@/types/flashLog.type';
import SearchInput from '@/components/Shared/Input/SearchInput';
import { FlashLogModal } from '@/components/AdminPanel/Modals/FlashLogModal';
import '@/styles/AdminModal.scss';
import { setShowModalFlashLog } from '@/store/admin/admin.slice';

export const FlashLogs: FC = () => {
  const dispatch = useAppDispatch();
  const { showFlashLogModal } = useAppSelector((state) => state.admin);
  const [findByParam, setFindByParam] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>('');
  const { flashLogs, total, isLoading } = useAppSelector(
    (state) => state.adminLogs
  );
  const [selectedLog, setSelectedLog] = useState<TFlashLog | null>(null);
  const [paginationData, setPaginationData] = useState<{
    limit: number;
    page: number;
  }>({ limit: 10, page: 1 });
  useEffect(() => {
    dispatch(
      getFlashLogsByParam({
        param: searchParam,
        limit: paginationData.limit,
        page: paginationData.page
      })
    );
  }, [paginationData]);

  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput
          style={{ width: '50%', marginRight: '10px' }}
          onSearch={(value) => {
            setSearchParam(value.trim());
            setFindByParam(true);
            setPaginationData((prevState) => ({
              ...prevState,
              page: 1
            }));
            dispatch(
              getFlashLogsByParam({
                param: value,
                limit: paginationData.limit,
                page: paginationData.page
              })
            );
          }}
        />
        {showFlashLogModal && <FlashLogModal flashLog={selectedLog} />}
      </div>
      <Table
        rowClassName={'flash-log-modal'}
        size="small"
        rowKey="uuid"
        dataSource={flashLogs}
        loading={isLoading}
        pagination={{
          style: { width: '500px', justifyContent: 'flex-start' },
          total: total - 1,
          pageSize: paginationData.limit,
          current: paginationData.page,
          onChange: (step: number, itemsPerPage: number) => {
            setPaginationData({
              page: step,
              limit: itemsPerPage
            });
          }
        }}
      >
        <Column
          key="create_date"
          title="Дата создания"
          dataIndex="create_date"
          render={(_, record: TFlashLog) => {
            return new Date(record.create_date).toLocaleString();
          }}
          width="100px"
        />
        <Column
          key="bank"
          title="Банк"
          dataIndex="bank"
          width="80px"
          ellipsis={true}
        />
        <Column
          key="ext_uuid"
          title="ext_uuid"
          dataIndex="ext_uuid"
          width="120px"
          ellipsis={true}
        />
        <Column
          key="request_id"
          title="Request_id"
          dataIndex="request_id"
          width="120px"
        />
        <Column
          key="execution_name"
          title="execution name"
          dataIndex="execution_name"
          width="100px"
          ellipsis={true}
        />
        <Column
          key="execution_id"
          title="execution_id"
          dataIndex="execution_id"
          width="100px"
        />
        <Column
          className="flash-log-modal-table-cell"
          key="description"
          title="description"
          dataIndex="description"
          width={'300px'}
          ellipsis={{ showTitle: true }}
          onCell={(record: TFlashLog) => ({
            onClick: () => {
              setSelectedLog(record);
              dispatch(setShowModalFlashLog(true));
            }
          })}
        />
      </Table>
    </>
  );
};
