import React, { useEffect, useState } from 'react';
import '@/styles/Reports.scss';
import {
  Button,
  DatePicker,
  Input,
  notification,
  Radio,
  Select,
  Spin
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '@/store';
import { TTemplate } from '@/types/templates';
import { getTemplates, redactTemplate } from '@/store/templates.slice';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { TextEditor } from '@/components/App/Reports/TextEditor/TextEditor';
import TemplateBlock from '@/components/App/Reports/TemplateBlock';
import TemplateCreateBlock from '@/components/App/Reports/TemplateCreateBlock';
import { downloadFilesApi } from '@/integrations/index.api';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import { TModule } from '@/types/user';
import { useLocation } from 'react-router-dom';
import { getDictionary, getOptimizedDictionaries } from '@/store/dict.slice';

const { TextArea } = Input;
const Reports = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { dicts, dictStatus } = useAppSelector((state) => state.dict);
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { templates, isLoading } = useAppSelector((state) => state.templates);
  const [_isLoading, _setIsLoading] = useState<boolean>(false);
  const [templateType, setTemplateType] = useState<TModule | undefined>();
  const [filteredTemplates, setFilteredTemplates] = useState<TTemplate[]>([]);

  const [showParamModal, setShowParamModal] = useState<boolean>(false);
  const [statusOption, setStatusOption] = useState<
    { value: string; label: string }[]
  >([]);
  const [dealerOption, setDealerOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedStatus, setSelectedStatus] = useState<string | null>('all');
  const [selectedDealer, setSelectedDealer] = useState();

  const [textRedactorTemplate, setTextRedactorTemplate] = useState<string>('');
  const [showTemplateRedactor, setShowTemplateRedactor] =
    useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TTemplate | null>(
    null
  );
  const [datesValues, setDatesValues] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: dayjs(new Date()).format('YYYY-MM-DD')
  });
  const [isRedactingTplValues, setIsRedactingTplValues] =
    useState<boolean>(false);
  const [isCreateTemplate, setIsCreateTemplate] = useState<boolean>(false);
  const [tplValues, setTplValues] = useState<string>('');
  const handleRedactTemplate = (template: TTemplate) => {
    setIsCreateTemplate(false);
    setSelectedTemplate(template);
    setShowTemplateRedactor(true);
    setTextRedactorTemplate(template?.src || '');
    setIsRedactingTplValues(false);
  };

  const handleOpenTemplateValuesRedactor = (template: TTemplate) => {
    setIsCreateTemplate(false);
    setSelectedTemplate(template);
    setTplValues(template?.tpl_values || '');
    setShowTemplateRedactor(true);
    setIsRedactingTplValues(true);
  };

  const handleShowNewTemplateForm = () => {
    setIsCreateTemplate(true);
    setTextRedactorTemplate(
      '<p>This is the initial content of the editor.</p>'
    );
    setShowTemplateRedactor(true);
  };

  const handleDownloadTemplate = async () => {
    if (datesValues.endDate && !datesValues.startDate) {
      return notification.warning({
        message: '',
        description: 'Выберите начальную дату',
        duration: 5
      });
    }
    try {
      _setIsLoading(true);
      const downloadData = await downloadFilesApi.downloadTemplate({
        template: selectedTemplate ? selectedTemplate.code : '',
        type: templateType ? templateType.code : '',
        values: JSON.stringify({
          startDate: dayjs(datesValues.startDate).format('YYYY-MM-DD'),
          endDate: datesValues.endDate
            ? datesValues.endDate
            : dayjs(new Date()).format('YYYY-MM-DD'),
          status: selectedStatus,
          dealer: selectedDealer
        })
      });
      const link = document.createElement('a');
      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${downloadData}`;
      link.download = selectedTemplate ? selectedTemplate.code : '';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      _setIsLoading(false);
      setShowParamModal(false);
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const saveTemplateChanges = () => {
    if (!isCreateTemplate) {
      dispatch(
        redactTemplate({
          templateId: selectedTemplate?.uuid || '',
          template: {
            code: selectedTemplate?.code || '',
            name: selectedTemplate?.name || '',
            template: textRedactorTemplate,
            values: selectedTemplate?.tpl_values || ''
          }
        })
      );
    }
  };

  const changeTemplateSrc = (data: string) => {
    setTextRedactorTemplate(data);
    setSelectedTemplate(
      (prevState) => ({ ...prevState, src: data } as TTemplate)
    );
  };

  const changeTemplateValues = (data: string) => {
    setTplValues(data);
    setSelectedTemplate(
      (prevState) => ({ ...prevState, tpl_values: data } as TTemplate)
    );
  };

  useEffect(() => {
    dispatch(getTemplates());
    const initialTemplateType = userInfo?.frontendBlock?.find(
      (item) => item.code === 'autocred' || item.code === 'reports'
    );

    if (initialTemplateType) {
      setTemplateType(initialTemplateType);
    }
  }, [userInfo?.frontendBlock, dispatch]);

  useEffect(() => {
    if (userInfo?.position.code !== 6) {
      const filteredTempl = templates.filter(
        (templ) => templ.module_uuid === templateType?.uuid
      );

      if (filteredTempl) {
        setFilteredTemplates(filteredTempl);
      }
    } else {
      if (templates) setFilteredTemplates(templates);
    }


    if (dicts) {
      const statuses = findDictByTitle(DictTypes.Statuses, dicts)
        .content.filter((status) => {
          if (templateType?.code === 'insurance') {
            return (
              status.code.toString() === 'completed' ||
              status?.code.toString() === 'waiting_of_payment' ||
              status?.code.toString() === 'draft'
            );
          } else if (templateType?.code === 'autocred') {
            return (
              status.code.toString() === 'financed' ||
              status.code.toString() === 'approved' ||
              status.code.toString() === 'declined'
            );
          } else if (templateType?.code === 'reports') {
            return (
              status.code.toString() === 'financed' ||
              status.code.toString() === 'approved' ||
              status.code.toString() === 'declined'
            );
          }
        })
        .map((item) => ({
          value: item.code.toString(),
          label:
            templateType?.code === 'insurance' && item.name === 'Завершено'
              ? 'Полис выдан'
              : item.name
        }));

      statuses.unshift({ value: 'all', label: 'Все' });

      setStatusOption(statuses);
    }
    if (dicts) {
      const dealers = findDictByTitle(DictTypes.Dealers, dicts)
        .content.filter((dealer) => dealer.is_active)
        .map((dealer) => ({ value: dealer.uuid, label: dealer.name }));
      setDealerOptions(dealers);
    }
  }, [templateType, userInfo, templates, dicts]);

  useEffect(() => {
    if (dictStatus === 'idle') {
      dispatch(getOptimizedDictionaries());
    }
  }, [dictStatus, dispatch]);

  return (
    <div className="Reports">
      <CustomModal
        editClass="prevClientInfo"
        handleCancel={() => setShowParamModal(false)}
        handleOk={() => setShowParamModal(false)}
        isModalOpen={showParamModal}
        title="Выберите параметры"
        footer={[
          <Button key="back" onClick={() => setShowParamModal(false)}>
            Отмена
          </Button>,
          <Button
            disabled={_isLoading}
            style={{ background: '#ff2e1f' }}
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={handleDownloadTemplate}
          >
            {_isLoading ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              'Скачать'
            )}
          </Button>
        ]}
      >
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '16px'
            }}
          >
            <DatePicker
              style={{ width: '200px' }}
              value={
                datesValues.startDate ? dayjs(datesValues.startDate) : null
              }
              onChange={(value) =>
                setDatesValues((prevState) => ({
                  ...prevState,
                  startDate: value ? dayjs(value).format('YYYY/MM/DD') : null
                }))
              }
              placeholder="Начальная дата"
              name="start_date"
            />
            <DatePicker
              style={{ width: '200px', marginLeft: '10px' }}
              value={datesValues.endDate ? dayjs(datesValues.endDate) : null}
              onChange={(value) =>
                setDatesValues((prevState) => ({
                  ...prevState,
                  endDate: value ? dayjs(value).format('YYYY/MM/DD') : null
                }))
              }
              placeholder="Конечная дата"
              name="start_date"
            />
          </div>
          {(selectedTemplate?.code === 'payments_tpl' ||
            selectedTemplate?.code === 'insurance_report') && (
            <Select
              style={{ marginTop: '10px' }}
              options={statusOption}
              value={selectedStatus}
              onChange={(value) => setSelectedStatus(value)}
            />
          )}
          {selectedTemplate?.code === 'report_dc' && (
            <Select
              style={{ marginTop: '10px' }}
              mode="multiple"
              // maxCount={1}
              maxTagCount={1}
              options={dealerOption}
              value={selectedDealer}
              onChange={(value) => setSelectedDealer(value)}
            />
          )}
        </>
      </CustomModal>
      <Spin
        spinning={isLoading}
        fullscreen
        size="large"
        style={{
          zIndex: '10000'
        }}
        indicator={
          <LoadingOutlined style={{ fontSize: 70, color: 'whitesmoke' }} spin />
        }
      />
      <h2>Выберите направление</h2>
      <Radio.Group
        defaultValue="a"
        value={templateType?.uuid}
        onChange={(e) => {
          const selectedTemplate = userInfo?.frontendBlock?.find(
            (block) => block.uuid === e.target.value
          );
          if (selectedTemplate) {
            setTemplateType(selectedTemplate);
          }
        }}
      >
        {userInfo?.frontendBlock ? (
          userInfo?.frontendBlock
            .slice()
            .filter(
              (block) =>
                block.code !== 'main' &&
                block.code !== 'reports' &&
                block.code !== 'clients' &&
                block.is_active
            )
            .sort((a, b) => a.position - b.position)
            .map((item) => {
              return (
                <Radio.Button
                  className="radio-btn"
                  value={item.uuid}
                  key={item.code}
                >
                  {item.name}
                </Radio.Button>
              );
            })
        ) : (
          <></>
        )}
      </Radio.Group>

      <CustomModal
        editClass="RedactTemplateModal"
        isModalOpen={showTemplateRedactor}
        title={
          isCreateTemplate
            ? 'Заполните данные шаблона'
            : 'Внесите изменения в шаблон'
        }
        handleOk={() => setShowTemplateRedactor(false)}
        handleCancel={() => setShowTemplateRedactor(false)}
        footer={[
          <Button key="back" onClick={() => setShowTemplateRedactor(false)}>
            Отмена
          </Button>,
          <Button
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={saveTemplateChanges}
          >
            {isCreateTemplate ? 'Создать' : 'Сохранить'}
          </Button>
        ]}
      >
        <>
          {isCreateTemplate && (
            <>
              <div className="NewTemplateInput_root">
                <CustomInput
                  label="name"
                  labelTitle="Укажите название шаблона"
                  type="template"
                  value={''}
                  fieldsReadOnly={false}
                  onChangeInputHandler={() => {}}
                />
              </div>
              <div className="NewTemplateInput_root">
                <CustomInput
                  label="name"
                  labelTitle="Укажите код шаблона"
                  type="template"
                  value={''}
                  fieldsReadOnly={false}
                  onChangeInputHandler={() => {}}
                />
              </div>
            </>
          )}
          {!isRedactingTplValues ? (
            <TextEditor
              onChange={changeTemplateSrc}
              basicHTML={textRedactorTemplate}
            />
          ) : (
            <TextArea
              rows={4}
              style={{ height: '50vh' }}
              onChange={(e) => changeTemplateValues(e.target.value)}
              value={tplValues}
            />
          )}
        </>
      </CustomModal>
      <div className="Reports_templates_wrapper">
        {filteredTemplates.length > 0 &&
          filteredTemplates
            .filter((item) => {
              if (!location.pathname.includes('adminPage')) {
                return item.name.includes('.xlsx');
              } else {
                return item;
              }
            })
            .map((template) => {
              return (
                <TemplateBlock
                  key={template.uuid}
                  template={template}
                  handleDownloadTemplate={() => {
                    setSelectedTemplate(template);

                    setShowParamModal(true);
                  }} //{handleDownloadTemplate}
                  handleRedactTemplate={handleRedactTemplate}
                  handleRedactTemplateValues={handleOpenTemplateValuesRedactor}
                />
              );
            })}
        {location.pathname.includes('adminPage') && (
          <TemplateCreateBlock
            handleShowNewTemplateForm={handleShowNewTemplateForm}
          />
        )}
      </div>
    </div>
  );
};

export default Reports;
