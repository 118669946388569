import { useEffect, useState } from 'react';
import '@/styles/FreedomBank.scss';
import { useAppDispatch } from '@/store';
import { onSetUploadedFiles } from '@/store/financingStep.slice';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import { FileUpload } from '@/components/Shared/Input/FileUpload';
import { checkArrayValues } from '@/services/validators/checkArrayValues';

export type TFreedomBankProps = {
  changeFreedomValidStatus: (isValid: boolean) => void;
};
export const FreedomBank = ({
  changeFreedomValidStatus
}: TFreedomBankProps) => {
  const [uploadFiles, setUploadFiles] = useState({});
  const dispatch = useAppDispatch();
  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {
    setUploadFiles((prevState) => ({ ...prevState, [type]: fileBase64 }));
  };

  useEffect(() => {
    const isValid = Object.values(uploadFiles).length
      ? checkArrayValues(Object.values(uploadFiles))
      : false;
    isValid && dispatch(onSetUploadedFiles(uploadFiles));
    changeFreedomValidStatus(isValid);
  }, [uploadFiles]);

  return (
    <div className="freedomBank">
      <FileDownload prevStateTitle={'Гарантийное письмо'} fileLink={'#'} />
      <div className="cols">
        <div className="col">
          <FileDownload prevStateTitle={'Заверение'} fileLink={'#'} />
        </div>
        <div className="col">
          <FileUpload
            customeStyles={{ margin: '15px 0' }}
            positionLeft={'51px'}
            marginLeft={'35px'}
            fileNameMargin={'20px'}
            isLg={true}
            onFileChange={handleFileChange}
            title={'Заверение'}
            type={'finished'}
            preventActions={false}
          />
        </div>
      </div>
    </div>
  );
};
