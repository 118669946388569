import { FC } from 'react';
import Column from 'antd/es/table/Column';
import { TDealersBanks, TDealersProducts } from '@/types/dealer.type';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { Divider, Table, Typography } from 'antd';
import '@/styles/AdminModal.scss';

type TDealersProductsTableProps = {
  dealersProducts: TDealersProducts[];
  setDealersProducts: (agr: TDealersProducts[]) => void;
};
export const DealersProductsTable: FC<TDealersProductsTableProps> = ({
  dealersProducts,
  setDealersProducts
}) => {
  return (
    <>
      <Divider>
        <Typography.Title level={4}>Продукты</Typography.Title>
      </Divider>
      <Table
        bordered={true}
        key="table"
        pagination={false}
        rowKey="product_uuid"
        dataSource={dealersProducts}
        size="small"
      >
        <Column
          key="bank_uuid"
          title="Наименование"
          dataIndex="product_uuid"
          render={(_, record: TDealersBanks) => {
            return record.name ? record.name : record.bank_uuid;
          }}
        />
        <Column key="bank_uuid" title="Банк" dataIndex="bank" />
        <Column
          className="admin-modal-table-cell"
          key="is_active"
          title="Статус"
          dataIndex="is_active"
          render={(_, record: TDealersProducts) => (
            <Checkbox
              key="record.is_active"
              checked={record.is_active}
              onChange={() => {
                const updatedDealersProducts = dealersProducts.map(
                  (product) => {
                    if (product.product_uuid === record.product_uuid) {
                      return {
                        ...product,
                        is_active: !record.is_active
                      };
                    }
                    return product;
                  }
                );
                setDealersProducts(updatedDealersProducts);
              }}
            />
          )}
        />
      </Table>
    </>
  );
};
