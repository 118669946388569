import { FC, ReactNode } from 'react';
import { Tooltip } from 'antd';

type TStatusTooltipProps = {
  title: string;
  titleChildren?: ReactNode;
  children?: ReactNode;
  background?: string;
  textColor?: string;
  isFlex?: boolean;
};

const StatusTooltip: FC<TStatusTooltipProps> = ({
  title,
  titleChildren,
  children,
  background,
  textColor,
  isFlex
}) => {
  return (
    <Tooltip
      title={
        <div
          style={{
            padding: '6px 8px'
          }}
        >
          <h2
            style={{
              color: textColor,
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '160%'
            }}
          >
            {title}
          </h2>
          <div
            style={
              !isFlex
                ? {
                    display: 'flex',
                    marginTop: '8px',
                    alignItems: 'center'
                  }
                : undefined
            }
          >
            {titleChildren}
          </div>
        </div>
      }
    >
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
        <div
          style={
            background
              ? {
                  marginRight: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  background
                }
              : { display: 'none' }
          }
        ></div>
        {children}
      </div>
    </Tooltip>
  );
};

export default StatusTooltip;
