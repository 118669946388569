import Editor from '@monaco-editor/react';
import { useEffect, useState } from 'react';
import { ideApi } from '@/integrations/index.api';

interface CodeData {
  uuid: string;
  name: string;
  code: string;
  extension: string;
  is_external: string | null;
  interpreter: string;
}

export const Ide = () => {
  const [data, setData] = useState<string | undefined>();

  const fetchCode = async () => {
    const data: CodeData = await ideApi.getCode('test_node');
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCode();
        setData(response.code);
      } catch (error) {
        console.error('Error fetching code:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Editor
        onChange={(value) => {
        }}
        theme={'vs-dark'}
        height="70vh"
        defaultLanguage="javascript"
        value={data}
      />
      {/*<Button onClick={showValue}>Get Value</Button>*/}
    </>
  );
};
