import { TNewOrder, TNewOrderDto } from '@/types/newOrder';

export const mapperForLocalStorageRequest = (dto: TNewOrderDto): TNewOrder => {
  return {
    uuid: dto.uuid,
    status: dto.status,
    otp: dto.otp,
    otpDate: dto.otpDate,
    // biometric_validated?: boolean;
    code: dto.code,
    productInfo: {
      product: dto.productInfo.product,
      additionalProducts: dto.productInfo.additionalProducts
    },
    personalInfo: {
      clientIin: dto.personalInfo.clientIin,
      phoneNumber: dto.personalInfo.phoneNumber,
      firstName: dto.personalInfo.firstName,
      lastName: dto.personalInfo.lastName,
      middleName: dto.personalInfo.middleName,
      dateBirth: dto.personalInfo.dateBirth,
      docType: dto.personalInfo.docType || '',
      sex: dto.personalInfo.sex || '',
      familyStatus: dto.personalInfo.familyStatus || '',
      children: dto.additionalInfo.children,
      companyName: dto.additionalInfo.companyName,
      jobTitle: dto.additionalInfo.jobTitle || '',
      workTelNumber: dto.additionalInfo.workTelNumber,
      salary: dto.additionalInfo.salary,
      additionalSalary: dto.additionalInfo.additionalSalary,
      socialStatus: dto.additionalInfo.socialStatus?.toString() || '',
      addContactsFullName: dto.additionalInfo.addContactsFullName,
      companyType: dto.additionalInfo.companyType?.toString() || '',
      addContactsTelNum: dto.additionalInfo.addContactsTelNum,
      addContactsIsRelated:
        dto.additionalInfo.addContactsIsRelated?.toString() || '',
      jobAddress: dto.additionalInfo.jobTitle,
      spouseInfo: {
        spouseIin: dto.personalInfo.spouseInfo?.spouseIin,
        spouseFullName: dto.personalInfo.spouseInfo?.spouseFullName,
        spousePlaceBirth: dto.personalInfo.spouseInfo?.spousePlaceBirth,
        spouseTelNum: dto.personalInfo.spouseInfo?.spouseTelNum,
        spouseDateBirth: dto.personalInfo.spouseInfo?.spouseDateBirth,
        spouseResidentStatus: dto.personalInfo.spouseInfo?.spouseResidentStatus,
        spouseCompanyName: dto.personalInfo.spouseInfo?.spouseCompanyName,
        spouseSalary: dto.personalInfo.spouseInfo?.spouseSalary,
        spouseAddSalary: dto.personalInfo.spouseInfo?.spouseAddSalary,
        isMarried: dto.personalInfo.spouseInfo?.isMarried
      }
    },
    additionalInfo: {
      children: dto.additionalInfo.children,
      companyName: dto.additionalInfo.companyName,
      companyType: dto.additionalInfo.companyType,
      jobTitle: dto.additionalInfo.jobTitle,
      workTelNumber: dto.additionalInfo.workTelNumber,
      salary: dto.additionalInfo.salary,
      additionalSalary: dto.additionalInfo.additionalSalary,
      socialStatus: dto.additionalInfo.socialStatus,
      addContactsFullName: dto.additionalInfo.addContactsFullName,
      addContactsIsRelated: dto.additionalInfo.addContactsIsRelated,
      addContactsTelNum: dto.additionalInfo.addContactsTelNum,
      files: dto.additionalInfo.files
    },
    createDate: ''
  };
};
