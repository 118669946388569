import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import CreateInsuranceDialogForm from './CreateInsuranceDialogForm';
import { dispatch, useAppSelector } from '@/store';
import {
  requestCreateUnverifiedDraft,
  retryValidateOTP
} from '@/store/orderStep.slice';
import { cleanPhoneNumber, formatPhoneNumber } from '@/services/formatter';
import { setVerifyModalShowInsuranceType } from '@/store/app.slice';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import {
  generateRandomSixDiginCode,
  isValidInput,
  validateNumberInputValue
} from 'services/helpers';
import { setCurrent } from 'store/newOrder.slice';
import {
  onSetClientInsuranceInfo,
  requestCreateOtp,
  validateInsuranceOTP
} from '@/store/orderInsurance.slice';
import { checkObjectValues } from '@/services/validators/checkObjectValues';
import { checkVerifyInputValuesValid } from '@/services/validators/checkVerifyInputValuesValid';

export type InsurVeryfiModalProps = {
  veryfiModalInsurance: string;
};
const VerifyModalInsurance = ({
  veryfiModalInsurance
}: InsurVeryfiModalProps) => {
  const navigate = useNavigate();
  const { dealer } = useAppSelector((state) => state.userInfo);

  const [isFocused, setIsFocused] = useState<{
    iin: boolean;
    //   fullName: boolean;
    telNum: boolean;
  }>({ iin: false, telNum: false });

  const [verifyContinueDisabled, setVerifyContinueDisabled] =
    useState<boolean>(true);

  const [otpValidWatcher, setOtpValidWatcher] = useState<boolean | null>(null);

  const { verifyModalInsurance } = useAppSelector((state) => state.app);

  const { dicts } = useAppSelector((state) => state.dict);

  const { isLoading, otpValid, clientCalcId } = useAppSelector(
    (state) => state.orderStep
  );

  const { requestId } = useAppSelector((state) => state.orderInsurance);

  const [code, setCode] = useState({
    firstSquare: '',
    secondSquare: '',
    thirdSquare: '',
    fourthSquare: ''
  });

  useEffect(() => {
  }, [requestId]);

  // const dispatch = useAppDispatch();

  const [step, setStep] = useState(1);

  const [inputValues, setInputValues] = useState<{
    iin: string;
    //   fullName: string;
    telNum: string;
  }>({ iin: '', telNum: '+7' });

  useEffect(() => {
    setOtpValidWatcher(otpValid);
  }, [otpValid]);

  useEffect(() => {
    const formattedOtpCode =
      code.firstSquare +
      code.secondSquare +
      code.thirdSquare +
      code.fourthSquare;
    if (formattedOtpCode.length < 4) return;
    dispatch(setCurrent(0));
    //dispatch(onSetSelectedBankToInitial());
    //dispatch(onSetOrderOtpCode(formattedOtpCode));
    dispatch(
      setVerifyModalShowInsuranceType({
        prevClientInsurance: false,
        smsVerfInsurance: false
      })
    );
    verifyModalInsurance.isNewApplicationInsurance;
    navigate('/new-insurance');
    // : navigate('/calculator');
    if (otpValidWatcher === false) {
      const formattedOtpCode =
        code.firstSquare +
        code.secondSquare +
        code.thirdSquare +
        code.fourthSquare;
      dispatch(setCurrent(0));
      //dispatch(onSetSelectedBankToInitial());
      //dispatch(onSetOrderOtpCode(formattedOtpCode));
      dispatch(
        setVerifyModalShowInsuranceType({
          prevClientInsurance: false,
          smsVerfInsurance: false
        })
      );
      verifyModalInsurance.isNewApplicationInsurance;
      navigate('/new-insurance');
      //  : navigate('/calculator');
    }
  }, [otpValidWatcher, code]);

  const retryOTP = async (): Promise<void> => {
    await dispatch(
      retryValidateOTP({
        clientPhone: cleanPhoneNumber(inputValues.telNum),
        uuid: requestId
      })
    );
  };

  const onCancelHandler = async () => {
    //dispatch(setVerifyModalShowType({ prevClient: false, smsVerf: false }));
    dispatch(
      setVerifyModalShowInsuranceType({
        prevClientInsurance: false,
        smsVerfInsurance: false
      })
    );
    setInputValues({ iin: '', telNum: '' });
    setCode({
      firstSquare: '',
      secondSquare: '',
      thirdSquare: '',
      fourthSquare: ''
    });
    setStep(1);
  };

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    if (label === 'fullName') {
      setInputValues((prevState) => {
        if (isValidInput(val)) {
          return { ...prevState, [label]: val };
        } else {
          return prevState;
        }
      });
    } else if (label === 'telNum') {
      val = formatPhoneNumber(val);
      setInputValues((prevState) => ({ ...prevState, [label]: val }));
    } else {
      setInputValues((prevState) => ({
        ...prevState,
        [label]: validateNumberInputValue(val, 12)
      }));
    }
  };

  const onOkHandler = async () => {
    // setOpenModal((prevState) => ({ ...prevState, prevClient: false }));
    // setOpenModal((prevState) => ({ ...prevState, smsVerf: true }));
    dispatch(
      setVerifyModalShowInsuranceType({
        prevClientInsurance: false,
        smsVerfInsurance: true
      })
    );

    const obj = {
      iin: inputValues.iin,
      telNum: inputValues.telNum,
      id: generateRandomSixDiginCode()
    };
    const createRequestDraftDto = {
      iin: inputValues.iin,
      clientPhone: cleanPhoneNumber(inputValues.telNum)
      // dealer_uuid: dealer?.value || ''
    };
    dispatch(onSetClientInsuranceInfo(obj));
    await dispatch(
      requestCreateOtp({
        iin: inputValues.iin,
        clientPhone: inputValues.telNum
      })
    );

    verifyModalInsurance.isNewApplicationInsurance
      ? await dispatch(
          requestCreateUnverifiedDraft({
            draftDto: createRequestDraftDto,
            dicts: dicts || []
          })
        )
      : await dispatch(onSetClientInsuranceInfo(createRequestDraftDto));
  };

  useEffect(() => {
    const isInputValuesValid = checkVerifyInputValuesValid(inputValues);
    setVerifyContinueDisabled(!isInputValuesValid);
  }, [inputValues]);

  useEffect(() => {
    if (Object.values(code).every((val) => val)) {
      const isCodeValid = checkObjectValues(code);
      if (isCodeValid) {
        dispatch(
          validateInsuranceOTP({
            otp:
              code.firstSquare +
              code.secondSquare +
              code.thirdSquare +
              code.fourthSquare,
            uuid: requestId
          })
        );
      }
    }
  }, [code]);

  return (
    <>
      <CustomModal
        isModalOpen={
          verifyModalInsurance.showVerifyModalInsurance.prevClientInsurance
        }
        handleOk={onOkHandler}
        handleCancel={onCancelHandler}
        title={veryfiModalInsurance}
        editClass="prevClientInfo"
        footer={[
          <Button className="cancel_btn" key="back" onClick={onCancelHandler}>
            Отмена
          </Button>,
          <Button
            disabled={verifyContinueDisabled || isLoading}
            className="continue_btn"
            key="submit"
            type="primary"
            onClick={onOkHandler}
          >
            Далее
          </Button>
        ]}
      >
        <div>
          <p className="sub_title">Заполните данные о клиенте</p>

          <CustomInput
            isFocused={isFocused.iin}
            value={validateNumberInputValue(inputValues.iin, 12)}
            label={'iin'}
            type={'iin'}
            labelTitle={'ИИН клиента'}
            onChangeInputHandler={onChangeInputHandler}
          />
          {/* <CustomInput
            isFocused={isFocused.fullName}
            value={inputValues.fullName}
            label={'fullName'}
            type={'fullName'}
            labelTitle={'ФИО клиента'}
            onChangeInputHandler={onChangeInputHandler}
          /> */}
          <CustomPhoneInput
            value={inputValues.telNum}
            label={'telNum'}
            type={'telNum'}
            onChangeInputHandler={onChangeInputHandler}
          />
        </div>
      </CustomModal>

      <CreateInsuranceDialogForm
        retryOTP={retryOTP}
        openDialog={
          verifyModalInsurance.showVerifyModalInsurance.smsVerfInsurance
        }
        onClose={onCancelHandler}
        step={step}
        checkCode={true}
        setStep={setStep}
        code={code}
        setCode={setCode}
      />
    </>
  );
};

export default VerifyModalInsurance;
