import React, { useEffect, useState } from 'react';
import { TDataSource, TInsuranceDataSource } from '@/types/dataSource';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  Button,
  Cascader,
  Collapse,
  DatePicker,
  Pagination,
  Select,
  Table,
  Tooltip
} from 'antd';
import { ColumnsType } from 'antd/es/table';
// import Panel from 'antd/es/cascader/Panel';
import { TNewInsuranceDto } from '@/types/newInsurance';
import { TNewInsuranceResponse } from '@/types/insuranceMapper';
import dayjs, { Dayjs } from 'dayjs';
import '@/styles/PersonalRequestsInfo.scss';
import {
  filterInsurance,
  getInsurancesByFilters,
  getOneInsuranceReq
} from '@/store/orderInsurance.slice';
import { DownOutlined, ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';

interface PersonalInsuranceInfoProps {
  status: string | undefined;
  onChangeStatusInsurance: (val: string) => void;
  setParamInsurance: (value: any) => void;
  paramInsurances: {
    status?: string;
    dealer_uuid?: string;
    brand_uuid?: string;
    modal_uuid?: string;
  };
  brandIns: string;
  modelIns: string;
  handleModelChangeIns: (val: string) => void;
  onChangeBrandIns: (val: string) => void;
  handleResetDateRangeIns: (val: string) => void;
  handleDateRangeChangeIns: (dates: [Dayjs, Dayjs] | null) => void;
  onResetStatusIns: () => void;
  onResetBrandModelIns: () => void;
  onResetDateRangeIns: () => void;
  onDateRangeChangeIns: () => void;
  onResetFiltersIns: () => void;
}
const { Panel } = Collapse;

const PersonalInsuranceInfo: React.FC<PersonalInsuranceInfoProps> = ({
  status,
  onChangeStatusInsurance,
  setParamInsurance,
  paramInsurances,
  brandIns,
  modelIns,
  handleModelChangeIns,
  onChangeBrandIns,
  handleResetDateRangeIns,
  handleDateRangeChangeIns,
  onResetStatusIns,
  onResetBrandModelIns,
  onResetDateRangeIns,
  onDateRangeChangeIns,
  onResetFiltersIns
}) => {
  const dispatch = useAppDispatch();
  const { insurances } = useAppSelector((state) => state.orderInsurance); // получаем все заявки по страхованию
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 10
  });

  const [telNumIin, setTelNumIin] = useState<string>('');
  const { dicts } = useAppSelector((state) => state.dict);
  const [brand_ins, setBrandIns] = useState<string>(brandIns);
  const [model_ins, setModelIns] = useState<string>(modelIns);

  const statusOptions = [
    { value: 'Все заявки', label: 'Все заявки' },
    { value: 'Полис ожидает оплату', label: 'Полис ожидает оплату' },
    { value: 'Полис выдан', label: 'Полис выдан' }
  ];
  const [brandOptionsIns, setBrandOptionsIns] = useState<
    { value: string; label: string | number }[]
  >([]);
  const [modelOptionsIns, setModelOptionsIns] = useState<
    { value: string; label: string | number; brand_uuid: string }[]
  >([]);
  const [dateRange, setDateRange] = useState<
    [Dayjs | null, Dayjs | null] | null
  >(null);

  // const onPageChange = (page: number, pageSize: number) => {
  //   const startIndex = (page - 1) * pageSize;
  //   const endIndex = Math.min(startIndex + pageSize, insurances!.length);
  //   const currentPageData = insurances!.slice(startIndex, endIndex);
  //   setFilteredRequest(currentPageData);
  // };

  const [userSearchType, setUserSearchType] = useState<string>('');
  const [filteredRequests, setFilteredRequest] = useState<
    TNewInsuranceResponse[]
  >([]);

  useEffect(() => {
    const paginationInfoStorage = localStorage.getItem(
      'paginationInsuranceInfo'
    );
    if (paginationInfoStorage) {
      const parsedPaginationInfo = JSON.parse(paginationInfoStorage);
      setPaginationData(parsedPaginationInfo);
    }
  }, []);

  useEffect(() => {
    const startIndex =
      (paginationData.currentPage - 1) * paginationData.itemsPerPage;
    const endIndex = startIndex + paginationData.itemsPerPage;
    const paginatedData = insurances.slice(startIndex, endIndex);
    setFilteredRequest(paginatedData);
  }, [paginationData, insurances]);

  const onPageChange = (page: number, pageSize: number) => {
    setPaginationData({
      currentPage: page, // Устанавливаем текущую страницу в соответствии с выбранной
      itemsPerPage: pageSize // Устанавливаем количество элементов на странице
    });
  };

  useEffect(() => {
    if (insurances?.length) {
      // Сначала сортируем заявки по дате
      const sortedRequests = [...insurances].sort((a, b) => {
        const dateA = new Date(a.createDate).getTime();
        const dateB = new Date(b.createDate).getTime();
        return dateB - dateA;
      });

      // Затем применяем пагинацию
      const startIndex =
        (paginationData.currentPage - 1) * paginationData.itemsPerPage;
      const endIndex = startIndex + paginationData.itemsPerPage;
      const paginatedData = sortedRequests.slice(startIndex, endIndex);

      // Устанавливаем отфильтрованные и отсортированные данные
      setFilteredRequest(paginatedData);
    } else {
      setFilteredRequest([]);
    }
  }, [insurances, paginationData]);

  const navigate = useNavigate();

  const mapStatus = (status: string | undefined): string | undefined => {
    switch (status) {
      case 'Все заявки':
        return undefined;
      case 'Полис выдан':
        return 'completed';
      case 'Полис ожидает оплату':
        return 'waiting';
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const mappedStatus = mapStatus(status);
    dispatch(
      getInsurancesByFilters({ ...paramInsurances, status_code: mappedStatus })
    );
  }, [paramInsurances, status, brand_ins, model_ins]);

  useEffect(() => {
    dispatch(filterInsurance({ telNumIin, userSearchType }));
  }, []);

  const handleStatusChange = (value: string) => {
    onChangeStatusInsurance(value);
  };

  const handleCascaderChange = (value: any) => {
    onChangeBrandIns(value[0] || '');
    handleModelChangeIns(value[1] || '');
  };
  useEffect(() => {
    const brandDict = findDictByTitle(DictTypes.Brands, dicts);
    if (brandDict) {
      setBrandOptionsIns(
        brandDict.content.map((brand) => ({
          value: brand.uuid,
          label: brand.name
        }))
      );
    }

    const modelDict = findDictByTitle(DictTypes.Models, dicts);
    if (modelDict) {
      setModelOptionsIns(
        modelDict.content.map((model) => ({
          value: model.uuid,
          label: model.name,
          brand_uuid: model.brand_uuid as string
        }))
      );
    }
  }, [dicts]);

  const cascaderOptions = brandOptionsIns.map((brand) => ({
    ...brand,
    children: modelOptionsIns
      .filter((model) => model.brand_uuid === brand.value)
      .map((model) => ({
        value: model.value,
        label: model.label
      }))
  }));

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates === null || dates.some((date) => date === null)) {
      setDateRange(null);
      handleDateRangeChangeIns(null);
    } else {
      const [start, end] = dates;
      setDateRange([start as Dayjs, end as Dayjs]);
      handleDateRangeChangeIns([start as Dayjs, end as Dayjs]);
    }
  };

  const handleResetFilters = () => {
    setBrandIns('');
    setModelIns('');
    setDateRange(null); // Сброс даты в null
    setParamInsurance({
      status: '',
      brand_uuid: '',
      model_uuid: '',
      date_from: '',
      date_until: ''
    });

    onResetStatusIns();
    onResetBrandModelIns();
    handleStatusChange('');
    handleCascaderChange([]); // Сброс значений Cascader
  };
  const handleRequestInfo = (dataSource: any) => {};

  return (
    <div className="PersonalRequestsInfo_root">
      <div className="PersonalRequests_filter">
        <Select
          className="custom-select-placeholder"
          style={{ width: '30%', marginRight: '2%' }}
          size="large"
          placeholder="Выберите статус"
          value={status || undefined}
          onChange={handleStatusChange}
          options={statusOptions}
        />
        <Cascader
          options={cascaderOptions}
          value={
            brandIns || (undefined && modelIns) || undefined
              ? [brandIns, modelIns]
              : []
          }
          onChange={(value) => {
            handleCascaderChange(value);
          }}
          changeOnSelect
          placeholder="Выберите бренд и модель"
          style={{ width: '40%', marginRight: '2%' }}
          size="large"
          allowClear={false}
        />
        <DatePicker.RangePicker
          style={{
            width: '30%',
            marginRight: '2%',
            height: '38px',
            marginTop: '1px',
            borderRadius: '8px',
            color: 'black'
          }}
          value={dateRange || undefined}
          onChange={(dates) => {
            setDateRange(dates);
            handleDateChange(dates);
          }}
          placeholder={['Начальная дата', 'Конечная дата']}
          className="custom-date-picker"
          format="DD.MM.YYYY"
        />
        <Tooltip title="Сбрось фильтры">
          <Button
            type="default"
            onClick={handleResetFilters}
            style={{
              // marginTop: '0px',
              width: '40px',
              height: '40px',
              borderRadius: '8px',
              border: '1px solid #d9d9d9',
              padding: '16px',
              gap: '10px'
            }}
            icon={<ReloadOutlined />}
          ></Button>
        </Tooltip>
      </div>
      <Collapse
        expandIconPosition="start"
        expandIcon={({ isActive }) => (
          <DownOutlined
            style={{
              marginTop: '30px',
              width: '24px',
              height: '24px'
            }}
            rotate={isActive ? 180 : 0}
          />
        )}
      >
        {filteredRequests.length > 0 ? (
          filteredRequests.map((dataSource) => (
            <Panel
              key={dataSource.uuid}
              header={
                <div className="Collapse_header">
                  <div className="collapse_span">
                    {dataSource.brand && dataSource.model && dataSource.year
                      ? `${dataSource.brand} ${dataSource.model}, ${dataSource.year}`
                      : ''}
                    <span className="separate_dot">&#183;</span>
                    ИИН: {dataSource.clientIin || ''}
                    <span className="separate_dot">&#183;</span>
                    {dataSource.firstName &&
                    dataSource.lastName &&
                    dataSource.middleName
                      ? `${dataSource.firstName} ${dataSource.lastName} ${dataSource.middleName}`
                      : ''}
                    <span className="separate_dot">&#183;</span>
                    {dataSource.clientPhone || ''}
                    <p>
                      {dataSource.dealer || ''}
                      <span className="separate_dot">&#183;</span>
                      Создано:{' '}
                      {dayjs(dataSource.createDate).format('DD.MM.YYYY')}
                      <span className="separate_dot">&#183;</span>
                      Полис выдан:{' '}
                      {dayjs(dataSource.contrancDate).format('DD.MM.YYYY')}
                    </p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {dataSource.status.code === 'completed' ? (
                      <div
                        style={{
                          padding: '4px 16px',
                          borderRadius: '8px',
                          background: '#0CAF60',
                          width: '90px',
                          textAlign: 'center'
                        }}
                      >
                        <p
                          style={{
                            fontSize: '10px',
                            fontWeight: '700',
                            color: 'white'
                          }}
                        >
                          Полис выдан
                        </p>
                      </div>
                    ) : dataSource.status.code === 'waiting_bank_response' ||
                      dataSource.status.code === 'waiting_of_payment' ? (
                      <div
                        style={{
                          padding: '4px 16px',
                          borderRadius: '8px',
                          background: '#ffc01f',
                          width: '90px',
                          textAlign: 'center'
                        }}
                      >
                        <p
                          style={{
                            fontSize: '10px',
                            fontWeight: '700',
                            color: 'black'
                          }}
                        >
                          Полис ожидает оплаты
                        </p>
                      </div>
                    ) : dataSource.status.code === 'error' ? (
                      <div
                        style={{
                          padding: '4px 16px',
                          borderRadius: '8px',
                          background: '#ff2e1f',
                          width: '90px',
                          textAlign: 'center'
                        }}
                      >
                        <p
                          style={{
                            fontSize: '10px',
                            fontWeight: '700',
                            color: 'white'
                          }}
                        >
                          Ошибка в процессе
                        </p>
                      </div>
                    ) : dataSource.status.code === 'sms_verificating' ? (
                      <div
                        style={{
                          padding: '4px 16px',
                          borderRadius: '8px',
                          background: '#ff2e1f',
                          width: '90px',
                          textAlign: 'center'
                        }}
                      >
                        <p
                          style={{
                            fontSize: '10px',
                            fontWeight: '700',
                            color: 'white'
                          }}
                        >
                          SMS верификация
                        </p>
                      </div>
                    ) : dataSource.status.code === 'draft' ? (
                      <div
                        style={{
                          padding: '4px 16px',
                          borderRadius: '8px',
                          background: '#a8a8a8',
                          width: '90px',
                          textAlign: 'center'
                        }}
                      >
                        <p
                          style={{
                            fontSize: '10px',
                            fontWeight: '700',
                            color: 'white'
                          }}
                        >
                          Создан
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: '4px 16px',
                          borderRadius: '8px',
                          background: 'rgb(17 24 39)',
                          width: '90px',
                          textAlign: 'center'
                        }}
                      >
                        <p
                          style={{
                            fontSize: '10px',
                            fontWeight: '700',
                            color: 'white'
                          }}
                        >
                          DRAFT
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              }
            >
              <Button
                onClick={async () => {
                  await dispatch(getOneInsuranceReq(dataSource.uuid));
                  navigate('/new-insurance');
                }}
              >
                Подробнее
              </Button>
            </Panel>
          ))
        ) : (
          <p style={{ textAlign: 'center', marginTop: '20px' }}>
            Заявки не найдены
          </p>
        )}
      </Collapse>

      <Pagination
        current={paginationData.currentPage}
        pageSize={paginationData.itemsPerPage}
        total={insurances.length} // Общее количество элементов
        onChange={onPageChange}
        showSizeChanger
        style={{ marginTop: '20px', textAlign: 'center' }}
      />
    </div>
  );
};

export default PersonalInsuranceInfo;
