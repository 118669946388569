import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { TModel } from '@/types/model.type';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import {
  createModel,
  getModels,
  updateModel
} from '@/store/admin/adminModels.slice';
import Checkbox from 'antd/es/checkbox/Checkbox';
import '@/styles/AdminModal.scss';

type TCreateOrEditModelProps = {
  setShow: () => void;
  editMode?: boolean;
  modelItem: TModel | null;
  pagination: { limit: number; page: number };
  param: string;
  brand: string;
};
export const CreateOrEditModelModal: FC<TCreateOrEditModelProps> = ({
  setShow,
  editMode,
  modelItem,
  pagination,
  param,
  brand
}) => {
  const dispatch = useAppDispatch();
  const { dicts } = useAppSelector((state) => state.dict);
  const [model, setModel] = useState<TModel>({
    uuid: '',
    name: '',
    code: 0,
    brand_uuid: '',
    capacity: null,
    year: null,
    is_active: true,
    forte_id: '',
    last_year_forte_id: '',
    is_commercial: false
  });
  const [options, setOptions] = useState<
    { value: string; label: string | number }[]
  >([]);

  useEffect(() => {
    if (editMode && modelItem) {
      setModel(modelItem);
    }
    const brands = findDictByTitle(DictTypes.Brands, dicts).content.map(
      (brand) => {
        return { value: brand.uuid, label: brand.name };
      }
    );
    setOptions(brands);
  }, []);
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };
  const onSelectChange = (value: string) => {
    setModel((prevState) => ({
      ...prevState,
      brand_uuid: value
    }));
  };
  const onOk = () => {
    if (editMode) {
      dispatch(updateModel({ ...model, code: Number(model.code) })).then(() => {
        dispatch(
          getModels({
            limit: pagination.limit,
            page: pagination.page,
            brandUuid: brand,
            param: param
          })
        );
      });
      return setShow();
    }
    dispatch(createModel({ ...model, code: Number(model.code) })).then(() => {
      dispatch(
        getModels({
          limit: pagination.limit,
          page: pagination.page,
          brandUuid: brand,
          param: param
        })
      );
    });
    setShow();
  };

  return (
    <Modal open={true} onOk={onOk} onCancel={() => setShow()} className="modal">
      <Form
        style={{
          margin: '30px 0'
        }}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Row style={{ marginBottom: '10px' }}>
              <Col span={10}>
                <Form.Item label="Наименование модели" required>
                  <Input
                    name="name"
                    value={model.name}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Код" required>
                  <Input
                    name="code"
                    value={model.code}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6} offset={1}>
                <Form.Item label="Бренд" required>
                  <Select
                    options={options}
                    value={model.brand_uuid}
                    onChange={onSelectChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Form.Item label="Объем" required>
                  <InputNumber
                    type={'number'}
                    name="capacity"
                    controls={false}
                    value={model.capacity ? Number(model.capacity) : ''}
                    onChange={(value) =>
                      setModel((prevState) => ({
                        ...prevState,
                        capacity: value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label="Год" required>
                  <Input
                    name="year"
                    value={model.year ? model.year : ''}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
              <Col span={10} offset={1}>
                <Form.Item label="Коммерческое" required>
                  <Checkbox
                    checked={model.is_commercial}
                    onChange={() =>
                      setModel((prevState) => ({
                        ...prevState,
                        is_commercial: !prevState.is_commercial
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item label="Forte ID" required>
                  <Input
                    name="forte_id"
                    value={model.forte_id ? model.forte_id : ''}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Last year Forte ID" required>
                  <Input
                    name="last_year_forte_id"
                    value={
                      model.last_year_forte_id ? model.last_year_forte_id : ''
                    }
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
