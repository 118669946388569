import { DictTypes } from '../constants';
import { TSelectItemInsurance } from '../types/selectsItem';

export const productInfoItemsInsurance: TSelectItemInsurance[] = [
  {
    key: 'productType',
    label: 'Выбрать продукт',
    snakeCaseValues: DictTypes.Products,
    editable: false,
    type: 'select'
  },
  
  {
    key: 'dateForm',
    label: 'Дата оформления',
    editable: true,
    type: 'datepicker'
  },
  {
    key: 'payment',
    label: 'Способ оплаты',
    editable: false,
    snakeCaseValues: DictTypes.InsurancePayment,
    type: 'select'
  },
  {
    key: 'paymentType',
    label: 'Тип оплаты',
    editable: false,
    snakeCaseValues: DictTypes.InsuranсeInstallmentTypes,
    type: 'select'
  },
  

  {
    key: 'datepolis',
    label: 'Дата начала полиса',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'dateEndpolis',
    label: 'Дата окончания полиса',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'productTypeDate',
    label: 'Период рассрочки ',
    snakeCaseValues: DictTypes.Insurance_Installment_Periods,
    editable: false,
    type: 'select'
  },

];

export const personalClientInsurance: TSelectItemInsurance[] = [
  {
    key: 'clientIin',
    label: 'ИИН клиента',
    editable: false,
    type: 'input'
  },
  {
    key: 'clientPhone',
    label: 'Номер мобильного телефона',
    editable: false,
    type: 'phoneNumber'
  },
  {
    key: 'lastName',
    label: 'Фамилия',
    editable: false,
    type: 'input'
  },
  {
    key: 'firstName',
    label: 'Имя',
    editable: false,
    type: 'input'
  },
  {
    key: 'dateBirth',
    label: 'Дата рождения',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'middleName',
    label: 'Отчество',
    editable: false,
    type: 'input'
  },
  
 
 
 
  {
    key: 'sex',
    label: 'Выбрать пол',
    editable: false,
    snakeCaseValues: DictTypes.Genders,
    type: 'select'
  },
  {
    key: 'placeBirth',
    label: 'Место рождения',
    editable: false,
    type: 'input'
  },
  {
    key: 'docType',
    label: 'Выбрать тип документа',
    editable: false,
    snakeCaseValues: DictTypes.DocumentTypes,
    type: 'select'
  },
  {
    key: 'numberdocument',
    label: '№ документа',
    editable: false,
    type: 'input'
  },
  {
    key: 'issuebdy',
    label: 'Кем выдан',
    editable: false,
    snakeCaseValues: DictTypes.DocumentIssuringAuthorities,
    type: 'select'
  },
 
  {
    key: 'resident',
    label: 'Резидент РК',
    snakeCaseValues: DictTypes.ResidentStatuses,
    editable: false,
    type: 'select'
  },
  {
    key: 'email',
    label: 'E-mail',
    editable: false,
    type: 'input'
  },
 
  
  {
    key: 'dateDocument',
    label: 'Дата выдачи документа',
    //snakeCaseValues: DictTypes.SocialStatuses,
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'validity',
    label: 'Срок действия',
    editable: false,
    type: 'datepicker'
  },
  
  {
    key: 'adress',
    label: 'Адрес прописки',
    editable: false,
    type: 'input'
  },
 
  
];

export const modalTSItemsInsuramce: TSelectItemInsurance[] = [
  {
    key: 'priseAvto',
    label: 'Стоимость авто',
    editable: false,
    type: 'input'
  },
  {
    key: 'tehNum',
    label: '№ Тех.паспорта',
    editable: false,
    type: 'input'
  },
  {
    key: 'vinCode',
    label: 'VIN',
    editable: false,
    type: 'input'
  },
  {
    key: 'numberGos',
    label: 'Гос.номер',
    editable: false,
    type: 'input'
  },

  {
    key: 'dateOfTechPasspord',
    label: 'Дата выдачи тех.паспорта',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'dealer',
    label: 'Торговая точка',
    snakeCaseValues: DictTypes.Dealers,
    editable: false,
    type: 'select'
  }
];

export const inputInsurance: TSelectItemInsurance[] = [
  {
    key: 'insuranseSumm',
    label: 'Страховая сумма',
    editable: false,
    type: 'input'
  },
  {
    key: 'insuransePremium',
    label: 'Страховая премия',
    editable: false,
    type: 'input'
  }
];
