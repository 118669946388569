import { useEffect, useState } from 'react';
import { BankApprovedItem } from './BankOfferItems/BankApprovedItem';
import { BankWaitingItem } from './BankOfferItems/BankWaitingItem';
import { TBankOffers, TBankOffersAlternative } from '@/types/offers';
import { useAppSelector } from '@/store';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import { Collapse } from 'antd';
import { bankIconFormatter } from '@/services/formatter/bankIconFormatter';
import { BankDeclinedItem } from '@/components/App/NewOrder/OfferStep/BankOffers/BankOfferItems/BankDeclinedvedItem';
import { TAltynResponse } from '@/types/altynResponse.type';

export type TBankOffersProps = {
  onSelectOffer: (bank: TBankOffersAlternative) => void;
  handleCancelBankOffer: (bank: TBankOffersAlternative) => void;
  handleShowSpecialConditions: () => void;
  handleChangeEblankStatus: (responseExtUuid: string) => void;
  handleChangeForteStatus: (responseExtUuid: string) => void;
};

export const BankOffers = ({
  onSelectOffer,
  handleShowSpecialConditions,
  handleChangeEblankStatus,
  handleChangeForteStatus,
  handleCancelBankOffer
}: TBankOffersProps) => {
  const { dicts } = useAppSelector((state) => state.dict),
    { banksOffers } = useAppSelector((state) => state.offerStep),
    [approvedOffers, setApprovedOffers] = useState<TBankOffers[]>([]),
    [declinedOffers, setDeclinedOffers] = useState<TBankOffers[]>([]),
    [waitingOffers, setWaitingOffers] = useState<TBankOffers[]>([]),
    [bankOffersWatcher, setBankOffersWatcher] =
      useState<TBankOffers[]>(banksOffers);

  useEffect(() => {
    if (bankOffersWatcher.length > 0) {
      const approvedArrayHandler =
        bankOffersWatcher.length > 0
          ? [...bankOffersWatcher].filter(
              (bank) =>
                bank.statusType === 'approved' ||
                bank.statusType === 'financed' ||
                bank.statusType === 'accepted_alternative' ||
                bank.statusType === 'financed_pledge_missing' ||
                bank.statusType === 'waiting_for_upload_files' ||
                bank.statusType === 'pre_approved' ||
                bank.statusType === 'waiting_of_financing' ||
                bank.statusType === 'mobile_client' ||
                bank.statusType ===
                  'financed_waiting_for_modification_before_pledge'
            )
          : [];
      setApprovedOffers(approvedArrayHandler);

      const waitArrayHandler =
        bankOffersWatcher.length > 0
          ? [...bankOffersWatcher].filter((bank) => {
              return (
                bank.statusType === 'waiting_bank_response' ||
                bank.statusType === 'on_rework' ||
                bank.statusType === 'on_verification'
              );
            })
          : [];
      const filteredBanks = findDictByTitle(
        DictTypes.Banks,
        dicts
      ).content.filter((bank) => bank.is_active);

      const filteredOffers = waitArrayHandler.filter((offer) =>
        filteredBanks.some((bank) => offer.bank.includes(bank.name))
      );
      setWaitingOffers(filteredOffers);

      const declinedArrayHandler =
        bankOffersWatcher.length > 0
          ? [...bankOffersWatcher].filter(
              (bank) =>
                bank.statusType === 'declined' ||
                bank.statusType === 'cancelled' ||
                bank.statusType === 'error'
            )
          : [];
      setDeclinedOffers(declinedArrayHandler);
    } else {
      const banksDictContent = findDictByTitle(DictTypes.Banks, dicts)?.content;
      const placeHolderArray = [...banksDictContent]
        ?.filter((bank) => bank?.is_active)
        ?.map((bank) => {
          const offerHandler: TBankOffers = {
            bank: bank.name,
            responsePrimaryBody: '',
            offerId: bank.offerId,
            name: bank.name,
            statusType: 'waiting_bank_response',
            extId: '',
            id: bank.name,
            responseApprovedBody: '',
            alternative: [],
            createDate: '',
            icon: bankIconFormatter(bank.name),
            initialFee: '',
            insurance_amount: '',
            loanAmount: '',
            monthlyPayment: '',
            product: '',
            rate: '',
            reason: 'В ожидании предложений',
            statusCode: '',
            term: '',
            withPledge: false,
            responseSendedBody: ''
          };
          return offerHandler;
        });
      setWaitingOffers(placeHolderArray);
    }
  }, [bankOffersWatcher, dicts]);

  useEffect(() => {
    setBankOffersWatcher(banksOffers);
  }, [banksOffers]);

  return (
    <div key="offers">
      {approvedOffers.length > 0 &&
        (() => {
          const filtered = approvedOffers.filter(
            (bank) => bank.bank === 'altyn'
          );

          if (filtered.length > 0) {
            const altynResponses: TAltynResponse[] | null = filtered[0]
              .responseApprovedBody
              ? JSON.parse(filtered[0].responseApprovedBody)
              : null;

            return (
              <Collapse
                bordered={false}
                className="custom-collapse"
                items={[
                  {
                    key: '1',
                    label: (
                      <BankApprovedItem
                        key={filtered[0].offerId}
                        isAltyn={true}
                        isHeader={true}
                        bankOffer={filtered[0]}
                        onCancelBankOffer={handleCancelBankOffer}
                        onSelectOffer={onSelectOffer}
                        handleShowSpecialConditions={
                          handleShowSpecialConditions
                        }
                      />
                    ),
                    children:
                      altynResponses &&
                      altynResponses.map((bank) => {
                        return (
                          <BankApprovedItem
                            key={bank.offerId}
                            altynResponse={bank}
                            isAltyn={true}
                            bankOffer={filtered[0]}
                            handleShowSpecialConditions={
                              handleShowSpecialConditions
                            }
                            onSelectOffer={onSelectOffer}
                            onCancelBankOffer={handleCancelBankOffer}
                          />
                        );
                      })
                  }
                ]}
              />
            );
          }
          return null;
        })()}
      {approvedOffers.length > 0 &&
        approvedOffers
          .filter((bank) => bank.bank !== 'altyn')
          .map((bank) => {
            return (
              bank && (
                <BankApprovedItem
                  bankOffer={bank}
                  onCancelBankOffer={handleCancelBankOffer}
                  key={bank.offerId} // Уникальный ключ
                  onSelectOffer={onSelectOffer}
                  handleShowSpecialConditions={handleShowSpecialConditions}
                />
              )
            );
          })}
      {waitingOffers.length > 0 &&
        waitingOffers.map((bank) => {
          return (
            bank && (
              <BankWaitingItem
                updateEblankStatus={handleChangeEblankStatus}
                updateForteStatus={handleChangeForteStatus}
                bankOffer={bank}
                key={bank.offerId} // Уникальный ключ
              />
            )
          );
        })}
      <div>
        <h1 className="DeclinedTitle"></h1>
        {declinedOffers.length > 0 &&
          declinedOffers.map((bank) => {
            return (
              bank && <BankDeclinedItem bankOffer={bank} key={bank.offerId} /> // Уникальный ключ
            );
          })}
      </div>
    </div>
  );
};
