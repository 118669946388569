import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import FormItem from 'antd/es/form/FormItem';
import { FC, useState } from 'react';
import { TDictCreate } from '@/types/dicts';

interface TModalProps {
  setShow: () => void;
}

const options: { value: string; label: string }[] = [
  {
    value: 'Boolean',
    label: 'Boolean'
  },
  {
    value: 'String',
    label: 'String'
  },
  {
    value: 'DateTime',
    label: 'DateTime'
  },
  {
    value: 'Float',
    label: 'Float'
  },
  {
    value: 'Int',
    label: 'Int'
  }
];

export const CreateDbTableModal: FC<TModalProps> = ({ setShow }) => {
  const [data, setData] = useState<TDictCreate>({
    title: '',
    body: [{ name: '', type: '', nullable: false }]
  });

  const addFieldHandler = () => {
    setData({
      ...data,
      body: [...data.body, { name: '', type: '', nullable: false }]
    });
  };
  const removeFieldHandler = (index: number) => {
    setData({
      ...data,
      body: data.body.filter((_, idx) => idx !== index)
    });
  };

  const okHandler = () => {
    const names = new Set<string>();
    for (const field of data.body) {
      if (field.name.trim() === '') {
        console.error('Ошибка: Имя поля не может быть пустым');
        return;
      }
      if (!field.type.trim()) {
        console.error('Ошибка: Тип поля не может быть пустым');
        return;
      }
      if (!options.some((option) => option.value === field.type)) {
        console.error('Ошибка: Недопустимый тип поля');
        return;
      }
      if (names.has(field.name)) {
        console.error('Ошибка: Найдены повторяющиеся имена полей');
        return;
      }
      names.add(field.name);
    }

    setShow();
  };

  return (
    <Modal open={true} onOk={okHandler} onCancel={setShow}>
      <Form>
        <Row style={{ width: '100%', marginTop: '50px' }}>
          <Col span={24}>
            <FormItem>
              <label>
                Наименование справочника
                <Input
                  value={data.title}
                  onChange={(e) =>
                    setData((prevState) => ({
                      ...prevState,
                      title: e.target.value
                    }))
                  }
                />
              </label>
            </FormItem>
            {data.body.map((field, index) => (
              <Row
                style={{ width: '100%' }}
                justify={'start'}
                key={index}
                align={'middle'}
              >
                <Col span={8}>
                  <FormItem>
                    <label>
                      Наименование поля
                      <Input
                        value={field.name}
                        onChange={(e) => {
                          const updatedBody = [...data.body];
                          updatedBody[index] = {
                            ...field,
                            name: e.target.value
                          };
                          setData((prevState) => ({
                            ...prevState,
                            body: updatedBody
                          }));
                        }}
                      />
                    </label>
                  </FormItem>
                </Col>
                <Col span={7} offset={1}>
                  <FormItem>
                    <label>
                      Тип поля
                      <Select
                        options={options}
                        value={field.type}
                        onChange={(value) => {
                          const updatedBody = [...data.body];
                          updatedBody[index] = { ...field, type: value };
                          setData((prevState) => ({
                            ...prevState,
                            body: updatedBody
                          }));
                        }}
                      />
                    </label>
                  </FormItem>
                </Col>
                <Col span={3} offset={1}>
                  <FormItem>
                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      Nullable
                      <Checkbox
                        style={{ marginTop: '10px' }}
                        checked={field.nullable}
                        onChange={(e) => {
                          const updatedBody = [...data.body];
                          updatedBody[index] = {
                            ...field,
                            nullable: e.target.checked
                          };
                          setData((prevState) => ({
                            ...prevState,
                            body: updatedBody
                          }));
                        }}
                      />
                    </label>
                  </FormItem>
                </Col>
                {index > 0 && (
                  <Col
                    span={1}
                    offset={2}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <Button onClick={() => removeFieldHandler(index)}>
                      <DeleteOutlined style={{ color: 'red' }} />
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          </Col>
        </Row>
        <Button onClick={addFieldHandler}>Добавить поле</Button>
      </Form>
    </Modal>
  );
};
