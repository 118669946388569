import { TLeasingsStore } from '../types/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiConnector } from '@/integrations/api.connector';
import { leasingItemMapper } from '@/services/mappers/leasingItemMapper';
import { leasingsResponseMapper } from '@/services/mappers/leasingsResponseMapper';

const initialState: TLeasingsStore = {
  isLoading: false,
  leasings: [],
  targetLeasingUuid: '',
  selectedLeasing: null
};

export const createLeasingDraft = createAsyncThunk(
  'leasings/createLeasingDraft',
  async (_, { dispatch }) => {
    try {
      const data = await apiConnector.createLeasingDraft();
      await dispatch(getLeasingById(data.uuid));
      return data;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const updateLeasing = createAsyncThunk(
  'leasings/updateLeasing',
  async (
    { leasingUuid, leasingDto }: { leasingUuid: string; leasingDto: any },
    { dispatch }
  ) => {
    try {
      const data = await apiConnector.updateLeasing(leasingUuid, leasingDto);
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${data.tplValues}`;
      link.download = 'Лизинг';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(getLeasingById(data?.leasingData?.uuid || ''));
      return data;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getLeasingById = createAsyncThunk(
  'leasings/getLeasingById',
  async (leasingId: string) => {
    try {
      return await apiConnector.getLeasingById(leasingId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getAllLeasings = createAsyncThunk(
  'leasings/getAllLeasings',
  async () => {
    try {
      return await apiConnector.getAllLeasings();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const leasingsSlice = createSlice({
  name: 'leasings',
  initialState,
  reducers: {
    setSelectedLeasingToInitial: (state) => {
      state.selectedLeasing = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLeasingDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createLeasingDraft.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createLeasingDraft.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(updateLeasing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLeasing.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateLeasing.fulfilled, (state, action) => {
        state.isLoading = false;
      })

      .addCase(getAllLeasings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLeasings.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllLeasings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leasings = leasingsResponseMapper(action.payload);
      })

      .addCase(getLeasingById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeasingById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLeasingById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.targetLeasingUuid = action.payload.uuid;
        state.selectedLeasing = leasingItemMapper(action.payload);
      });
  }
});

export const { setSelectedLeasingToInitial } = leasingsSlice.actions;
