import React, { useEffect, useState } from 'react';
import { TAdditionalInfo } from '@/types/additionalInfo';
import { TPersonInfo } from '@/types/personInfo';
import { TProductInfo } from '@/types/productInfo';
import { TDict } from '@/types/store';
import { useAppSelector } from '@/store';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import { FileUpload } from '@/components/Shared/Input/FileUpload';
import { PhotoUpload } from '@/components/Shared/Input/PhotoUpload';
import { FileDownload } from '@/components/Shared/Input/FileDownload';

export type TThirdStepProps = {
  handleFileChange: (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => void;
  additionalInfo: TAdditionalInfo;
  personalInfo: TPersonInfo;
  setAdditionalInfo: React.Dispatch<React.SetStateAction<TAdditionalInfo>>;
  productInfo: TProductInfo;
  dicts: TDict[] | null;
  file: any;
  fieldsReadOnly: boolean;
};

export const ThirdStep = ({
  file,
  handleFileChange,
  additionalInfo,
  personalInfo,
  productInfo,
  fieldsReadOnly
}: TThirdStepProps) => {
  const { dicts } = useAppSelector((state) => state.dict);
  // const info = useAppSelector(state=> state.)
  const [personalSocialOrganisationTypes, setPersonalSocialOrganisationTypes] =
    useState<{
      socialStatus: string;
      companyType: string;
    }>({ companyType: '', socialStatus: '' });

  useEffect(() => {
    const socialStatusValue = findDictByTitle(
      DictTypes.SocialStatuses,
      dicts
    )?.content?.find(
      (socialStatus) => socialStatus.code === Number(personalInfo.socialStatus)
    );
    const companyTypeValue = findDictByTitle(
      DictTypes.OrganizationTypes,
      dicts
    )?.content?.find(
      (socialStatus) => socialStatus.code === Number(personalInfo.companyType)
    );
    setPersonalSocialOrganisationTypes({
      companyType: companyTypeValue?.name || '',
      socialStatus: socialStatusValue?.name || ''
    });
  }, [personalInfo.companyType, personalInfo.socialStatus]);

  return (
    <div className="ThirdStep">
      {personalSocialOrganisationTypes.socialStatus === 'Военнослужащий' ? (
        <div className="socialCompany_root">
          <h2>Идентификационная информация</h2>

          {personalSocialOrganisationTypes.socialStatus ===
            'Военнослужащий' && (
            <FileUpload
              fileName={
                // @ts-ignore
                (additionalInfo.files?.docScanSocialStatus?.name as string) ||
                ''
              }
              file={file.docScanSocialStatus}
              onFileChange={handleFileChange}
              title={`Скан документа, подтверждающий социальный статус`}
              type="docScanSocialStatus"
              docPhoto={true}
              preventActions={fieldsReadOnly}
            />
          )}
        </div>
      ) : null}

      <div className="title">
        <h2>Фотография и документы</h2>
      </div>

      <div className="photoDocs">
        <PhotoUpload
          // @ts-ignore
          urlImg={(additionalInfo.files?.clientPhoto?.link as string) || ''}
          file={file.clientPhoto}
          onFileChange={handleFileChange}
          title={`Фотография клиента`}
          type="clientPhoto"
        />

        {!fieldsReadOnly ? (
          <FileUpload
            // @ts-ignore
            fileName={(additionalInfo.files?.docPhoto?.name as string) || ''}
            file={file.docPhoto}
            onFileChange={handleFileChange}
            title={
              personalInfo.docType === '1'
                ? 'Фото паспорта'
                : personalInfo.docType === '2'
                ? 'Фото удостоверения личности'
                : personalInfo.docType === '3'
                ? 'Фото вида на жительство'
                : 'Фото документа'
            }
            type="docPhoto"
            docPhoto={true}
            preventActions={fieldsReadOnly}
          />
        ) : (
          <FileDownload
            fileLink={file?.docPhoto?.uuid || ''}
            prevStateTitle={
              personalInfo.docType === '1'
                ? 'Фото паспорта'
                : personalInfo.docType === '2'
                ? 'Фото удостоверения личности'
                : personalInfo.docType === '3'
                ? 'Фото вида на жительство'
                : 'Фото документа'
            }
          />
        )}

        {productInfo.proofInCome ? (
          <>
            {!fieldsReadOnly ? (
              <FileUpload
                fileName={
                  //@ts-ignore
                  (additionalInfo.files?.incomeCert?.name as string) || ''
                }
                file={file.incomeCert}
                onFileChange={handleFileChange}
                title={`Выписка доходов за 6 месяцев`}
                type="incomeCert"
                preventActions={fieldsReadOnly}
              />
            ) : (
              <FileDownload
                fileLink={file?.incomeCert?.uuid || ''}
                prevStateTitle={`Выписка доходов за 6 месяцев`}
              />
            )}
          </>
        ) : null}

        {personalSocialOrganisationTypes.companyType === 'ИП' ? (
          <>
            {!fieldsReadOnly ? (
              <FileUpload
                fileName={
                  // @ts-ignore
                  (additionalInfo.files?.noticeActivity?.name as string) || ''
                }
                file={file.noticeActivity}
                onFileChange={handleFileChange}
                title={`Уведомление о деятельности в качестве ИП`}
                type="noticeActivity"
                docPhoto={true}
                preventActions={fieldsReadOnly}
              />
            ) : (
              <FileDownload
                fileLink={file?.noticeActivity?.uuid || ''}
                prevStateTitle={`Уведомление о деятельности в качестве ИП`}
              />
            )}
          </>
        ) : null}
        {!fieldsReadOnly ? (
          <FileUpload
            // @ts-ignore
            fileName={(additionalInfo.files?.otherDoc?.name as string) || ''}
            file={file.otherDoc}
            onFileChange={handleFileChange}
            title={` Справка о доходах/выписка (с QR)`}
            type="otherDoc"
            docPhoto={true}
            preventActions={fieldsReadOnly}
          />
        ) : (
          <FileDownload
            fileLink={file?.otherDoc?.uuid || ''}
            prevStateTitle={` Справка о доходах/выписка (с QR)`}
          />
        )}

        {/* <FileUpload file={file.otherDoc} onFileChange={handleFileChange} title={`Иные документы`} type="otherDoc"/> */}
      </div>
    </div>
  );
};
