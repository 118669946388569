import { useEffect, useState } from 'react';
import '@/styles/Personal.scss';
import { Radio, RadioChangeEvent, Tabs, TabsProps } from 'antd';
import PersonalInfo from './PersonalInfo';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { useRequestsChangeNavigation } from '@/hooks/useRequestsChangeNavigation';
import { TDataSource } from '@/types/dataSource';
import { setPageTitle } from '@/store/newOrder.slice';
import { getFilterByPersonal, getRequests } from '@/store/request.slice';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import PersonalRequestsInfo from '@/components/App/Personal/PersonalRequestsInfo';
import { getDealersByUserId } from '@/store/dict.slice';
import moment, { Moment } from 'moment';
import { Dayjs } from 'dayjs';
import { TModule } from '@/types/user';
import { request } from 'https';
import PersonalInsuranceInfo from './PersonalInsuranceInfo';
import { getInsurancesByFilters } from '@/store/orderInsurance.slice';

const Personal = () => {
  const dispatch = useAppDispatch();
  const { changeData, handleRequestDetails } = useRequestsChangeNavigation();
  const location = useLocation();

  // const { orderApp } = useAppSelector((state) => state.newOrder);
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { requests } = useAppSelector((state) => state.request);

  const { dicts } = useAppSelector((state) => state.dict);
  const { banksOffers, reqSuccessIndicator } = useAppSelector(
    (state) => state.offerStep
  );

  const [filteredRequests, setFilteredRequest] = useState<TDataSource[]>([]);
  const [termDict, setTermDict] = useState<
    {
      code: number;
      name: string;
      uuid: string;
      brand_uuid?: string | undefined;
      model_uuid?: string | undefined;
      is_active?: boolean | undefined;
      promo_body?: string | undefined;
    }[]
  >([]);
  const [selectedRequests, setSelectedRequests] =
    useState<string>('allRequests');
  const [param, setParam] = useState<{
    bank_uuid?: string;
    status?: string;
    dealer_uuid?: string;
    brand_uuid?: string;
    modal_uuid?: string;
    date_from?: string;
    date_until?: string;
  }>({
    bank_uuid: '',
    status: '',
    dealer_uuid: '',
    brand_uuid: '',
    modal_uuid: '',
    date_from: '',
    date_until: ''
  });

  const [paramInsurances, setParamInsurances] = useState<{
    status?: string;
    brand_uuid?: string;
    modal_uuid?: string;
    date_from?: string;
    date_until?: string;
  }>({
    status: '',
    brand_uuid: '',
    modal_uuid: '',
    date_from: '',
    date_until: ''
  });

  const [bankUuid, setBankUuid] = useState<string>('');
  const [dealerUuid, setDealerUuid] = useState<string>('');
  const [brandUuid, setBrandUuid] = useState<string>('');
  const [modelUuid, setModelUuid] = useState<string>('');

  const [brandIns, setBrandIns] = useState<string>('');
  const [modelIns, setModelIns] = useState<string>('');

  const [selectedAutoType, setSelectedAutoType] =
    useState<string>('autoCredit');

  useEffect(() => {
    dispatch(setPageTitle('Личный кабинет'));
    dispatch(getFilterByPersonal(param));
    dispatch(getInsurancesByFilters(paramInsurances));
  }, []);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedAutoType(e.target.value);
  };

  const handleBankChange = (newBankUuid: string) => {
    // setBankUuid(newBankUuid);
    setParam((prev) => ({ ...prev, bank_uuid: newBankUuid }));
  };

  const handleStatusChange = (newStatus: string) => {
    setParam((prev) => ({ ...prev, status: newStatus }));
  };

  const handleStatusChangeInsuranse = (newStatusIns: string) => {
    setParam((prev) => ({ ...prev, status: newStatusIns }));
  };

  const handleDealerChange = (newDealerUuid: string) => {
    setDealerUuid(newDealerUuid);
    setParam((prev) => ({ ...prev, dealer_uuid: newDealerUuid }));
  };

  const onChangeBrandUuid = (newBrandUuid: string) => {
    setBrandUuid(newBrandUuid);
    setParam((prev) => ({
      ...prev,
      brand_uuid: newBrandUuid
    }));
  };

  const onChangeBrandIns = (newBrandIns: string) => {
    setBrandIns(newBrandIns);
    setParamInsurances((prev) => ({
      ...prev,
      brand_uuid: newBrandIns
    }));
  };

  const handleModelChange = (newModelUuid: string) => {
    setModelUuid(newModelUuid);
    setParam((prev) => ({
      ...prev,
      model_uuid: newModelUuid
    }));
  };
  const handleModelChangeIns = (newModelIns: string) => {
    setModelIns(newModelIns);
    setParamInsurances((prev) => ({
      ...prev,
      model_uuid: newModelIns
    }));
  };

  const handleDateRangeChange = (
    dates: [Dayjs | null, Dayjs | null] | null
  ) => {
    if (dates) {
      const [startDate, endDate] = dates;
      setParam((prev) => ({
        ...prev,
        date_from: startDate ? startDate.format('YYYY-MM-DD') : '',
        date_until: endDate ? endDate.format('YYYY-MM-DD') : ''
      }));
    }
  };

  const handleDateRangeChangeIns = (
    dates: [Dayjs | null, Dayjs | null] | null
  ) => {
    if (dates) {
      const [startDate, endDate] = dates;
      setParamInsurances((prev) => ({
        ...prev,
        date_from: startDate ? startDate.format('YYYY-MM-DD') : '',
        date_until: endDate ? endDate.format('YYYY-MM-DD') : ''
      }));
    }
  };

  const handleResetDateRange = () => {
    setParam((prev) => ({
      ...prev,
      date_from: '',
      date_until: ''
    }));
  };


  const handleResetDateRangeIns = () => {
    setParamInsurances((prev) => ({
      ...prev,
      date_from: '',
      date_until: ''
    }));
  };

  const onChangeModelUuid = (newModelUuid: string) => {
    setModelUuid(newModelUuid);
  };

  useEffect(() => {
    if (reqSuccessIndicator === true && location.pathname !== '/newApp') {
      changeData();
    }
  }, [banksOffers]);

  const handleResetFilters = () => {
    setBankUuid('');
    setParam((prev) => ({ ...prev, bank_uuid: '' }));
    setParam((prev) => ({ ...prev, status: '' }));
    setDealerUuid('');
    setParam((prev) => ({ ...prev, dealer_uuid: '' }));
    setBrandUuid('');
    setModelUuid('');
    setParam((prev) => ({ ...prev, brand_uuid: '' }));
    setParam((prev) => ({ ...prev, model_uuid: '' }));
    setParam((prev) => ({
      ...prev,
      date_from: '',
      date_until: ''
    }));
  };

  const handleResetFiltersIns = () => {
    setParamInsurances((prev) => ({ ...prev, status: '' }));
    setBrandUuid('');
    setModelUuid('');
    setParamInsurances((prev) => ({ ...prev, brand_uuid: '' }));
    setParamInsurances((prev) => ({ ...prev, model_uuid: '' }));
    setParamInsurances((prev) => ({
      ...prev,
      date_from: '',
      date_until: ''
    }));
  };

  const tabsItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Личная информация',
      children: <PersonalInfo userInfo={userInfo} />
    },
    {
      key: '2',
      label: 'Заявки',
      children: (
        <>
          <div className="Personal_vector">
            <div className="Personal_btn">
              <Radio.Group
                value={selectedAutoType}
                onChange={handleRadioChange}
              >
                <Radio.Button value="autoCredit" className="radio-btn">
                  Автокредитование
                </Radio.Button>
                <Radio.Button value="autoInsurance" className="radio-btn">
                  Страхование
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
          {selectedAutoType === 'autoCredit' ? (
            <PersonalRequestsInfo
              setParams={(value) => setParam(value)}
              selectedRequests={selectedRequests}
              onChangeSelectedRequests={(val) => setSelectedRequests(val)}
              termDicts={termDict}
              handleRequestInfo={handleRequestDetails}
              requests={filteredRequests}
              bankUuid={bankUuid}
              status={param.status}
              dealerUuid={dealerUuid}
              brandUuid={brandUuid}
              modelUuid={modelUuid}
              onChangeDealerUuid={handleDealerChange}
              onChangeBankUuid={handleBankChange}
              onChangeStatusUuid={handleStatusChange}
              onChangeBrandUuid={onChangeBrandUuid}
              onChangeModelUuid={handleModelChange}
              param={param}
              userId={userInfo?.uuid}
              onResetBank={handleResetFilters}
              onResetStatus={handleResetFilters}
              onResetDealer={handleResetFilters}
              onResetBrandModel={handleResetFilters}
              onResetDateRange={handleResetDateRange}
              onDateRangeChange={handleDateRangeChange}
              onResetFilters={handleResetFilters}
            />
          ) : (
            <PersonalInsuranceInfo
              status={param.status}
              onChangeStatusInsurance={handleStatusChangeInsuranse}
              setParamInsurance={(value) => setParamInsurances(value)}
              paramInsurances={paramInsurances}
              brandIns={brandIns}
              modelIns={modelIns}
              onChangeBrandIns={onChangeBrandIns}
              handleModelChangeIns={handleModelChangeIns}
              handleResetDateRangeIns={handleResetDateRangeIns}
              handleDateRangeChangeIns={handleDateRangeChangeIns}
          
              onResetStatusIns={handleResetFiltersIns}
          
              onResetBrandModelIns={handleResetFiltersIns}
              onResetDateRangeIns={handleResetFiltersIns}
              onDateRangeChangeIns={handleResetFiltersIns}
              onResetFiltersIns={handleResetFiltersIns}
            />
          )}
        </>
      )
    }
  ];

  return (
    <div className="Personal">
      <div className="content">
        <Tabs defaultActiveKey="1" items={tabsItems} />
      </div>
    </div>
  );
};

export default Personal;
