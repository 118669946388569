import { AxiosInstance } from 'axios';
import { TRoleAction } from '@/types/roleAction.type';

export class RolesActionsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAllRolesActions() {
    const res = await this.api.get('admin/roles_action/getAll');
    return res.data;
  }

  public async createRolesAction(data: TRoleAction) {
    const res = await this.api.post('admin/roles_action/create', data);
    return res.data;
  }

  public async updateRolesAction(data: TRoleAction) {
    const res = await this.api.patch(
      `admin/roles_action/${data.uuid}/update`,
      data
    );
    return res.data;
  }
}
