import { TUser } from '@/types/user.type';
import { setShowUserModal } from '@/store/admin/admin.slice';
import Column from 'antd/es/table/Column';
import { formatPhoneNumber } from '@/services/formatter';
import { Button, Table } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAdminAllUsers } from '@/store/admin/adminUsers.slice';
import SearchInput from '@/components/Shared/Input/SearchInput';
import { CreateUserModal } from '@/components/AdminPanel/Modals/CreateUserModal';
import { PlusCircleOutlined } from '@ant-design/icons';

export const UsersTable: FC = () => {
  const dispatch = useAppDispatch();
  const { showUserModal } = useAppSelector((state) => state.admin);
  const [userForEdit, setUserForEdit] = useState<TUser | null>(null);
  const [searchParam, setSearchParams] = useState<string>('');
  const { users, isLoading, total } = useAppSelector(
    (state) => state.adminUsers
  );

  const [paginationData, setPaginationData] = useState<{
    limit: number;
    page: number;
  }>({
    limit: 10,
    page: 1
  });
  const [usersData, setUsersData] = useState<TUser[]>([]);

  useEffect(() => {
    dispatch(
      getAdminAllUsers({
        limit: paginationData.limit,
        page: paginationData.page,
        param: searchParam
      })
    );
  }, [paginationData, searchParam]);

  useEffect(() => {
    if (Array.isArray(users)) {
      setUsersData(users);
    }
  }, [users]);

  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput
          style={{ width: '50%', marginRight: '10px' }}
          onSearch={(value) => {
            setSearchParams(value);
          }}
        />
        {showUserModal && (
          <CreateUserModal
            userItem={userForEdit}
            editMode={!!userForEdit}
            paginationData={paginationData}
            param={searchParam}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setUserForEdit(null);
            dispatch(setShowUserModal(true));
          }}
        >
          Добавить
        </Button>
      </div>

      <Table
        rowClassName="admin-dealers-table-row"
        rowKey="uuid"
        dataSource={usersData}
        size="small"
        loading={isLoading}
        onRow={(record: TUser) => ({
          onClick: () => {
            setUserForEdit(record);
            dispatch(setShowUserModal(true));
          }
        })}
        pagination={{
          style: { width: '500px', justifyContent: 'flex-start' },
          total: total,
          pageSize: paginationData.limit,
          current: paginationData.page,
          onChange: (step: number, itemsPerPage: number) => {
            setPaginationData({
              page: step,
              limit: itemsPerPage
            });
          }
        }}
      >
        <Column
          key="first_name"
          dataIndex="first_name"
          title="ФИО"
          render={(_, record: TUser) => {
            return `${record.last_name ? record.last_name : ''} ${
              record.first_name ? record.first_name : ''
            } ${record.middle_name ? record.middle_name : ''}`;
          }}
        />
        <Column
          key="email"
          dataIndex="email"
          title="Email/login"
          render={(_, record: TUser) => {
            return (
              <p>
                {record.email ? record.email : ''}/
                {record.login ? record.login : ''}
              </p>
            );
          }}
        />
        <Column
          key="position_uuid"
          dataIndex="position_uuid"
          title="Роль"
          render={(_, record: TUser) => {
            return `${record.position ? record.position.name : ''}`;
          }}
        />
        <Column
          key="phone_number"
          dataIndex="phone_number"
          title="Телефон"
          render={(_, record: TUser) => {
            return formatPhoneNumber(record.phone_number);
          }}
        />
        <Column
          key="is_active"
          title="Статус"
          dataIndex="is_active"
          render={(_, record: TUser) => {
            return record.is_active ? (
              <span style={{ color: 'green' }}>Активно</span>
            ) : (
              <span style={{ color: 'red' }}>Не активно</span>
            );
          }}
          // filterDropdown={() => (
          //   <div style={{ padding: 8 }}>
          //     <Menu
          //       onClick={({ key }) => {
          //         handleStatusFilterChange(key === 'all' ? null : key);
          //       }}
          //     >
          //       <Menu.Item key="all">Все</Menu.Item>
          //       <Menu.Item key="true">Активно</Menu.Item>
          //       <Menu.Item key="false">Не активно</Menu.Item>
          //     </Menu>
          //   </div>
          // )}
          // // filterIcon={(filtered) => <Button>Фильтр</Button>}
          // filterMultiple={false}
        />
      </Table>
    </>
  );
};
