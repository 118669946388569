import { Col, Form, Modal, Row, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import { TPromoProductModel } from '@/types/promotion.type';
import { createPromoProductModel } from '@/store/admin/adminPromoProductModel.slice';

type TCreateOrEditPromoProductModelProps = {
  setShow: () => void;
  editMode?: boolean;
  promoProductModelItem: TPromoProductModel | null;
};

export const CreateOrEditPromoProductModelModal: FC<
  TCreateOrEditPromoProductModelProps
> = ({ setShow, editMode, promoProductModelItem }) => {
  const dispatch = useAppDispatch();
  const { dicts } = useAppSelector((state) => state.dict);
  const [promoProductModel, setPromoProductModel] =
    useState<TPromoProductModel>({
      brand_uuid: '',
      model_uuid: '',
      promo_product_uuid: ''
    });
  const [brandOptions, setBrandOptions] = useState<
    { value: string; label: string | number }[]
  >([]);
  const [modelOptions, setModelOptions] = useState<
    { value: string; label: string | number }[]
  >([]);
  const [productOptions, setProductOptions] = useState<
    { value: string; label: string | number }[]
  >([]);

  useEffect(() => {
    if (editMode && promoProductModelItem)
      setPromoProductModel(promoProductModelItem);

    setBrandOptions(
      findDictByTitle(DictTypes.Brands, dicts).content.map((brand) => ({
        value: brand.uuid,
        label: brand.name
      }))
    );

    setProductOptions(
      findDictByTitle(DictTypes.Products, dicts).content.map((product) => ({
        value: product.uuid,
        label: product.name
      }))
    );
  }, [dicts, editMode, promoProductModelItem]);

  useEffect(() => {
    setModelOptions(
      findDictByTitle(DictTypes.Models, dicts)
        .content.filter(
          (model) => model.brand_uuid === promoProductModel.brand_uuid
        )
        .map((model) => ({ value: model.uuid, label: model.name }))
    );
  }, [dicts, promoProductModel.brand_uuid]);

  const handleChange = (key: keyof TPromoProductModel) => (value: string) =>
    setPromoProductModel((prev) => ({ ...prev, [key]: value }));

  const handleBrandChange = (value: string) => {
    setPromoProductModel((prev) => ({
      ...prev,
      brand_uuid: value,
      model_uuid: ''
    }));
  };

  const handleOk = () => {
    dispatch(createPromoProductModel(promoProductModel));
    setShow();
  };

  return (
    <Modal open={true} onOk={handleOk} onCancel={setShow}>
      <Form layout="vertical" style={{ margin: '30px 0' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Промо продукт">
              <Select
                options={productOptions}
                value={promoProductModel.promo_product_uuid}
                onChange={handleChange('promo_product_uuid')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Бренд">
              <Select
                options={brandOptions}
                value={promoProductModel.brand_uuid}
                onChange={handleBrandChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Модель">
              <Select
                disabled={!promoProductModel.brand_uuid}
                options={modelOptions}
                value={promoProductModel.model_uuid}
                onChange={handleChange('model_uuid')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
