import { FirstStepVerify } from '@/components/Shared/Modal/VerifyOTP/FirstStepVerify';
import React, { FC } from 'react';
import { SecondStepVerify } from './SecondStepVerify';
import { useAppSelector } from '@/store';
import { TClientInfoOtpDto } from '@/types/otp.type';
import '@/styles/otp.scss';

type TVerifyOtpProps = {
  fioInputShow: boolean;
  sendClient: (client: TClientInfoOtpDto) => void;
  isLoading: boolean;
  sendVerifyOtp: (otp: string) => void;
  clientInfo?: TClientInfoOtpDto;
  headerTitle?: string;
};
export const VerifyOTP: FC<TVerifyOtpProps> = ({
  fioInputShow,
  sendClient,
  isLoading,
  sendVerifyOtp,
  clientInfo,
  headerTitle
}) => {
  const { showVerifyFirstStep, showVerifySecondStep } = useAppSelector(
    (state) => state.app
  );
  return (
    <>
      {showVerifyFirstStep ? (
        <FirstStepVerify
          fioInputShow={fioInputShow}
          sendClient={(value) => sendClient(value)}
          isLoading={isLoading}
          clientInfoItem={clientInfo}
        />
      ) : showVerifySecondStep ? (
        <SecondStepVerify
          sendValidateOtp={(value) => sendVerifyOtp(value)}
          isLoading={isLoading}
          headerTitle={headerTitle}
        />
      ) : null}
    </>
  );
};
