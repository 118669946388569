import '@/styles/ClientCalculationsData.scss';
import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { TClientCalculations } from '@/types/clients';

export type TClientCalculationDataProps = {
  calculations: TClientCalculations[];
  brandDicts: {
    code: number;
    name: string;
    uuid: string;
    brand_uuid?: string | undefined;
    model_uuid?: string | undefined;
    is_active?: boolean | undefined;
    promo_body?: string | undefined;
  }[];
};

const { Panel } = Collapse;
const ClientCalculationsData = ({
  brandDicts,
  calculations
}: TClientCalculationDataProps) => {
  return (
    <div className="ClientCalculationsData_root">
      <h2>Расчеты: {calculations.length}</h2>
      <Collapse
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <DownOutlined
            style={{
              marginTop: '30px',
              width: '24px',
              height: '24px'
            }}
            rotate={isActive ? 180 : 0}
          />
        )}
      >
        {calculations?.length &&
          calculations.map((calculation) => {
            return (
              <Panel
                key={calculation.uuid}
                header={
                  <div className="Collapse_header">
                    <div>
                      <h2>{`${
                        brandDicts.find(
                          (brand) =>
                            brand.uuid === calculation.markModelYear.mark
                        )?.name
                      } ${calculation.markModelYear.model}, ${
                        calculation.markModelYear.year
                      }г.`}</h2>
                      {/* Код для точки по середине строки */}
                      <p>
                        {calculation.dealer}
                        <span className="separate_dot">&#183;</span>
                        {calculation.carCost} ₸
                        <span className="separate_dot">&#183;</span>
                        Создано:{' '}
                        {dayjs(calculation?.calculationDate).format(
                          'DD.MM.YYYY'
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: '4px 16px',
                        borderRadius: '8px',
                        background: '#FE964A',
                        width: '90px'
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center',
                          fontSize: '10px',
                          fontWeight: '700',
                          color: 'white'
                        }}
                      >
                        РАСЧЕТ
                      </p>
                    </div>
                  </div>
                }
              >
                <table>
                  <tr className="Table_header">
                    <th>ID заявки</th>
                    <th>Дата создания заявки</th>
                    <th>Марка и модель,год выпуска</th>
                    <th>Стоимость авто</th>
                    <th>Перв. взнос</th>
                    <th>Срок займа</th>
                    <th>Дата выполнения расчета</th>
                  </tr>

                  <tr className="Table_body">
                    <td> - </td>
                    <td> - </td>
                    <td>{`${
                      brandDicts.find(
                        (brand) => brand.uuid === calculation.markModelYear.mark
                      )?.name
                    } ${calculation.markModelYear.model}, ${
                      calculation.markModelYear.year
                    }г.`}</td>
                    <td>{calculation.carCost} ₸</td>
                    <td>{calculation.initFee} ₸</td>
                    <td>{calculation.loanTerm} мес.</td>
                    <td>
                      {dayjs(calculation.calculationDate).format('DD.MM.YYYY')}
                    </td>
                  </tr>
                </table>
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

export default ClientCalculationsData;
