import React, { useEffect, useState } from 'react';
import { Button, Menu, Table } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import Column from 'antd/es/table/Column';
import { setShowModalCreateOrEditPromoProductModel } from '@/store/admin/admin.slice';
import SearchInput from '@/components/Shared/Input/SearchInput';
import { PlusCircleOutlined } from '@ant-design/icons';
import { getAllPromoProductModel } from '@/store/admin/adminPromoProductModel.slice';
import { TPromoProductModel } from '@/types/promotion.type';
import { CreateOrEditPromoProductModelModal } from '@/components/AdminPanel/Modals/CreateOrEditPromoProductModelModal';
import { TDealer } from '@/types/dealer.type';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';

const PromoProductModel = () => {
  const dispatch = useAppDispatch();
  const { showModalCreateOrEditPromoProductModel } = useAppSelector(
    (state) => state.admin
  );
  const { dicts } = useAppSelector((state) => state.dict);
  const { promoProductModel, total, isLoading } = useAppSelector(
    (state) => state.adminPromoProductModel
  );
  const [productForEdit, setProductForEdit] =
    useState<TPromoProductModel | null>(null);
  const [productData, setProductData] = useState<TPromoProductModel[]>([]);
  const [paginationData, setPaginationData] = useState<{
    limit: number;
    page: number;
  }>({
    limit: 10,
    page: 1
  });
  const [brandFilterOptions, setBrandFilterOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    dispatch(getAllPromoProductModel());

    const brands = findDictByTitle(DictTypes.Brands, dicts).content.map(
      (brand) => {
        return { value: brand.uuid, label: brand.name };
      }
    );

    setBrandFilterOptions(brands);
  }, []);

  useEffect(() => {
    setProductData(promoProductModel);
  }, [promoProductModel]);

  return (
    <>
      <p>PRODUCTS</p>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput style={{ width: '50%', marginRight: '10px' }} />
        {showModalCreateOrEditPromoProductModel && (
          <CreateOrEditPromoProductModelModal
            setShow={() =>
              dispatch(setShowModalCreateOrEditPromoProductModel(false))
            }
            editMode={!!productForEdit}
            promoProductModelItem={productForEdit}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setProductForEdit(null);
            dispatch(setShowModalCreateOrEditPromoProductModel(true));
          }}
        >
          Добавить
        </Button>
      </div>
      <Table
        rowClassName="admin-dealers-table-row"
        dataSource={productData}
        rowKey="uuid"
        size={'small'}
        onRow={(record: TPromoProductModel) => ({
          onClick: () => {
            setProductForEdit(record);
            dispatch(setShowModalCreateOrEditPromoProductModel(true));
          }
        })}
        loading={isLoading}
        pagination={{
          style: { width: '500px', justifyContent: 'flex-start' },
          total: total,
          pageSize: paginationData.limit,
          current: paginationData.page,
          onChange: (step: number, itemsPerPage: number) => {
            setPaginationData({
              page: step,
              limit: itemsPerPage
            });
          }
        }}
      >
        <Column
          key="brand_uuid"
          title="Бренд"
          dataIndex="brand_uuid"
          render={(_: TPromoProductModel, record: TPromoProductModel) => {
            return record.brand ? record.brand.name : 'No data';
          }}
          filterMultiple={false}
          // sorter={(a: TProduct, b: TProduct) => a.code.localeCompare(b.code)}
          filterDropdown={() => (
            <div style={{ padding: 8 }}>
              <Menu
                onClick={({ key }) => {
                  // handleStatusFilterChange(key === 'all' ? null : key);
                }}
              >
                {brandFilterOptions.map((item) => (
                  <Menu.Item key={item.value}>{item.label}</Menu.Item>
                ))}
                {/*<Menu.Item key="all">Все</Menu.Item>*/}
                {/*<Menu.Item key="true">Активно</Menu.Item>*/}
                {/*<Menu.Item key="false">Не активно</Menu.Item>*/}
              </Menu>
            </div>
          )}
        />
        <Column
          key="model_uuid"
          title="Модель"
          dataIndex="model_uuid"
          render={(_, record: TPromoProductModel) => {
            return record.model ? record.model.name : 'No data';
          }}
        />
        <Column
          key="product_uuid"
          title="Продукт"
          dataIndex="product_uuid"
          render={(_, record: TPromoProductModel) => {
            return record.promo_product ? record.promo_product.name : 'No data';
          }}
        />
        <Column
          key="is_active"
          title="Статус"
          dataIndex="is_active"
          render={(_, record: TDealer) => {
            return record.is_active ? (
              <span style={{ color: 'green' }}>Активно</span>
            ) : (
              <span style={{ color: 'red' }}>Не активно</span>
            );
          }}
          // filterDropdown={() => (
          //   <div style={{ padding: 8 }}>
          //     <Menu
          //     // onClick={({ key }) => {
          //     //   handleStatusFilterChange(key === 'all' ? null : key);
          //     // }}
          //     >
          //       <Menu.Item key="all">Все</Menu.Item>
          //       <Menu.Item key="true">Активно</Menu.Item>
          //       <Menu.Item key="false">Не активно</Menu.Item>
          //     </Menu>
          //   </div>
          // )}
          // // filterIcon={(filtered) => <Button>Фильтр</Button>}
          // filterMultiple={false}
        />
      </Table>
    </>
  );
};

export default PromoProductModel;
