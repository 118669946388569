import { Col, Form, Input, Modal, Row, Select } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import { TProduct } from '@/types/product.type';
import '@/styles/AdminModal.scss';
import {
  createProduct,
  getProducts,
  updateProduct
} from '@/store/admin/adminProducts.slice';
import { getModels } from '@/store/admin/adminModels.slice';

type TCreateOrEditProductProps = {
  setShow: () => void;
  editMode: boolean;
  productItem: TProduct | null;
  paginationData: { limit: number; page: number };
  searchParam: string;
};

type Option = { value: string; label: string };

export const CreateOrEditProductModal: FC<TCreateOrEditProductProps> = ({
  setShow,
  editMode,
  productItem,
  paginationData,
  searchParam
}) => {
  const dispatch = useAppDispatch();
  const { dicts } = useAppSelector((state) => state.dict);
  const { models } = useAppSelector((state) => state.adminModels);
  const [product, setProduct] = useState<TProduct>({} as TProduct);
  const [options, setOptions] = useState<{
    banks: Option[];
    productTypes: Option[];
    brands: Option[];
    models: Option[];
    insurance: Option[];
  }>({
    banks: [],
    productTypes: [],
    brands: [],
    models: [],
    insurance: []
  });
  const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
  const [deleteList, setDeleteList] = useState<
    { ppm_uuid: string; model_uuid: string }[]
  >([]);

  const findBrandByProductName = (productData: TProduct) => {
    const arr = productData.name.toLowerCase().split(' ');
    const matchedBrand = options.brands.find((brand) =>
      arr.includes(brand.label.toLowerCase())
    );
    if (matchedBrand) {
      setSelectedBrand(matchedBrand.value);
    }
  };

  // Устанавливаем начальные данные
  useEffect(() => {
    if (productItem) {
      setProduct(productItem);
    }
  }, [productItem]);

  useEffect(() => {
    if (productItem) {
      findBrandByProductName(productItem);

      if (productItem.Promo_Products_Models) {
        const data: { ppm_uuid: string; model_uuid: string }[] =
          productItem.Promo_Products_Models.map((item) => ({
            ppm_uuid: item.uuid!,
            model_uuid: item.model_uuid
          }));
        setDeleteList(data);
        setProduct((prevState) => ({
          ...prevState,
          Models_UUID: data.map((item) => item.model_uuid)
        }));
      }
    }
  }, [options.brands]);

  // Обновляем опции и находим бренд по имени продукта
  useEffect(() => {
    if (dicts?.length) {
      const getOptions = (type: DictTypes) =>
        findDictByTitle(type, dicts).content.map(
          ({ uuid, name, fullname }) => ({
            value: uuid,
            label: fullname || name
          })
        );

      setOptions({
        banks: getOptions(DictTypes.Banks),
        productTypes: getOptions(DictTypes.ProductTypes),
        brands: getOptions(DictTypes.Brands),
        models: [],
        insurance: getOptions(DictTypes.Insurances)
      });

      if (productItem) {
      }
    }
  }, []);

  // Обновляем модели при изменении выбранного бренда
  useEffect(() => {
    if (selectedBrand) {
      dispatch(getModels({ brandUuid: selectedBrand }));
    }
  }, [selectedBrand, dicts]);

  useEffect(() => {
    const modelsOption = models.map((model) => ({
      value: model.uuid,
      label: model.name
    }));

    setOptions((prevState) => ({
      ...prevState,
      models: modelsOption
    }));
  }, [models]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProduct((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCheckboxChange = (key: keyof TProduct) => {
    setProduct((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onOk = () => {
    const productData = {
      ...product,
      initial_payment: Number(product.initial_payment),
      loan_amount: Number(product.loan_amount),
      loan_period: Number(product.loan_period),
      initial_payment_from: Number(product.initial_payment_from),
      initial_payment_to: Number(product.initial_payment_to),
      percent_from: Number(product.percent_from)
      // Delete_Models_UUID: deleteList
    };

    if (editMode) {
      const filteredList = deleteList.filter(
        (item) => !product.Models_UUID?.includes(item.model_uuid)
      );
      dispatch(
        updateProduct({
          ...productData,
          Delete_Promo_UUID: filteredList.map((item) => item.ppm_uuid)
        })
      ).then(() => {
        dispatch(
          getProducts({
            limit: paginationData.limit,
            page: paginationData.page,
            param: searchParam
          })
        );
      });
      return setShow();
    }

    dispatch(createProduct(productData)).then(() => {
      dispatch(
        getProducts({
          limit: paginationData.limit,
          page: paginationData.page,
          param: searchParam
        })
      );
    });
    setShow();
  };

  return (
    <Modal open onOk={onOk} onCancel={setShow} width={720} className="modal">
      <Form layout="vertical" style={{ margin: '30px 0' }}>
        <Row>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={20}>
                <Form.Item label="Наименование" required>
                  <Input
                    name="name"
                    value={product.name}
                    onChange={onInputChange}
                    onBlur={() => findBrandByProductName(product)}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Код" required>
                  <Input
                    name="code"
                    value={product.code}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {product.product_type?.name === 'Акция' ||
                product.product_type?.name === 'Субсидия' || (
                  <>
                    <Col span={6}>
                      <Form.Item label="Код партнера">
                        <Input
                          name="partner_code"
                          value={product.partner_code || ''}
                          onChange={onInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Использовать по умолчанию">
                        <Checkbox
                          checked={product.enabled_by_default}
                          onChange={() =>
                            handleCheckboxChange('enabled_by_default')
                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              <Col
                span={5}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Form.Item label="Активно">
                  <Checkbox
                    checked={product.is_active}
                    onChange={() => handleCheckboxChange('is_active')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Is PROMO">
                  <Checkbox
                    checked={product.is_promo}
                    onChange={() => handleCheckboxChange('is_promo')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Банк" required>
                  <Select
                    options={options.banks}
                    value={product.bank_uuid}
                    onChange={(value) =>
                      setProduct((prev) => ({ ...prev, bank_uuid: value }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Тип продукта" required>
                  <Select
                    options={options.productTypes}
                    value={product.product_type_uuid}
                    onChange={(value) =>
                      setProduct((prev) => ({
                        ...prev,

                        product_type_uuid: value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Grace period">
                  <Input
                    name="grace_period"
                    value={product.grace_period || ''}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Страхование">
                  <Select
                    options={options.insurance}
                    value={product.insurance_uuid}
                    onChange={(value) =>
                      setProduct((prev) => ({ ...prev, insurance_uuid: value }))
                    }
                  />
                </Form.Item>
              </Col>
              {product.product_type?.name === 'Акция' ||
                product.product_type?.name === 'Субсидия' || (
                  <>
                    <Col span={8}>
                      <Form.Item label="Loan Amount">
                        <Input
                          name="loan_amount"
                          value={product.loan_amount || ''}
                          onChange={onInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Loan Period">
                        <Input
                          name="loan_period"
                          value={product.loan_period || ''}
                          onChange={onInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
            </Row>
            <Row gutter={16}>
              {product.product_type?.name === 'Акция' ||
                product.product_type?.name === 'Субсидия' || (
                  <>
                    <Col span={12}>
                      <Form.Item label="Bank tariff name">
                        <Input
                          name="bank_tariff_name"
                          value={product.bank_tariff_name || ''}
                          onChange={onInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Percent from">
                        <Input
                          name="percent_from"
                          value={product.percent_from || ''}
                          onChange={onInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
            </Row>
            <Row gutter={16}>
              {product.product_type?.name === 'Акция' ||
                product.product_type?.name === 'Субсидия' || (
                  <Col span={12}>
                    <Form.Item label="Код продукта">
                      <Input
                        name="product_code"
                        value={product.product_code || ''}
                        onChange={onInputChange}
                      />
                    </Form.Item>
                  </Col>
                )}
              <Col span={12}>
                <Form.Item label="Первоначальный взнос">
                  <Input
                    name="initial_payment"
                    value={product.initial_payment || ''}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {product.product_type?.name === 'Акция' ||
                product.product_type?.name === 'Субсидия' || (
                  <>
                    <Col span={12}>
                      <Form.Item label="Initial payment from">
                        <Input
                          name="initial_payment_from"
                          value={product.initial_payment_from || ''}
                          onChange={onInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Initial payment to">
                        <Input
                          name="initial_payment_to"
                          value={product.initial_payment_to || ''}
                          onChange={onInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="promo body">
                  <Input
                    name="promo_body"
                    value={product.promo_body || ''}
                    onChange={onInputChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/*{(product.product_type?.name === 'Акция' ||*/}
            {/*  product.product_type?.name === 'Субсидия') && (*/}
            <Row>
              <Col span={11}>
                <Form.Item label="Бренд">
                  <Select
                    options={options.brands}
                    value={selectedBrand}
                    onChange={(value) => setSelectedBrand(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Модели">
                  <Select
                    mode="multiple"
                    maxTagCount={1}
                    options={options.models}
                    value={product.Models_UUID}
                    onChange={(value) =>
                      setProduct((prev) => ({
                        ...prev,
                        Models_UUID: value
                      }))
                    }
                    disabled={!!!options.models.length}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/*)}*/}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
