import React, { FC, ReactNode } from 'react';
import {
  AntDesignOutlined,
  BankOutlined,
  CarOutlined,
  ConsoleSqlOutlined,
  FundProjectionScreenOutlined,
  IdcardOutlined,
  LogoutOutlined,
  ProfileOutlined,
  ProjectOutlined,
  ReadOutlined,
  ShopOutlined,
  TagsOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { DictTypes } from '@/constants';
import { dictsNavigateMapper } from '@/services/dictsNavigateMapper';
import { NavLink } from 'react-router-dom';

type TAdminNavBarProps = {
  isSidebarExpanded: boolean;
};

const LAYOUT_DICTS = [
  DictTypes.Statuses,
  DictTypes.ResidentStatuses,
  DictTypes.SocialStatuses,
  DictTypes.AddContactsIsRelated,
  DictTypes.OrganizationTypes,
  DictTypes.DocumentTypes,
  DictTypes.Brands,
  // DictTypes.Banks,
  DictTypes.LoanTerms,
  DictTypes.EngineTypes
  // DictTypes.DocumentInsuringAuthorities
  // DICT_TYPES.Models
];

const adminSideBarItems: {
  label: ReactNode | string;
  key: string;
  icon: ReactNode | null;
  children?: {
    label: ReactNode;
    key: string;
    icon: ReactNode;
  }[];
}[] = [
  {
    label: <NavLink to="/autocred">На главную</NavLink>,
    key: '/main',
    icon: <LogoutOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/dealers">Дилерские центры</NavLink>,
    key: '/dealers',
    icon: <ShopOutlined style={{ fontSize: '20px' }} />
  },
  // {
  //   label: <NavLink to="/adminPage/sale-points">Торговые точки</NavLink>,
  //   key: '/sale-points',
  //   icon: <ShopOutlined />
  // },
  {
    label: <NavLink to="/adminPage/banks">Банки</NavLink>,
    key: '/banks',
    icon: <BankOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/clients">Клиенты</NavLink>,
    key: '/clients',
    icon: <IdcardOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/promotion">Акции/Субсидии</NavLink>,
    key: '/promotion',
    icon: <TagsOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/products">Продукты</NavLink>,
    key: '/products',
    icon: <ProjectOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/models">Модели</NavLink>,
    key: '/models',
    icon: <CarOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/users">Пользователи</NavLink>,
    key: '/roles',
    icon: <UserOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/executor">SQL-запросы</NavLink>,
    key: '/executor',
    icon: <ConsoleSqlOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/ide">IDE</NavLink>,
    key: '/ide',
    icon: <AntDesignOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/logs">Логи</NavLink>,
    key: '/logs',
    icon: <ProfileOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: <NavLink to="/adminPage/reports">Отчеты</NavLink>,
    key: '/reporst',
    icon: <FundProjectionScreenOutlined />
  },
  {
    label: 'Справочники',
    key: '/dictionaries',
    icon: <ReadOutlined style={{ fontSize: '20px' }} />,
    children: Object.keys(LAYOUT_DICTS)
      .map((key: any) => dictsNavigateMapper(LAYOUT_DICTS[key]))
      .map((dict) => ({
        label: <NavLink to={dict.path}>{dict.label}</NavLink>,
        icon: dict.icon,
        key: dict.key
      }))
  }
];

export const AdminNavBar: FC<TAdminNavBarProps> = ({ isSidebarExpanded }) => {
  return (
    <>
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={['1']}
        inlineCollapsed={!isSidebarExpanded}
        defaultOpenKeys={['sub1']}
        style={{
          marginTop: '20px',
          marginLeft: isSidebarExpanded ? '-30px' : '0px',
          boxSizing: 'border-box',
          height: '100%',
          borderRight: 0,
          background: '#1f2937',
          transition: '0.7s',
          width: !isSidebarExpanded ? '50px' : '230px'
        }} //#1f2937
        items={adminSideBarItems}
      />
    </>
  );
};
