import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiConnector } from '../integrations/api.connector';
import { TAuthStore } from '../types/store';
import axios from 'axios';

export const forgotePassword = createAsyncThunk(
  'auth/resetPassword',
  async (email: string): Promise<any> => {
    try {
      const res = await apiConnector.forgotePassword(email);
      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({
    email,
    password,
    isRemember
  }: {
    email: string;
    password: string;
    isRemember: boolean;
  }): Promise<any> => {
    try {
      // TODO: Превышен лимит использования. Комментируется, до восстановления
      const response = await axios.get(
        'https://api.ipdata.co?api-key=a8d0725d9ce39971566005bdbbf47348d9bfec23a17e49cf080f2ec4'
      );
      const { ip, latitude, longitude } = await response.data;

      const lat = latitude?.toString();
      const long = longitude?.toString();

      return await apiConnector.authJwt(
        email,
        password,
        isRemember,
        ip,
        lat,
        long
      );
    } catch (e: any) {
      // throw new Error(e);
      return await apiConnector.authJwt(
        email,
        password,
        isRemember,
        '',
        '',
        ''
      );
    }
  }
);

export const tokenValidate = createAsyncThunk('auth/validate', async () => {
  try {
    return await apiConnector.isValid();
  } catch (e: any) {
    throw new Error(e);
  }
});

const initialState: TAuthStore = {
  resetEmail: null,
  isLoading: false,
  loginError: false,
  isUserAuth: true
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setResetEmail: (state, action) => {
      state.resetEmail = action.payload;
    },
    resetLoginError: (state) => {
      state.loginError = false;
    },
    toggleLoginError: (state) => {
      state.loginError = !state.loginError;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isUserAuth = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.loginError = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status) {
          state.isUserAuth = true;
          state.loginError = false;
        } else {
          state.isUserAuth = false;
          state.loginError = true;
        }
      })
      .addCase(tokenValidate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(tokenValidate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(tokenValidate.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === false) {
          state.isUserAuth = false;
          // localStorage.clear();
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userSearchType');
          localStorage.removeItem('isRemember');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('emailAllur');
          localStorage.removeItem('paginationInfo');
          localStorage.removeItem('dealer');
          localStorage.removeItem('isAuthenticated');
          // window.location.reload();
        } else {
          state.isUserAuth = true;
        }
      })
      .addCase(forgotePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotePassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(forgotePassword.fulfilled, (state) => {
        state.isLoading = false;
      });
  }
});

export const { setResetEmail, setIsAuth, resetLoginError, toggleLoginError } =
  authSlice.actions;
