import '@/styles/PersonalInfo.scss';
import dayjs, { Dayjs } from 'dayjs';
import { useAppSelector } from '@/store';
import React, { useEffect, useState } from 'react';
import { TUserInfo } from '@/types/user';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomDatePicker } from '@/components/Shared/DatePicker/CustomDatePicker';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import { PhotoUpload } from '@/components/Shared/Input/PhotoUpload';

type TPersonalInfoProps = {
  userInfo: TUserInfo | null;
};
const PersonalInfo = ({ userInfo }: TPersonalInfoProps) => {
  const dealers = useAppSelector((state) => state.dict.activeDealersOption);
  const [dealerName, setDealerName] = useState<string>('');
  useEffect(() => {
    const dealer = localStorage.getItem('dealer');
    if (dealer) {
      const selectedDealer:
        | null
        | {
            value: string;
            label: string;
            code: string;
          }
        | undefined =
        dealers &&
        dealers.find((_dealer) => _dealer.code.toString() === dealer);
      if (selectedDealer) {
        setDealerName(selectedDealer.label);
      }
    }
  }, []);

  return (
    <div className="PersonalInfo">
      {/* <div className="dealerPoints_wrapper">
        <h2>Дилерские центры</h2>
        <div className="dealerPoints_info">
          <p>{dealerName}</p>
      
        </div>
      </div> */}
      <div className="PersonalInfo_block">
        <p>Полное ФИО:</p>
        <h2>{`${userInfo?.last_name || ''} ${userInfo?.middle_name || ''} ${
          userInfo?.first_name || ''
        }`}</h2>
      </div>
      <div className="PersonalInfo_block">
        <p>Почта:</p>
        <h2>{userInfo?.email || ''}</h2>
      </div>
      <div className="PersonalInfo_block">
        <p>Дата рождения:</p>
        <h2>
          {userInfo?.birth_date
            ? dayjs(userInfo?.birth_date).format('DD.MM.YYYY')
            : ''}
        </h2>
      </div>
      <div className="PersonalInfo_block">
        <p>Номер телефона:</p>
        <h2>{userInfo?.phone_number || ''}</h2>
      </div>
      <div className="PersonalInfo_block">
        <p>Дата регистрации:</p>
        <h2>{dayjs(userInfo?.work_date_start).format('DD.MM.YYYY') || ''}</h2>
      </div>
    </div>
  );
};

export default PersonalInfo;
