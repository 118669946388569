import { ColumnsType } from 'antd/es/table';
import { TLeasingTableData } from '../types/leasing';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Button, Popconfirm, Tooltip } from 'antd';
import { apiConnector } from '../integrations/api.connector';
import { getAllLeasings, getLeasingById } from '../store/leasings.slice';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../store';

type TLeasingDownloadCell = {
  record: TLeasingTableData;
};

type TCellInnerProps = {
  title: string;
  content: string;
};

const CellInner: FC<TCellInnerProps> = ({ title, content }) => {
  return (
    <>
      <h2 style={{ fontSize: '12px', fontWeight: '600', color: '#111827' }}>
        {title}
      </h2>
      <p style={{ fontSize: '12px', fontWeight: '400', color: '#687588' }}>
        {content}
      </p>
    </>
  );
};

const ActionCell = ({ record }: TLeasingDownloadCell) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPopConfirm, setShowPopConfirm] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [buttonDisableControl, setButtonDisableControl] = useState<{
    changeStatusDisabled: boolean;
    downloadDisabled: boolean;
  }>({
    changeStatusDisabled: false,
    downloadDisabled: false
  });
  const downloadFile = async (e: any) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      const leasingToDownload = await apiConnector.downloadLeasing(record.uuid);
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${leasingToDownload.tplValues}`;
      link.download = 'Лизинг';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateLeasingStatus = async (e: any) => {
    e?.stopPropagation();
    try {
      const data = await apiConnector.updateLeasingStatus(record.uuid);
      setShowPopConfirm(false);
      await dispatch(getAllLeasings());
    } catch (e: any) {
      throw new Error(e);
    }
  };

  useEffect(() => {
    setButtonDisableControl({
      downloadDisabled: record.status === 'leasing_draft',
      changeStatusDisabled: record.status !== 'leasing_created'
    });
  }, [record]);

  return (
    <div>
      <Tooltip title={'Скачать файл'}>
        <Button
          type="link"
          disabled={buttonDisableControl.downloadDisabled}
          onClick={downloadFile}
          loading={isLoading}
          icon={<DownloadOutlined />}
        ></Button>
      </Tooltip>
      <Tooltip title={'Профинансировать лизинг'}>
        <Popconfirm
          title="Профинонсировать лизинг"
          description="Вы уверены что хотите профинансирвоать лизинг?"
          open={showPopConfirm}
          onConfirm={updateLeasingStatus}
          onCancel={(e) => {
            e?.stopPropagation();
            setShowPopConfirm(false);
          }}
        >
          <Button
            disabled={buttonDisableControl.changeStatusDisabled}
            onClick={(e) => {
              e.stopPropagation();
              setShowPopConfirm(true);
            }}
            type="link"
            icon={<ReloadOutlined />}
          ></Button>
        </Popconfirm>
      </Tooltip>
    </div>
  );
};
export const leasingColumnsData: ColumnsType<TLeasingTableData> = [
  {
    title: 'ИИН',
    dataIndex: 'iin',
    key: 'iin',
    width: 150,
    sorter: (a, b) =>
      a.identificationDocument.localeCompare(b.identificationDocument),
    render: (text, record) => {
      return (
        <div>
          <CellInner title={record.identificationDocument} content={''} />
        </div>
      );
    }
  },
  // {
  //   title: 'Дата рождения',
  //   dataIndex: 'birthDate',
  //   key: 'birthDate',
  //   width: 200,
  //   sorter: (a, b) => a.birthDate.localeCompare(b.birthDate),
  //   render: (text, record) => {
  //     return <div>{dayjs(record.birthDate).format('DD-MM-YYYY')}</div>;
  //   }
  // },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 150,
    sorter: (a, b) => a.createDate.localeCompare(b.createDate),
    render: (text, record) => {
      return (
        <div>
          <CellInner
            title={dayjs(record.createDate).format('DD.MM.YYYY')}
            content={record?.user?.userName || ''}
          />
        </div>
      );
    }
  },
  {
    title: 'Дата обновления статуса',
    dataIndex: 'birthPlace',
    key: 'birthPlace',
    width: 160,
    sorter: (a, b) => a?.updateDate?.localeCompare(b?.updateDate),
    render: (text, record) => {
      return (
        <div>
          <CellInner
            title={
              record.updateDate
                ? dayjs(record.updateDate).format('DD-MM-YYYY')
                : 'Нет данных'
            }
            content={''}
          />
        </div>
      );
    }
  },
  {
    title: 'Номер телефона',
    dataIndex: 'personalPhoneNumber',
    key: 'personalPhoneNumber',
    width: 130,
    render: (text, record) => {
      return (
        <div>
          <CellInner title={record.personalPhoneNumber} content={''} />
        </div>
      );
    }
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: 130,
    sorter: (a, b) => a.status.localeCompare(b.status),
    render: (text, record) => {
      return (
        <div>
          {record.status === 'leasing_created' ? (
            <div
              style={{
                padding: '4px 16px',
                borderRadius: '8px',
                background: '#e16103',
                width: '90px'
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '10px',
                  fontWeight: '700',
                  color: 'white'
                }}
              >
                CREATED
              </p>
            </div>
          ) : record.status === 'leasing_financed' ? (
            <div
              style={{
                padding: '4px 16px',
                borderRadius: '8px',
                background: '#0CAF60',
                width: '90px'
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '10px',
                  fontWeight: '700',
                  color: 'white'
                }}
              >
                FINANCED
              </p>
            </div>
          ) : (
            <div
              style={{
                padding: '4px 16px',
                borderRadius: '8px',
                background: 'rgb(17 24 39)',
                width: '90px'
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '10px',
                  fontWeight: '700',
                  color: 'white'
                }}
              >
                DRAFT
              </p>
            </div>
          )}
        </div>
      );
    }
  },
  {
    title: 'Действия',
    dataIndex: 'actions',
    key: 'actions',
    width: 100,
    render: (text, record) => {
      return <ActionCell record={record} />;
    }
  }
];
