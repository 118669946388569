import { useAppDispatch, useAppSelector } from '@/store';
import { Button, Drawer } from 'antd';
import { onToggleShowNotifications } from '@/store/notifications.slice';
import NotificationItem from './NotificationItem';
import '@/styles/NotificationSidebar.scss';

const NotificationsSideBar = () => {
  const { notifications, showNotifications } = useAppSelector(
    (state) => state.notifications
  );
  const dispatch = useAppDispatch();

  return (
    <div className="NotificationsSideBar">
      <Drawer
        title={'Уведомления'}
        closable={false}
        open={showNotifications}
        onClose={() => dispatch(onToggleShowNotifications())}
      >
        <Button style={{ width: '100%', marginBottom: '20px' }}>
          Удалить все уведомления
        </Button>
        {notifications.length
          ? notifications.map((notification) => (
              <NotificationItem
                notification={notification}
                key={notification.uuid}
              />
            ))
          : null}
      </Drawer>
    </div>
  );
};

export default NotificationsSideBar;
