import React, { useEffect, useState } from 'react';
import { Select, Switch } from 'antd';
import '@/styles/ProductItem.scss';
import { TAddProducts } from '@/types/productItem';
import { useAppSelector } from '@/store';
import {
  pathAltynLogo,
  pathBckLogo,
  pathBerekeLogo,
  pathEurasLogo,
  pathForteLogo,
  pathFreedomlogo,
  pathHalykLogo,
  pathKmfLogo
} from '@/constants';
import { addProductsLoanPeriodOptions } from '@/data/addProductsLoanPeriodOptions.data';
import { TStockBondsState } from '@/types/state';

type TProductItemProps = {
  handleStockBoundChange: (id: string) => void;
  showMinorProducts: boolean;
  handleChangeAddProductLoanPeriod: (data: {
    value: string;
    id: string;
  }) => void;
  sortedProductInfo: {
    title: string;
    addProducts: TAddProducts[];
  };
  handleChangeProdInsurance: (data: {
    organisationId: string;
    addProductId: string;
  }) => void;
  stocksBonds: TStockBondsState;
};

export const ProductItem = ({
  sortedProductInfo,
  handleStockBoundChange,
  handleChangeAddProductLoanPeriod,
  handleChangeProdInsurance,
  showMinorProducts,
  stocksBonds
}: TProductItemProps) => {
  const { addProductsInsurances } = useAppSelector((state) => state.dict);
  const [periodOption, setPeriodOption] = useState<
    { value: string; label: string }[]
  >([]);
  const [period, setPeriod] = useState<number>();
  // const {
  //   dict: { addProductsInsurances }
  // } = useAppSelector((state) => state);
  useEffect(() => {
    const selectedPeriod = stocksBonds.find(
      (promo) => promo.label.includes('Евразийский') && promo.checked
    );
    if (selectedPeriod) {
      sortedProductInfo.addProducts.forEach((product) => {
        if (product.label === 'Каско СК "Евразия"' && periodOption.length > 0) {
          handleChangeAddProductLoanPeriod({
            value: periodOption[0].value,
            id: product.id
          });
        }
      });
      if (selectedPeriod.label.includes('12')) {
        setPeriod(12);
      } else if (selectedPeriod.label.includes('24')) {
        setPeriod(24);
      } else if (selectedPeriod.label.includes('36')) {
        setPeriod(36);
      }
    }
  }, [stocksBonds]);

  useEffect(() => {
    if (period === 12) {
      setPeriodOption(addProductsLoanPeriodOptions.slice(0, 1));
    } else if (period === 24) {
      setPeriodOption(addProductsLoanPeriodOptions.slice(0, 2));
    } else if (period === 36) {
      setPeriodOption(addProductsLoanPeriodOptions.slice(0, 3));
    } else {
      setPeriodOption(addProductsLoanPeriodOptions);
    }
  }, [period]);
  return (
    <div
      style={{
        display:
          !showMinorProducts && sortedProductInfo.title === 'no_bank'
            ? 'none'
            : 'block'
      }}
      className="ProductItem"
    >
      <div className="bank_info">
        {sortedProductInfo.title !== 'no_bank' && (
          <div className="logo" key={sortedProductInfo.title}>
            <img
              src={
                sortedProductInfo.title === 'Freedom Finance'
                  ? pathFreedomlogo
                  : sortedProductInfo.title === 'Евразийский Банк' ||
                    sortedProductInfo.title === 'eu_bank'
                  ? pathEurasLogo
                  : sortedProductInfo.title === 'KMF' ||
                    sortedProductInfo.title === 'kmf'
                  ? pathKmfLogo
                  : sortedProductInfo.title === 'Halyk Bank' ||
                    sortedProductInfo.title === 'halyk'
                  ? pathHalykLogo
                  : sortedProductInfo.title === 'Банк Центр Кредит' ||
                    sortedProductInfo.title === 'bcc'
                  ? pathBckLogo
                  : sortedProductInfo.title === 'Береке Банк' ||
                    sortedProductInfo.title === 'bereke'
                  ? pathBerekeLogo
                  : sortedProductInfo.title === 'Forte Bank'
                  ? pathForteLogo
                  : sortedProductInfo.title === 'Алтын Банк' ||
                    sortedProductInfo.title === 'altyn'
                  ? pathAltynLogo
                  : ''
              }
              alt={sortedProductInfo.title}
            />
          </div>
        )}
        <p>
          {sortedProductInfo.title === 'Freedom Finance'
            ? 'Freedom Finance'
            : sortedProductInfo.title === 'Евразийский Банк' ||
              sortedProductInfo.title === 'eu_bank'
            ? 'Евразийский Банк'
            : sortedProductInfo.title === 'KMF' ||
              sortedProductInfo.title === 'kmf'
            ? 'KMF'
            : sortedProductInfo.title === 'Halyk Bank' ||
              sortedProductInfo.title === 'halyk'
            ? 'Halyk Bank'
            : sortedProductInfo.title === 'Банк Центр Кредит' ||
              sortedProductInfo.title === 'bcc'
            ? 'Банк Центр Кредит'
            : sortedProductInfo.title === 'Береке Банк' ||
              sortedProductInfo.title === 'bereke'
            ? 'Береке Банк'
            : sortedProductInfo.title === 'Forte Bank'
            ? 'Forte Bank'
            : sortedProductInfo.title === 'Алтын Банк' ||
              sortedProductInfo.title === 'altyn'
            ? 'Алтын Банк'
            : 'Общие продукты'}
        </p>
      </div>
      {sortedProductInfo.addProducts.map((product) => {
        const insPerProd = addProductsInsurances
          ?.filter((ins) => ins.product.uuid === product.id)
          .map((data) => {
            return { value: data.insurance.uuid, label: data.insurance.name };
          });

        return (
          <div className="title" key={product.label}>
            <div className="title_inner_root">
              <Switch
                style={{ marginRight: '16px' }}
                checked={product.checked}
                onChange={() => handleStockBoundChange(product.id)}
                disabled={
                  product.label === 'Каско по BCC' ||
                  product.label === 'Каско по Halyk'
                }
              />
              <p>{product.label}</p>
            </div>
            {(product.label === 'Каско по BCC' ||
              product.label === 'Каско по Halyk') && (
              <p className="presentTitle">В подарок</p>
            )}
            <div>
              {product.label === 'Каско СК "Евразия"' && (
                <Select
                  key={product.label}
                  bordered={false}
                  value={product.loan_period}
                  onChange={(e) => {
                    handleChangeAddProductLoanPeriod({
                      value: e,
                      id: product.id
                    });
                  }}
                  options={periodOption}
                />
              )}

              {insPerProd && insPerProd.length > 0 && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    key={product.label}
                    style={{ marginRight: '40px' }}
                    bordered={false}
                    placeholder="Выберите страхование"
                    // bordered={false}
                    value={product.insurance_uuid}
                    onChange={(e) => {
                      handleChangeProdInsurance({
                        organisationId: e,
                        addProductId: product.id
                      });
                    }}
                    options={insPerProd}
                  />

                  {product.label === 'Каско по Береке' && <p>12 месяцев</p>}
                  {product.label === 'Страхование от НС Береке' && (
                    <p>На весь срок</p>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
