import '@/styles/Clients.scss';
import { useEffect, useState } from 'react';
import Search from 'antd/es/input/Search';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { TClientStoreFormat } from '@/types/store';
import { getClientByIin, setTargetedClient } from '@/store/clients.slice';
import { clientsColumns } from '@/data/clients.data';
import { getFullPageTableHeight } from '@/services';

const Clients = () => {
  const dispatch = useAppDispatch();
  const { clients, isLoading } = useAppSelector((state) => state.clients);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [clientsArray, setClientArray] = useState<TClientStoreFormat[]>([]);
  const [isSearchExecuted, setIsSearchExecuted] = useState<boolean>(false);

  const handleSearch = async () => {
    await dispatch(getClientByIin(searchValue));
    setIsSearchExecuted(true);
  };

  const handleShowClientDetails = (client: TClientStoreFormat) => {
    dispatch(setTargetedClient(client));
    navigate('/clientRequestsDetails');
  };
  useEffect(() => {
    // Проверяем, что клиенты не пустые и содержат валидные данные
    if (clients && clients.length > 0 && clients[0].id) {
      setClientArray(clients);
    } else {
      setClientArray([]); // Если данные некорректные, устанавливаем пустой массив
    }
  }, [clients]);

  return (
    <div className="Clients">
      <div className="content">
        <div className="header">
          <div className="title">
            <h2>Количество: {clientsArray?.length-1 && 0 }</h2>
            <p>Здесь небольшое описание</p>
          </div>
          <Search
            style={{ width: '40%' }}
            size="large"
            placeholder="Поиск по ИИН"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setIsSearchExecuted(false);
            }}
            onSearch={handleSearch}
          />
        </div>

        {/* Сообщение при отсутствии данных после поиска */}
        {isSearchExecuted && clientsArray.length === 0 && !isLoading && (
          <div className="no-results">
            По запросу <strong>{searchValue}</strong> ничего не найдено
          </div>
        )}

        <div className="table">
          <Table
            size="small"
            showSorterTooltip={true}
            columns={clientsColumns}
            dataSource={clientsArray.length > 0 && clientsArray[0].id ? clientsArray : []}
            rowKey={(record) => record?.id || ''}
            pagination={{ position: ['bottomLeft'], showSizeChanger: true }}
            scroll={{
              x: 'max-content',
              y: getFullPageTableHeight()
            }}
            loading={isLoading}
            // locale={{ emptyText: isSearchExecuted ? <div className='empty-text'>Нет данных</div>: 'Загрузите данные' }}
            onRow={(data) => {
              return {
                onClick: () => {
                  handleShowClientDetails(data);
                }
              };
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Clients;
