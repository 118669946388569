import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TClientServerResponse } from '@/types/clients';
import { clientsApi } from '@/integrations/index.api';

type TClientsSliceProps = {
  clients: TClientServerResponse[];
  total: number;
  isLoading: boolean;
};

const initialState: TClientsSliceProps = {
  clients: [],
  total: 0,
  isLoading: false
};

export const getAdminAllClients = createAsyncThunk(
  'admin/clients/getAll',
  async ({
    limit,
    page,
    param
  }: {
    limit?: number;
    page?: number;
    param?: string;
  }) => {
    return await clientsApi.getAdminAllClients(limit, page, param);
  }
);

export const getAdminClientByIin = createAsyncThunk(
  'admin/clients/getByIin',
  async (iin: string) => {
    const res = await clientsApi.getAdminClientByIin(iin);
   
    return res;
  }
);
export const adminClientsSlice = createSlice({
  name: 'admin/clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAllClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminAllClients.fulfilled, (state, action) => {
      
        state.clients = action.payload.clients;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getAdminAllClients.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAdminClientByIin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminClientByIin.fulfilled, (state, action) => {
        state.clients = [action.payload];
        // state.total = action.payload.count;
        state.isLoading = false;
      })
      .addCase(getAdminClientByIin.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {} = adminClientsSlice.actions;
