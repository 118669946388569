import React, { useState } from 'react';
import { BankSubPrintItem } from './BankSubPrintItem';
import '@/styles/BankMainPrintItem.scss';
import { TBankOffers } from '@/types/offers';
import { darkMinusIcon, darkPlusIcon } from '@/constants';
import CustomCheckbox from '@/components/Shared/Checkbox/CustomCheckbox';

export type TBankMainPrintItemProps = {
  printChecked: boolean;
  bankInfo: TBankOffers;
  onChangePrint: (bankId: string) => void;
  onChangeSubPrint: (bankId: string) => void;
};
export const BankMainPrintItem = ({
  printChecked,
  bankInfo,
  onChangePrint,
  onChangeSubPrint
}: TBankMainPrintItemProps) => {
  const [showAlternativePrints, setShowAlternativePrints] =
    useState<boolean>(false);
  const toggleShowAdditional = () =>
    setShowAlternativePrints(!showAlternativePrints);

  return (
    <>
      <div
        className={`BankMainPrintItem ${
          bankInfo.alternative?.length && 'BankMainPrintItem_bank_alternative'
        }`}
      >
        <div className="BankMainPrintItem_bank_info">
          <div className="BankMainPrintItem_wrapper">
            <img src={bankInfo.icon} alt="bank" />
          </div>
          <p>{bankInfo.name}</p>
        </div>
        <div className="action_root">
          {bankInfo.alternative?.length && bankInfo.alternative?.length > 0 && (
            <button onClick={toggleShowAdditional}>
              <img
                src={showAlternativePrints ? darkMinusIcon : darkPlusIcon}
                alt="toggle"
              />
            </button>
          )}

          <CustomCheckbox
            onChange={onChangePrint}
            editClass="print_checkbox"
            checked={printChecked}
          />
        </div>
      </div>
      {showAlternativePrints && bankInfo.alternative?.length && (
        <>
          {bankInfo.alternative.map((alternative) => {
            return (
              <BankSubPrintItem
                onChangePrint={onChangeSubPrint}
                key={alternative.id}
                bank={alternative}
              />
            );
          })}
          <div className="BorderLine_print_item"></div>
        </>
      )}
    </>
  );
};
