import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAllBanks } from '@/store/admin/adminBanks.slice';
import { Button, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { TBank } from '@/types/bank.type';
import { setShowModalCreateOrEditBank } from '@/store/admin/admin.slice';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CreateOrEditBankModal } from '@/components/AdminPanel/Modals/CreateOrEditBankModal';
import '@/styles/AdminModal.scss';

export const Banks: FC = () => {
  const dispatch = useAppDispatch();
  const { showModalCreateOrEditBank } = useAppSelector((state) => state.admin);
  const { banks } = useAppSelector((state) => state.adminBanks);

  const [bankForEdit, setBankForEdit] = useState<TBank | null>(null);

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setBankForEdit(null);
            dispatch(setShowModalCreateOrEditBank(true));
          }}
        >
          Добавить
        </Button>
        {showModalCreateOrEditBank && (
          <CreateOrEditBankModal
            setShow={() => dispatch(setShowModalCreateOrEditBank(false))}
            editMode={!!bankForEdit}
            bankItem={bankForEdit}
          />
        )}
      </div>
      <Table
        rowClassName="admin-banks-table-row"
        rowKey="uuid"
        dataSource={banks}
        onRow={(record: TBank) => ({
          onClick: () => {
            setBankForEdit(record);
            dispatch(setShowModalCreateOrEditBank(true));
          }
        })}
      >
        <Column
          key="fullname"
          title="Полное наименование"
          dataIndex="fullname"
        />
        <Column key="name" title="Наименование" dataIndex="name" />
        <Column key="timeout" title="Timeout" dataIndex="timeout" />
        {/*<Column*/}
        {/*  key="login"*/}
        {/*  title="Логин"*/}
        {/*  dataIndex="login"*/}
        {/*  render={(_, record: TBank) => {*/}
        {/*    return record.login ? record.login : 'No Data';*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Column*/}
        {/*  key="password"*/}
        {/*  title="Пароль"*/}
        {/*  dataIndex="password"*/}
        {/*  render={(_, record: TBank) => {*/}
        {/*    return record.password ? record.password : 'No Data';*/}
        {/*  }}*/}
        {/*/>*/}
        <Column
          key="is_active"
          title="Статус"
          dataIndex="is_active"
          render={(_, record: TBank) => {
            return record.is_active ? (
              <span style={{ color: 'green' }}>Активно</span>
            ) : (
              <span style={{ color: 'red' }}>Не активно</span>
            );
          }}
        />
      </Table>
    </>
  );
};
