import { TClientRequest } from '@/types/clients';
import { TDataSource, TDataSourceResponses } from '@/types/dataSource';
import { validateNumberInputValue } from '@/services';

export const clientsRequestsMapper = (
  requests: TClientRequest[],
  userInfo: {
    iin: string;
    fullName: string;
    phoneNum: string;
  }
): TDataSource[] => {
  const dataSourceArray = requests?.map((request) => {
    const requestCode = request?.code || 'Не указано';
    const date = request?.create_date || '';
    const author = `${request?.user?.first_name || ''} ${
      request?.user?.middle_name || ''
    } ${request?.user?.last_name || ''}`;
    const amount = request?.vehicle_cost || '';
    const auto = `${request?.model?.brand?.name || ''} ${
      request?.model?.name
    }, ${request?.model?.year || new Date().getFullYear()}`;
    const autoDescription = `${request?.model?.brand?.name || ''} ${
      request?.model?.name
    }, ${request?.model?.year || ''} `;
    const autoType = request?.auto_type?.name || '';

    const deniedOffersArray = [...request.Responses].filter(
      (response) =>
        response.status.code === 'declined' ||
        response.status.code === 'cancelled'
    );
    const waitingOffersArray = [...request.Responses].filter(
      (response) =>
        response.status.code === 'waiting_bank_response' ||
        response.status.code === 'on_rework' ||
        response.status.code === 'on_verification'
    );
    const approvedArray = [...request.Responses].filter(
      (response) =>
        response.status.code === 'approved' ||
        response.status.code === 'pre_approved'
    );
    const waitingFinancingArray = [...request.Responses].filter(
      (response) => response.status.code === 'waiting_of_financing'
    );
    const financedOffersArray = [...request.Responses].filter(
      (response) => response.status.code === 'financed'
    );
    const alternativeApprovedArray = [...request.Responses].filter(
      (response) => response.status.code === 'accepted_alternative'
    );

    const errorOffersArray = [...request.Responses].filter(
      (response) => response.status.code === 'error'
    );

    const isFinanced = financedOffersArray.length > 0;
    const isSelected = waitingFinancingArray.length > 0;
    const selectedBank =
      waitingFinancingArray.length > 0
        ? waitingFinancingArray[0]?.bank?.name || ''
        : '';
    const approved = approvedArray.length > 0 ? approvedArray.length : '0';
    const expectations =
      waitingOffersArray.length > 0 ? waitingOffersArray.length : '0';
    const denied =
      deniedOffersArray.length > 0 ? deniedOffersArray.length : '0';
    const isRequestInDraft = request.status.code === 'draft';

    const approvedAlternatives =
      alternativeApprovedArray.length > 0
        ? alternativeApprovedArray.length
        : '0';

    const errors = errorOffersArray.length > 0 ? errorOffersArray.length : '0';

    const isUnverified = request.status.code === 'sms_verificating';

    const responsesAmount =
      financedOffersArray.length +
      errorOffersArray.length +
      waitingFinancingArray.length +
      waitingOffersArray.length +
      deniedOffersArray.length +
      alternativeApprovedArray.length +
      selectedBank.length +
      approvedArray.length;

    const dataSource: TDataSource = {
      id: request.uuid,
      responsesAmount: responsesAmount,
      client: {
        code: requestCode,
        fullName: userInfo.fullName,
        iin: userInfo.iin,
        telNumber: userInfo.phoneNum
      },
      createdUser: `${request?.user.last_name || ''} ${
        request?.user.first_name || ''
      } ${request?.user.middle_name || ''}`,
      dealer: {
        code: request?.dealer?.code,
        uuid: request?.dealer?.uuid,
        name: request?.dealer?.name
      },
      loan: {
        amount: amount,
        auto: auto,
        dealer: request?.dealer?.name || '',
        initialFee:
          validateNumberInputValue(request?.init_fee?.toString(), 10, true) ||
          '',
        loanTerm: request?.loan_period_code
      },
      orderType: {
        auto: autoDescription,
        autoType: autoType,
        code: requestCode
      },
      created_at: {
        author: author,
        date: date,
        author_uuid: request?.user_uuid || ''
      },
      status: {
        isFinanced: isFinanced,
        isSelected: isSelected,
        isUnverified: isUnverified,
        selectedBank: selectedBank,
        approved: approved,
        expectations: expectations,
        denied: denied,
        isDraft: isRequestInDraft,
        errors: errors,
        approvedAlternatives: approvedAlternatives
      } as {
        isUnverified: boolean;
        isFinanced: boolean;
        isSelected: boolean;
        selectedBank: string;
        approved: string;
        expectations: string;
        denied: string;
        isDraft: boolean;
        approvedAlternatives: string;
        errors: string;
      },
      responses:
        request.Responses.length > 0
          ? request.Responses.map((response) => {
              const responseData: TDataSourceResponses = {
                id: response.uuid,
                createDate: response.create_date,
                status: response.status.code,
                bank: response.bank.name,
                responseDate: response.response_date
              };
              return responseData;
            })
          : []
    };
    return dataSource;
  });

  return dataSourceArray;
};
