import { darkInfoCircleLogo } from '@/constants';
import { IAdditionalPND } from '@/types/bccBankResponse';
import { TBankOffers } from '@/types/offers';
import { PRODUCTS } from './BankApprovedItem';
import { getFormattedBankName } from '@/services';
import { formatPrice } from '@/services/formatter';

export type TBankAlternativeItemProps = {
  bank: TBankOffers;
  onSelectOffer: (code: string) => void;
  handleShowSpecialConditions: (text: string) => void;
  additionalPND: IAdditionalPND;
};

export const BankPndItem = ({
  bank,
  onSelectOffer,
  handleShowSpecialConditions,
  additionalPND
}: TBankAlternativeItemProps) => {
  return (
    <>
      <div className="BankItem approved BankItem_alternative">
        <div className="BankItem_header">
          <div className="BankLogo_root">
            <div className="BankItem_logo_wrapper">
              <img src={bank.icon} alt="logo_bank" />
            </div>
            <p className="Bank_title">{getFormattedBankName(bank.name)}</p>
          </div>
          <div className="BanksAlternative_buttons_root">
            <button
              className="BankAccept_show_alternative"
              onClick={() =>
                handleShowSpecialConditions(
                  JSON.stringify(additionalPND.contracts) || ''
                )
              }
            >
              <img src={darkInfoCircleLogo} alt="info" />
              <p>Задолженности</p>
            </button>
            <button
              onClick={() => onSelectOffer(additionalPND.code)}
              className="BankAccept_offer"
            >
              <p>Принять предложение</p>
            </button>
          </div>
        </div>

        <div className="BankInfo_root">
          <div className="BankMain_info">
            <div className="BankMain_info_raw">
              <div className="BankMain_info_block">
                <p>Ежемесячный платеж</p>
                <h4>
                  {formatPrice(additionalPND.monthly_payment) ||
                    bank.monthlyPayment}{' '}
                  ₸
                </h4>
              </div>
              <div className="BankMain_info_block">
                <p>Процентная ставка</p>
                <h4>
                  <span>{additionalPND.interest_rate || bank.rate}%</span>
                </h4>
              </div>
              <div className="BankMain_info_block">
                <p>Срок</p>
                <h4>{additionalPND.term || bank.term} месяца</h4>
              </div>
            </div>
            <div className="BankMain_info_raw">
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Сумма займа</p>
                <h5>
                  {formatPrice(additionalPND.loan_amount) || bank.loanAmount} ₸
                </h5>
              </div>
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Первоначальный взнос</p>
                <h5>
                  {formatPrice(additionalPND.down_payment) || bank.initialFee} ₸
                </h5>
              </div>
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Продукт</p>
                <h5>
                  {PRODUCTS[additionalPND.code as keyof typeof PRODUCTS] ||
                    bank.product}
                </h5>
              </div>
            </div>
          </div>
          <div className="BankAdditional_info">
            <div className="BankAdditional_info_block">
              {(bank.bank === 'halyk' ||
                bank.bank === 'bcc' ||
                bank.bank === 'eu_bank' ||
                bank.bank === 'bereke') && (
                <>
                  <p>
                    КАСКО{' '}
                    {bank.name === 'bcc'
                      ? additionalPND.insurance_amount &&
                        `${additionalPND.insurance_amount} ₸`
                      : bank.bank === 'eu_bank'
                      ? ` ${
                          bank.productInfo?.current_value
                            ? JSON.parse(bank.productInfo?.current_value)
                                .loan_period
                            : bank.productInfo?.product.loan_period
                            ? bank.productInfo?.product.loan_period
                            : ''
                        }`
                      : bank.bank === 'bereke'
                      ? ` ${
                          bank.productInfo?.product.loan_period
                            ? bank.productInfo?.product.loan_period
                            : ''
                        }`
                      : 'в подарок'}
                  </p>
                  <p>
                    {bank.name === 'bereke'
                      ? bank.responseSendedBody &&
                        `НС Страхование ${bank.responseSendedBody} ₸`
                      : ''}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
