import React, { useEffect, useMemo, useState } from 'react';
import { BankAlternativeItem } from './BankAlternativeItem';
import { TBankOffers, TBankOffersAlternative } from '@/types/offers';
import { useAppDispatch, useAppSelector } from '@/store';
import { getFormattedBankName } from '@/services';
import { chevronDownIcon, darkInfoCircleLogo } from '@/constants';
import { Button } from 'antd';
import { TAltynResponse } from '@/types/altynResponse.type';
import { formatPrice } from '@/services/formatter';
import { setOfferUuid } from '@/store/offerStep.slice';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { IAdditionalPND, TBCCBankResponse } from '@/types/bccBankResponse';
import { BankPndItem } from './BankPndItem';

export type TBankApprovedItemsProps = {
  bankOffer: TBankOffers;
  handleShowSpecialConditions: () => void;
  onSelectOffer: (bank: TBankOffersAlternative) => void;
  onCancelBankOffer: (bank: TBankOffersAlternative) => void;
  isHeader?: boolean;
  isAltyn?: boolean;
  altynResponse?: TAltynResponse;
};

export const PRODUCTS = {
  susp: 'Отлагательные условия',
  insurance: 'Страхование'
};

export const BankApprovedItem = ({
  bankOffer,
  onSelectOffer,
  handleShowSpecialConditions,
  onCancelBankOffer,
  isAltyn,
  isHeader,
  altynResponse
}: TBankApprovedItemsProps) => {
  const dispatch = useAppDispatch();
  const { orderApp } = useAppSelector((state) => state.newOrder);

  const [showAlternatives, setShowAlternatives] = useState<boolean>(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState<
    boolean | string
  >(false);
  const [bankProduct, setBankProduct] = useState<string>('');
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const bccBankData = useMemo(() => {
    let bccResponse: TBCCBankResponse | string | undefined =
      bankOffer.bankDescription;
    if (bankOffer.name === 'bcc') {
      try {
        bccResponse = bankOffer.bankDescription
          ? JSON.parse(bankOffer.bankDescription)
          : bankOffer.bankDescription;
      } catch (error) {
        bccResponse = bankOffer.bankDescription;
      }
    }

    const parsedBCC = (bankOffer.name === 'bcc' &&
      typeof bccResponse !== 'string' &&
      bccResponse) as TBCCBankResponse;
    const status = bankOffer.statusType === 'pre_approved';
    const additionalPND =
      parsedBCC?.additionalPND?.filter((item) => item.code !== 'insurance') ||
      [];
    const element = parsedBCC?.additionalPND?.[0];
    const offerCode = element?.code || bankOffer.statusType;
    const contracts = parsedBCC?.additionalPND?.find((item) => item.contracts);

    return {
      additionalPND: status ? additionalPND : [],
      contracts: contracts,
      offerCode
    };
  }, [bankOffer.bankDescription, bankOffer.name, bankOffer.statusType]);

  useEffect(() => {
    const productBankTitle =
      PRODUCTS[bccBankData.offerCode as keyof typeof PRODUCTS] ||
      orderApp?.productInfo.product.stocksBonds?.find(
        (prod) => prod.bank_name === bankOffer.name
      )?.label ||
      'Стандарт';
    setBankProduct(productBankTitle);
  }, []);

  const toggleAlternatives = () => setShowAlternatives(!showAlternatives);

  const handleSelect = (code?: string) => {
    onSelectOffer({
      ...bankOffer,
      code: code || bccBankData.offerCode || bankOffer.name
    } as TBankOffersAlternative);
  };

  return (
    <>
      <CustomModal
        editClass={'description'}
        handleCancel={() => setShowDescriptionModal(false)}
        handleOk={() => setShowDescriptionModal(false)}
        isModalOpen={!!showDescriptionModal}
        title=""
        footer={[]}
      >
        <p style={{ marginTop: '30px', fontSize: '16px', fontWeight: '600' }}>
          {typeof showDescriptionModal === 'string'
            ? showDescriptionModal
            : bankOffer.bankDescription
            ? bankOffer.bankDescription
            : ''}
        </p>
      </CustomModal>
      <CustomModal
        isModalOpen={cancelModal}
        handleOk={() => {
          if (altynResponse && isAltyn) {
            dispatch(setOfferUuid(altynResponse?.offerId));
          }
          onCancelBankOffer(bankOffer as TBankOffersAlternative);
          setCancelModal(false);
        }}
        handleCancel={() => setCancelModal(false)}
        title="Вы уверены что хотите отклонить предложения данного банка?"
        editClass={'cancel'}
        footer={[
          <Button key="back" onClick={() => setCancelModal(false)}>
            Нет
          </Button>,
          <Button
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={() => {
              if (altynResponse && isAltyn) {
                dispatch(setOfferUuid(altynResponse?.offerId));
              }
              onCancelBankOffer(bankOffer as TBankOffersAlternative);
              setCancelModal(false);
            }}
          >
            Да, отменить
          </Button>
        ]}
      />
      <div className="BankItem approved">
        <div className="BankItem_header">
          <div className="BankLogo_root">
            <div className="BankItem_logo_wrapper">
              <img src={bankOffer.icon} alt="logo_bank" />
            </div>
            <div style={{ display: 'flex' }}>
              <p className="Bank_title">
                {getFormattedBankName(bankOffer.name)}
              </p>
              {!bccBankData.contracts && bankOffer.bankDescription && (
                <Button
                  onClick={() => setShowDescriptionModal(true)}
                  style={{
                    borderRadius: '10px',
                    borderColor: 'black',
                    marginLeft: '30px'
                  }}
                >
                  Описание
                </Button>
              )}
            </div>
          </div>
          {bankOffer.extId ? (
            <p className="ext_uuid">
              {isAltyn && altynResponse
                ? altynResponse.offerId
                : bankOffer.extId}
            </p>
          ) : null}
          {bankOffer.statusType ? (
            <p className="status_type">
              {bankOffer.statusType === 'approved'
                ? 'Одобрено'
                : bankOffer.statusType === 'waiting_of_financing'
                ? 'Ожидает финансирования'
                : bankOffer.statusType === 'accepted_alternative'
                ? 'Одобрена альтернатива'
                : bankOffer.statusType === 'pre_approved'
                ? 'Предварительное одобрение'
                : bankOffer.statusType === 'waiting_for_upload_files'
                ? 'Ожидание загрузки файлов'
                : null}
            </p>
          ) : null}
          {bankOffer.statusType === 'financed_pledge_missing' ? (
            <div className="accepted_alternative_content">
              <img src={darkInfoCircleLogo} alt="info" />
              <p>Ожидается загрузка Ареста Авто</p>
            </div>
          ) : bankOffer.statusType ===
            'financed_waiting_for_modification_before_pledge' ? (
            <div className="accepted_alternative_content">
              <img src={darkInfoCircleLogo} alt="info" />
              <p>Доработка по аресту</p>
            </div>
          ) : bankOffer.statusType === 'waiting_for_upload_files' ? (
            <div className="accepted_alternative_content">
              <img src={darkInfoCircleLogo} alt="info" />
              <p>Ожидание загрузки файлов</p>
            </div>
          ) : bankOffer.statusType === 'mobile_client' ? (
            <div className="accepted_alternative_content">
              <img src={darkInfoCircleLogo} alt="info" />
              <p>Действия на мобильном ПО</p>
            </div>
          ) : null}
          {!isHeader &&
          (bankOffer.statusType === 'approved' ||
            bankOffer.statusType === 'accepted_alternative') ? (
            <button
              onClick={() => {
                if (altynResponse && isAltyn) {
                  dispatch(setOfferUuid(altynResponse?.offerId));
                }
                handleSelect();
              }}
              className="BankAccept_offer"
            >
              <p>Принять предложение</p>
            </button>
          ) : bankOffer.statusType === 'pre_approved' ? (
            <button
              onClick={() => {
                handleSelect();
              }}
              className="BankAccept_offer"
            >
              <p>Принять предварительное одобрение</p>
            </button>
          ) : bankOffer.statusType === 'financed' ? (
            <p>Профинансировано</p>
          ) : bankOffer.statusType === 'waiting_of_financing' ? (
            <p>Ожидает финансирования</p>
          ) : null}
          {bankOffer.alternative?.length || bccBankData.additionalPND.length ? (
            <button
              className={`BankAccept_show_alternative ${
                showAlternatives ? 'BankAccept_show_alternative--rotate' : ''
              }`}
              onClick={toggleAlternatives}
            >
              <p>
                Альтернативное решение:{' '}
                {bankOffer.alternative?.length ||
                  bccBankData.additionalPND.length}
              </p>
              <img src={chevronDownIcon} alt="show_more" />
            </button>
          ) : null}
          {!isHeader && (
            <Button
              style={{
                borderRadius: '10px',
                borderColor: 'black'
              }}
              onClick={() => setCancelModal(true)}
            >
              Отклонить
            </Button>
          )}
        </div>

        {bankOffer.statusType === 'accepted_alternative' && (
          <div className="accepted_alternative_content">
            <img src={darkInfoCircleLogo} alt="info" />
            <p>Данное предложение является альтернативой</p>
          </div>
        )}
        <div className="BankInfo_root">
          <div className="BankMain_info">
            <div className="BankMain_info_raw">
              <div className="BankMain_info_block">
                <p>Ежемесячный платеж</p>
                <h4>
                  {isAltyn && altynResponse
                    ? formatPrice(altynResponse.monthlyPayment)
                    : bankOffer.monthlyPayment}{' '}
                  ₸
                </h4>
              </div>
              <div className="BankMain_info_block">
                <p>Процентная ставка</p>
                <h4>
                  <span>
                    {isAltyn && altynResponse
                      ? altynResponse.percentRate
                      : bankOffer.rate}
                    %
                  </span>
                </h4>
              </div>
              <div className="BankMain_info_block">
                <p>Срок</p>
                <h4>
                  {isAltyn && altynResponse
                    ? altynResponse.loanDuration
                    : bankOffer.term}{' '}
                  месяца
                </h4>
              </div>
            </div>
            <div className="BankMain_info_raw">
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Сумма займа</p>
                <h5>
                  {isAltyn && altynResponse
                    ? formatPrice(altynResponse.amount)
                    : bankOffer.loanAmount}{' '}
                  ₸
                </h5>
              </div>
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Первоначальный взнос</p>
                <h5>
                  {isAltyn && altynResponse
                    ? formatPrice(altynResponse.initialPayment)
                    : bankOffer.initialFee}{' '}
                  ₸
                </h5>
              </div>
              <div className="BankMain_info_block">
                <p className="BankInfo_subhead">Продукт</p>
                <h5>{bankProduct}</h5>
              </div>
            </div>
          </div>
          <div className="BankAdditional_info">
            <h6 className="BankAdditional_header">Доп. продукты</h6>
            <div className="BankAdditional_info_block">
              {(bankOffer.bank === 'halyk' ||
                bankOffer.bank === 'bcc' ||
                bankOffer.bank === 'eu_bank' ||
                bankOffer.bank === 'bereke') && (
                <>
                  <p>
                    КАСКО{' '}
                    {bankOffer.name === 'bcc' || bankOffer.name === 'bereke'
                      ? bankOffer.insurance_amount &&
                        `${bankOffer.insurance_amount} ₸`
                      : bankOffer.bank === 'eu_bank'
                      ? ` ${
                          bankOffer.productInfo?.current_value
                            ? JSON.parse(bankOffer.productInfo?.current_value)
                                .loan_period
                            : bankOffer.productInfo?.product.loan_period
                            ? bankOffer.productInfo?.product.loan_period
                            : ''
                        }`
                      : bankOffer.bank === 'bereke'
                      ? ` ${
                          bankOffer.productInfo?.product.loan_period
                            ? bankOffer.productInfo?.product.loan_period
                            : ''
                        }`
                      : 'в подарок'}
                  </p>
                  <p>
                    {bankOffer.name === 'bereke'
                      ? bankOffer.responseSendedBody &&
                        `НС Страхование ${bankOffer.responseSendedBody} ₸`
                      : ''}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        {showAlternatives && (
          <>
            {bccBankData.additionalPND.map((pnd) => {
              return (
                <BankPndItem
                  key={pnd.code}
                  handleShowSpecialConditions={setShowDescriptionModal}
                  onSelectOffer={handleSelect}
                  bank={bankOffer}
                  additionalPND={pnd}
                />
              );
            })}
            {bankOffer.alternative?.map((alternative) => {
              return (
                <BankAlternativeItem
                  key={alternative.id}
                  handleShowSpecialConditions={handleShowSpecialConditions}
                  onSelectOffer={() => {
                    onSelectOffer(alternative);
                  }}
                  alternativeBank={alternative}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};
