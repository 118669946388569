import React from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import '@/styles/OfferStep.scss';
import { darkInfoCircleLogo } from '@/constants';
import { getFormattedBankName } from '@/services/helpers';
import { TBankOffers } from '@/types/offers';

export type TBankWaitingItemProps = {
  bankOffer: TBankOffers;
  updateEblankStatus: (responseExtUuid: string) => void;
  updateForteStatus: (responseExtUuid: string) => void;
  isHeader?: boolean;
};
export const BankWaitingItem = ({
  bankOffer,
  updateEblankStatus,
  updateForteStatus,
  isHeader
}: TBankWaitingItemProps) => {
  return (
    <div
      className={`BankItem ${
        bankOffer.statusType !== 'waiting_of_financing'
          ? 'waiting'
          : 'waiting_bank_financing'
      }`}
    >
      <div className="BankItem_header">
        <div className="BankLogo_root">
          <div className="BankItem_logo_wrapper">
            <img src={bankOffer.icon} alt="logo_bank" />
          </div>
          <p className="Bank_title"> {getFormattedBankName(bankOffer.name)}</p>
        </div>
        {!isHeader && bankOffer.extId ? (
          <p className="ext_uuid">{bankOffer.extId}</p>
        ) : null}

        <div className="Status_wrapper">
          {bankOffer.statusType === 'waiting_bank_response' ? (
            <div className="BankItem_status">
              В ожидании предложений
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 20, color: '#FFD023' }}
                    spin
                  />
                }
                size="small"
                style={{ marginLeft: '15px' }}
              />
            </div>
          ) : null}

          {bankOffer.statusType === 'on_verification' ? (
            <div className="BankItem_status">
              На верификации{' '}
              <Spin
                key={bankOffer.statusType}
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 20, color: '#FFD023' }}
                    spin
                  />
                }
                size="small"
                style={{ marginLeft: '15px' }}
              />
            </div>
          ) : null}
          {bankOffer.statusType === 'on_rework' ? (
            <div className="BankItem_status">
              На доработке{' '}
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 20, color: '#FFD023' }}
                    spin
                  />
                }
                size="small"
                style={{ marginLeft: '15px' }}
              />
            </div>
          ) : null}
          {bankOffer.statusType === 'waiting_of_financing' ? (
            <>
              {/*<button>Отменить предложение</button>*/}
              <div className="BankItem_status">
                Ожидание финансирования{' '}
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 20, color: '#0caf60' }}
                      spin
                    />
                  }
                  size="small"
                  style={{ marginLeft: '15px' }}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      {bankOffer.name === 'eu_bank' && bankOffer.extId !== null && (
        <Button onClick={() => updateEblankStatus(bankOffer?.extId || '')}>
          <ReloadOutlined />
          Обновить статус
        </Button>
      )}
      {bankOffer.name === 'forte' && bankOffer.extId !== null && (
        <Button onClick={() => updateForteStatus(bankOffer?.extId || '')}>
          <ReloadOutlined />
          Обновить статус
        </Button>
      )}
      <div className="BankInfo_root">
        <div className="BankWaiting_content">
          <img src={darkInfoCircleLogo} alt="info" />
          <p
            style={{
              wordBreak: 'break-all',
              maxWidth: '100%',
              textAlign: 'left'
            }}
          >
            {bankOffer.reason}
          </p>
        </div>
      </div>
    </div>
  );
};
