import {
  TPersonAddressInfo,
  TPersonInfo,
  TPersonSpouseInfo
} from '../types/personInfo';
import { TFocusState } from '../types/state';

// export const personalInfoData: TPersonInfo = {
//   dateBirth: null,
//   residentStatus: '',
//   docType: '',
//   docNum: '',
//   docAuth: '',
//   docIssueDate: null,
//   docValidPeriod: null,
//   familyStatus: '',
//   sex: '',
//   placeBirth: '',
//   residenceCountry: '',
//   isLiveRegistred: false,
//   spouseInfo: {
//     spouseIin: '',
//     spouseFullName: '',
//     spousePlaceBirth: '',
//     spouseTelNum: '',
//     spouseDateBirth: null,
//     spouseResidentStatus: '',
//     spouseCompanyName: '',
//     spouseSalary: '',
//     spouseAddSalary: '',
//     isMarried: false
//   },
//   address: {
//     residenceObject: {
//       cityDistrict: '',
//       appartmentNum: '',
//       houseNum: '',
//       region: '',
//       streetName: '',
//       villageSettlement: ''
//     },
//     personAddressObject: {
//       appartmentNum: '',
//       cityDistrict: '',
//       houseNum: '',
//       region: '',
//       streetName: '',
//       villageSettlement: ''
//     },
//     jobAddressObject: {
//       appartmentNum: '',
//       cityDistrict: '',
//       houseNum: '',
//       region: '',
//       streetName: '',
//       villageSettlement: ''
//     }
//   }
// };

export const personSpouseInfoData: TPersonSpouseInfo = {
  isMarried: false,
  spouseIin: '',
  spouseFullName: '',
  spousePlaceBirth: '',
  spouseTelNum: '',
  spouseDateBirth: null,
  spouseResidentStatus: '',
  spouseCompanyName: '',
  spouseSalary: '',
  spouseAddSalary: ''
};

export const personalAddressInfoData: TPersonAddressInfo = {
  residentAddress: '',
  personalAddress: '',
  jobAddress: '',
  regionResidence: '',
  cityDistrictResidence: '',
  villageSettlementResidence: '',
  streetNameResidence: '',
  houseNumResidence: '',
  appartmentNumResidence: '',
  regionPersonal: '',
  cityDistrictPersonal: '',
  villageSettlementPersonal: '',
  streetNamePersonal: '',
  houseNumPersonal: '',
  appartmentNumPersonal: '',
  regionJob: '',
  cityDistrictJob: '',
  villageSettlementJob: '',
  streetNameJob: '',
  houseNumJob: '',
  appartmentNumJob: '',
  isLiveRegistred: false
};

export const isFocusData: TFocusState = {
  dealer: false,
  autoType: false,
  markModelYear: false,
  costCard: false,
  initFee: false,
  loanTerm: false,
  autoColor: false,
  engineType: false,
  paymentScheme: false,
  paymentDate: false,
  proofInCome: false,
  term: false,
  dateBirth: false,
  residentStatus: false,
  docType: false,
  docNum: false,
  docAuth: false,
  docIssueDate: false,
  docValidPeriod: false,
  familyStatus: false,
  sex: false,
  placeBirth: false,
  residentAddress: false,
  personalAddress: false,
  jobAddress: false,
  spouseIin: false,
  spouseFullName: false,
  spousePlaceBirth: false,
  spouseTelNum: false,
  spouseDateBirth: false,
  spouseResidentStatus: false,
  spouseCompanyName: false,
  spouseSalary: false,
  spouseAddSalary: false,
  regionResidence: false,
  cityDistrictResidence: false,
  villageSettlementResidence: false,
  streetNameResidence: false,
  houseNumResidence: false,
  appartmentNumResidence: false,
  regionPersonal: false,
  cityDistrictPersonal: false,
  villageSettlementPersonal: false,
  streetNamePersonal: false,
  houseNumPersonal: false,
  appartmentNumPersonal: false,
  regionJob: false,
  cityDistrictJob: false,
  villageSettlementJob: false,
  streetNameJob: false,
  houseNumJob: false,
  appartmentNumJob: false,
  children: false,
  companyName: false,
  companyType: false,
  jobTitle: false,
  workTelNumber: false,
  salary: false,
  additionalSalary: false,
  socialStatus: false,
  addContactsFullName: false,
  addContactsTelNum: false,
  addContactsIsRelated: false,
  govNum: false,
  vin: false,
  carBodyNum: false,
  initialFeeDate: false,
  regCompany: false,
  engineCapacity: false,
  carColor: false,
  regCertDate: false,
  regCertNum: false,
  residenceCountry: false
};

// export const additionalInfoData: TAdditionalInfo = {
//     totalWorkExperience: "",
//     lastWorkExperience: "",
//     children: "",
//     companyName: "",
//     companyType: "",
//     jobTitle: "",
//     workTelNumber: "",
//     salary: "",
//     additionalSalary: "",
//     socialStatus: "",
//     addContactsFullName: "",
//     addContactsTelNum: "",
//     addContactsIsRelated: "",
//     files: null
// };

export const stepCountItemsData: {
  title: string;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
}[] = [
  {
    title: 'Информация о продукте',
    status: 'process'
  },
  {
    title: 'Личная информация',
    status: 'process'
  },
  {
    title: 'Доп. информация',
    status: 'process'
  }
];
