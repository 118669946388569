import { TPersonalAddressItems } from '../types/personInfo';
import { TSelectItem } from '../types/selectsItem';
import { DictTypes } from '../constants';

export const personalClientInfoItems: TSelectItem[] = [
  {
    key: 'dateBirth',
    label: 'Дата рождения',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'docType',
    snakeCaseValues: DictTypes.DocumentTypes,
    label: 'Тип документа',
    editable: true,
    type: 'select'
  },
  {
    key: 'sex',
    label: 'Пол',
    snakeCaseValues: DictTypes.Genders,
    editable: false,
    type: 'select'
  },
  {
    key: 'familyStatus',
    label: 'Семейное положение',
    snakeCaseValues: DictTypes.MaritalStatuses,
    editable: false,
    type: 'select'
  },
  {
    key: 'children',
    label: 'Кол-во несоверш. детей',
    editable: false,
    type: 'input'
  }
];

export const personalClientJobData: TSelectItem[] = [
  {
    key: 'companyName',
    label: 'Наименование организации',
    editable: false,
    type: 'input'
  },
  {
    key: 'companyType',
    label: 'Вид организации',
    editable: false,
    snakeCaseValues: DictTypes.OrganizationTypes,
    type: 'select'
  },
  {
    key: 'jobTitle',
    label: 'Должность',
    editable: false,
    type: 'input'
  },
  {
    key: 'jobAddress',
    label: 'Рабочий адрес',
    editable: false,
    type: 'input'
  },
  {
    key: 'salary',
    label: 'Заработная плата',
    editable: false,
    type: 'input'
  },
  {
    key: 'additionalSalary',
    label: 'Дополнительный доход',
    editable: false,
    type: 'input'
  },
  {
    key: 'workTelNumber',
    label: 'Рабочий номер телефона',
    editable: false,
    type: 'input'
  },
  {
    key: 'socialStatus',
    label: 'Социальный статус',
    snakeCaseValues: DictTypes.SocialStatuses,
    editable: false,
    type: 'select'
  }
];

// export const personalInfoItems: TSelectItem[] = [
//   {
//     key: 'dateBirth',
//     label: 'Дата рождения',
//     editable: false,
//     type: 'datepicker'
//   },
//   {
//     key: 'residentStatus',
//     snakeCaseValues: DictTypes.ResidentStatuses,
//     label: 'Статус резидента',
//     editable: false,
//     type: 'select'
//   },
//   {
//     key: 'docType',
//     snakeCaseValues: DictTypes.DocumentTypes,
//     label: 'Тип документа',
//     editable: true,
//     type: 'select'
//   },
//   {
//     key: 'docNum',
//     label: 'Номер документа',
//     editable: true,
//     type: 'input'
//   },
//   {
//     key: 'docAuth',
//     label: 'Орган выдачи документа',
//     snakeCaseValues: DictTypes.DocumentIssuringAuthorities,
//     editable: false,
//     type: 'select'
//   },
//   {
//     key: 'docIssueDate',
//     label: 'Дата выдачи документа',
//     editable: false,
//     type: 'datepicker'
//   },
//   {
//     key: 'docValidPeriod',
//     label: 'Срок действия документа',
//     editable: false,
//     type: 'datepicker'
//   },
//   {
//     key: 'familyStatus',
//     label: 'Семейное положение',
//     snakeCaseValues: DictTypes.MaritalStatuses,
//     editable: false,
//     type: 'select'
//   },
//   {
//     key: 'sex',
//     label: 'Пол',
//     snakeCaseValues: DictTypes.Genders,
//     editable: false,
//     type: 'select'
//   },
//   {
//     key: 'placeBirth',
//     label: 'Место рождения',
//     editable: false,
//     type: 'input'
//   },
//   {
//     key: 'isLiveRegistred',
//     label: 'Проживает там же где прописан',
//     editable: true,
//     type: 'switch'
//   },
//   {
//     key: 'residentAddress',
//     label: 'Адрес прописки',
//     editable: true,
//     type: 'input'
//   },
//   {
//     key: 'personalAddress',
//     label: 'Фактический адрес',
//     editable: true,
//     type: 'input'
//   },
//   {
//     key: 'jobAddress',
//     label: 'Рабочий адрес',
//     editable: true,
//     type: 'input'
//   }
// ];

export const personalSpouseItems: TSelectItem[] = [
  {
    key: 'spouseIin',
    label: 'ИИН супруги(а)',
    editable: true,
    type: 'input'
  },
  {
    key: 'spouseFullName',
    label: 'ФИО супруги(а)',
    editable: true,
    type: 'input'
  },
  {
    key: 'spousePlaceBirth',
    label: 'Место рождения',
    editable: true,
    type: 'input'
  },
  {
    key: 'spouseTelNum',
    label: 'Номер телефона',
    editable: true,
    type: 'input'
  },
  {
    key: 'spouseDateBirth',
    label: 'Дата рождения',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'spouseResidentStatus',
    label: 'Статус резидента',
    editable: false,
    type: 'select',
    snakeCaseValues: DictTypes.ResidentStatuses,
    option: [
      { value: 1, label: 'Гражданин РК' },
      { value: 2, label: 'Не гражданин РК' }
    ]
  },
  {
    key: 'spouseCompanyName',
    label: 'Наименование организации',
    editable: true,
    type: 'input'
  },
  {
    key: 'spouseSalary',
    label: 'Заработная плата',
    editable: true,
    type: 'input'
  },
  {
    key: 'spouseAddSalary',
    label: 'Дополнительный доход',
    editable: true,
    type: 'input'
  }
];

export const personalAddressItems: TPersonalAddressItems = {
  residence: [
    {
      key: 'regionResidence',
      subKey: 'region',
      label: 'Область',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'cityDistrictResidence',
      subKey: 'city',
      label: 'Город/Район',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'villageSettlementResidence',
      subKey: 'village',
      label: 'Село/Населенный пункт',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'streetNameResidence',
      subKey: 'streetName',
      label: 'Наименование улицы',
      editable: true,
      type: 'input'
    },
    {
      key: 'houseNumResidence',
      subKey: 'houseNum',
      label: 'Номер дома',
      editable: true,
      type: 'input'
    },
    {
      key: 'appartmentNumResidence',
      subKey: 'appartmentNum',
      label: 'Номер квартиры',
      editable: true,
      type: 'input'
    },
    {
      key: 'isLiveRegistred',
      subKey: 'isLiveReg',
      label: 'Проживает там же где прописан',
      editable: true,
      type: 'switch'
    }
  ],
  personal: [
    {
      key: 'regionPersonal',
      subKey: 'region',
      label: 'Область',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'cityDistrictPersonal',
      subKey: 'city',
      label: 'Город/Район',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'villageSettlementPersonal',
      subKey: 'village',
      label: 'Село/Населенный пункт',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'streetNamePersonal',
      subKey: 'streetNum',
      label: 'Наименование улицы',
      editable: true,
      type: 'input'
    },
    {
      key: 'houseNumPersonal',
      subKey: 'houseNum',
      label: 'Номер дома',
      editable: true,
      type: 'input'
    },
    {
      key: 'appartmentNumPersonal',
      subKey: 'appartmentNum',
      label: 'Номер квартиры',
      editable: true,
      type: 'input'
    }
  ],
  job: [
    {
      key: 'regionJob',
      subKey: 'region',
      label: 'Область',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'cityDistrictJob',
      subKey: 'city',
      label: 'Город/Район',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'villageSettlementJob',
      subKey: 'village',
      label: 'Село/Населенный пункт',
      editable: true,
      type: 'select',
      option: []
    },
    {
      key: 'streetNameJob',
      subKey: 'streetNum',
      label: 'Наименование улицы',
      editable: true,
      type: 'input'
    },
    {
      key: 'houseNumJob',
      subKey: 'houseNum',
      label: 'Номер дома',
      editable: true,
      type: 'input'
    },
    {
      key: 'appartmentNumJob',
      subKey: 'appartmentNum',
      label: 'Номер офиса',
      editable: true,
      type: 'input'
    }
  ]
};
