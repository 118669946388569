import {
  Button,
  Collapse,
  Pagination,
  Select,
  Cascader,
  Tooltip,
  DatePicker,
  Radio
} from 'antd';
import '@/styles/PersonalRequestsInfo.scss';
import { CloseOutlined, DownOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  DictTypes,
  pathBckLogo,
  pathBerekeLogo,
  pathEurasLogo,
  pathForteLogo,
  pathFreedomlogo,
  pathHalykLogo,
  pathKmfLogo
} from '@/constants';
import dayjs, { Dayjs } from 'dayjs';
import { findDictByTitle } from '@/services/helpers';
import { useEffect, useState } from 'react';
import { TDataSource } from '@/types/dataSource';
import { dispatch, useAppSelector } from '@/store';
import { getFilterByPersonal } from '@/store/request.slice';
import { getDealersByUserId } from '@/store/dict.slice';
import { Moment } from 'moment';
import { TModule } from '@/types/user';

type TPersonalRequestsInfoProps = {
  requests: TDataSource[] | null;
  selectedRequests: string;
  onChangeSelectedRequests: (val: string) => void;
  termDicts: {
    code: number;
    name: string;
    uuid: string;
    brand_uuid?: string | undefined;
    model_uuid?: string | undefined;
    is_active?: boolean | undefined;
    promo_body?: string | undefined;
  }[];
  handleRequestInfo: (request: TDataSource) => void;
  bankUuid: string;
  status: string | undefined;
  dealerUuid: string;
  brandUuid: string;
  modelUuid: string;
  param: {
    bank_uuid?: string;
    status?: string;
    dealer_uuid?: string;
    brand_uuid?: string;
    modal_uuid?: string;
  };
  onChangeBankUuid: (val: string) => void;
  onChangeStatusUuid: (val: string) => void;
  onChangeDealerUuid: (val: string) => void;
  onChangeBrandUuid: (val: string) => void;
  onChangeModelUuid: (val: string) => void;
  setParams: (value: any) => void;
  userId: string | undefined;
  onResetBank: () => void;
  onResetStatus: () => void;
  onResetDealer: () => void;
  onResetBrandModel: () => void;
  onResetDateRange: () => void; // Новый обработчик для сброса дат
  onDateRangeChange: (dates: [Dayjs, Dayjs] | null) => void;
  onResetFilters: () => void;
};

const { Panel } = Collapse;

const PersonalRequestsInfo = ({
  setParams,
  termDicts,
  handleRequestInfo,
  selectedRequests,
  onChangeSelectedRequests,
  bankUuid,
  status,
  dealerUuid,
  brandUuid,
  modelUuid,
  onChangeBankUuid,
  onChangeStatusUuid,
  onChangeDealerUuid,
  onChangeBrandUuid,
  onChangeModelUuid,
  param,
  userId,
  onResetBank,
  onResetStatus,
  onResetDealer,
  onResetBrandModel,
  onResetDateRange,
  onDateRangeChange,
  onResetFilters
}: TPersonalRequestsInfoProps) => {
  const { requests } = useAppSelector((state) => state.request);
  const { userInfo } = useAppSelector((state) => state.userInfo);

  const [viewArr, setViewArr] = useState<TDataSource[]>([]);

  const onPageChange = (page: number, pageSize: number) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, requests!.length);
    const currentPageData = requests!.slice(startIndex, endIndex);
    setViewArr(currentPageData);
  };

  const [bank_uuid, setBankUuid] = useState<string>(bankUuid);

  const [dealer_uuid, setDealerUuid] = useState<string>(dealerUuid);

  const [brand_uuid, setBrandUuid] = useState<string>(brandUuid);
  const [model_uuid, setModelUuid] = useState<string>(modelUuid);

  const { dicts } = useAppSelector((state) => state.dict);
  const [bankOptions, setBankOptions] = useState<
    { value: string; label: string | number }[]
  >([]);

  const statusOptions = [
    { value: 'Все заявки', label: 'Все заявки' },
    { value: 'Отказ', label: 'Отказ' },
    { value: 'Профинансировано', label: 'Профинансировано' },
    { value: 'Одобрено', label: 'Одобрено' }
  ];
  const [dealerOptions, setDealerOptions] = useState<
    { value: string; label: string | number }[]
  >([]);

  const [brandOptions, setBrandOptions] = useState<
    { value: string; label: string | number }[]
  >([]);
  const [modelOptions, setModelOptions] = useState<
    { value: string; label: string | number; brand_uuid: string }[]
  >([]);
  const { usersDealers } = useAppSelector((state) => state.dict);

  const [dateRange, setDateRange] = useState<
    [Dayjs | null, Dayjs | null] | null
  >(null);
  const [termDict, setTermDict] = useState<
    {
      code: number;
      name: string;
      uuid: string;
      brand_uuid?: string | undefined;
      model_uuid?: string | undefined;
      is_active?: boolean | undefined;
      promo_body?: string | undefined;
    }[]
  >([]);

  const [filteredRequests, setFilteredRequest] = useState<TDataSource[]>([]);

  useEffect(() => {
    if (!requests) return;
    setViewArr(requests);
  }, [requests]);

  useEffect(() => {
    const termDict = findDictByTitle(DictTypes.LoanTerms, dicts)?.content;
    if (termDict) {
      setTermDict(termDict);
    }

    if (requests?.length) {
      const sortedRequests = [...requests].sort((a, b) => {
        const dateA = new Date(a.created_at.date).getTime();
        const dateB = new Date(b.created_at.date).getTime();
        return dateB - dateA;
      });
      setFilteredRequest(sortedRequests);
    } else {
      setFilteredRequest([]);
    }
  }, [requests]);

  useEffect(() => {
    setDealerOptions(
      usersDealers.map((dealer) => ({
        value: dealer.value,
        label: dealer.label
      }))
    );
  }, [usersDealers]);

  useEffect(() => {
    if (userId) {
      dispatch(getDealersByUserId(userId));
    }
  }, [userId]);

  const mapStatus = (status: string | undefined): string => {
    switch (status) {
      case 'Все заявки':
        return 'undefined';
      case 'Профинансировано':
        return 'financed';
      case 'Отказ':
        return 'declined';
      case 'Одобрено':
        return 'approved';
      default:
        return 'undefined';
    }
  };

  useEffect(() => {
    const mappedStatus = mapStatus(status);
    dispatch(getFilterByPersonal({ ...param, status_code: mappedStatus }));
  }, [param, status, bank_uuid, dealer_uuid, brand_uuid, model_uuid]);

  const handleStatusChange = (value: string) => {
    onChangeStatusUuid(value);
  };
  const handleDealerChange = (value: string) => {
    setDealerUuid(value);
    onChangeDealerUuid(value);
  };
  const handleBankChange = (value: string) => {
    setBankUuid(value);
    onChangeBankUuid(value);
  };

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates === null || dates.some((date) => date === null)) {
      setDateRange(null);
      onDateRangeChange(null);
    } else {
      const [start, end] = dates;
      setDateRange([start as Dayjs, end as Dayjs]);
      onDateRangeChange([start as Dayjs, end as Dayjs]);
    }
  };

  useEffect(() => {
    const banksDict = findDictByTitle(DictTypes.Banks, dicts);
    if (banksDict) {
      setBankOptions(
        banksDict.content
          .filter((bank) => bank.is_active)
          .map((bank) => ({
            value: bank.uuid,
            label: bank.fullname
          }))
      );
    }

    const dealerDict = findDictByTitle(DictTypes.Dealers, dicts);
    if (dealerDict) {
      setDealerOptions(
        dealerDict.content.map((dealer) => ({
          value: dealer.uuid,
          label: dealer.name
        }))
      );
    }

    const brandDict = findDictByTitle(DictTypes.Brands, dicts);
    if (brandDict) {
      setBrandOptions(
        brandDict.content.map((brand) => ({
          value: brand.uuid,
          label: brand.name
        }))
      );
    }

    const modelDict = findDictByTitle(DictTypes.Models, dicts);
    if (modelDict) {
      setModelOptions(
        modelDict.content.map((model) => ({
          value: model.uuid,
          label: model.name,
          brand_uuid: model.brand_uuid as string
        }))
      );
    }
  }, [dicts]);

  useEffect(() => {
    const brandDict = findDictByTitle(DictTypes.Brands, dicts);
    if (brandDict) {
      setBrandOptions(
        brandDict.content.map((brand) => ({
          value: brand.uuid,
          label: brand.name,
          isLeaf: false
        }))
      );
    }
  }, [dicts]);

  useEffect(() => {
    if (brandUuid) {
      const modelDict = findDictByTitle(DictTypes.Models, dicts);
      if (modelDict) {
        const filteredModels = modelDict.content.filter(
          (model) => model.brand_uuid === brandUuid
        );

        setModelOptions(
          filteredModels.map((model) => ({
            value: model.uuid,
            label: model.name,
            brand_uuid: model.brand_uuid
          }))
        );
      }
    }
  }, [brandUuid, dicts]);

  const handleCascaderChange = (value: any) => {
    onChangeBrandUuid(value[0] || '');
    onChangeModelUuid(value[1] || '');
  };

  const cascaderOptions = brandOptions.map((brand) => ({
    ...brand,
    children: modelOptions
      .filter((model) => model.brand_uuid === brand.value)
      .map((model) => ({
        value: model.value,
        label: model.label
      }))
  }));

  const handleResetBank = () => {
    setBankUuid('');
    onChangeBankUuid('');
  };

 const handleResetFilters = () => {
  setBankUuid('');
  setDealerUuid('');
  setBrandUuid('');
  setModelUuid('');
  
  setDateRange(null); // Сброс даты в null

  setParams({
    bank_uuid: '',
    status: '',
    dealer_uuid: '',
    brand_uuid: '',
    model_uuid: '',
    date_from: '',
    date_until: ''
  });

  onResetStatus();
  onResetBank();
  onResetDealer();
  onResetBrandModel();
  handleCascaderChange([]); 
};


  return (
    <div className="PersonalRequestsInfo_root">
      <div className="PersonalRequests_filter">
        <Select
          className="custom-select-placeholder"
          style={{ width: '30%', marginRight: '2%' }}
          size="large"
          placeholder="Выберите банк"
          value={bank_uuid || undefined}
          onChange={handleBankChange}
          options={bankOptions}
        />
        <Select
          className="custom-select-placeholder"
          style={{ width: '30%', marginRight: '2%' }}
          size="large"
          placeholder="Выберите статус"
          value={status || undefined}
          onChange={handleStatusChange}
          options={statusOptions}
        />
        <Select
          className="custom-select-placeholder"
          showSearch
          style={{ width: '30%', marginRight: '2%' }}
          size="large"
          placeholder="Выберите дилера"
          value={dealer_uuid || undefined}
          onChange={(value) => {
            handleDealerChange(value);
          }}
          optionFilterProp="label"
          filterSort={(optionA, optionB) => {
            const result = String(optionA?.label ?? '')
              .toLowerCase()
              .localeCompare(String(optionB?.label ?? '').toLowerCase());
            return result;
          }}
          options={dealerOptions}
        />
        <Cascader
          options={cascaderOptions}
          value={
            brandUuid || (undefined && modelUuid) || undefined
              ? [brandUuid, modelUuid]
              : []
          }
          onChange={(value) => {
            handleCascaderChange(value);
          }}
          changeOnSelect
          placeholder="Выберите бренд и модель"
          style={{ width: '40%', marginRight: '2%' }}
          size="large"
          allowClear={false}
        />
        <DatePicker.RangePicker
          style={{
            width: '50%',
            marginRight: '2%',
            height: '40px',
            marginTop: '1px',
            borderRadius: '8px',
            color: 'black'
          }}
          value={dateRange || null}
          onChange={(dates) => {
            setDateRange(dates);
            handleDateChange(dates);
          }}
          placeholder={['Начальная дата', 'Конечная дата']}
          className="custom-date-picker"
          format="DD.MM.YYYY"
        />
        <Tooltip title="Сбрось фильтры">
          <Button
            type="default"
            onClick={handleResetFilters}
            style={{
              // marginTop: '0px',
              width: '40px',
              height: '40px',
              borderRadius: '8px',
              border: '1px solid #d9d9d9',
              padding: '16px',
              gap: '10px'
            }}
            icon={<ReloadOutlined />}
          ></Button>
        </Tooltip>
      </div>

      <Collapse
        expandIconPosition="start"
        expandIcon={({ isActive }) => (
          <DownOutlined
            style={{
              marginTop: '30px',
              width: '24px',
              height: '24px'
            }}
            rotate={isActive ? 180 : 0}
          />
        )}
      >
       {filteredRequests?.length > 0 ? (
  filteredRequests.map((dataSource) => {
            return (
              <Panel
                key={dataSource.id}
                header={
                  <div className="Collapse_header">
                    <div className="collapse_span">
                      {dataSource.loan?.auto || ''}
                      <span className="separate_dot">&#183;</span>
                      ИИН: {dataSource.client?.iin || ''}
                      <span className="separate_dot">&#183;</span>
                      {dataSource.client?.fullName || ''}
                      <span className="separate_dot">&#183;</span>
                      {dataSource.client?.telNumber || ''}
                      <p>
                        {dataSource.loan?.dealer || ''}
                        <span className="separate_dot">&#183;</span>
                        Создано:{' '}
                        {dayjs(dataSource?.created_at?.date).format(
                          'DD.MM.YYYY'
                        )}
                        <span className="separate_dot">&#183;</span>
                        Дата финансирования:{' '}
                        {dataSource.loan?.financed_date
                          ? dayjs(dataSource.loan.financed_date).format(
                              'DD.MM.YYYY'
                            )
                          : 'N/A'}
                      </p>
                    </div>
                    {dataSource.status?.isFinanced ? (
                      <div
                        style={{
                          padding: '4px 16px',
                          borderRadius: '8px',
                          background: '#0CAF60',
                          width: '90px'
                        }}
                      >
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: '10px',
                            fontWeight: '700',
                            color: 'white'
                          }}
                        >
                          FINANCED
                        </p>
                      </div>
                    ) : dataSource.status?.isSelected ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            marginRight: '10px',
                            borderRadius: '50%',
                            border: '1px solid #E9EAEC',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <img
                            src={
                              dataSource.status?.selectedBank ===
                              'Freedom Finance'
                                ? pathFreedomlogo
                                : dataSource.status?.selectedBank ===
                                  'Евразийский Банк'
                                ? pathEurasLogo
                                : dataSource.status?.selectedBank === 'KMF'
                                ? pathKmfLogo
                                : dataSource.status.selectedBank ===
                                  'Halyk Bank'
                                ? pathHalykLogo
                                : dataSource.status.selectedBank ===
                                    'Банк ЦентрКредит' ||
                                  dataSource.status.selectedBank === 'bcc'
                                ? pathBckLogo
                                : dataSource.status.selectedBank ===
                                  'Береке Банк'
                                ? pathBerekeLogo
                                : dataSource.status.selectedBank ===
                                  'Forte Bank'
                                ? pathForteLogo
                                : ''
                            }
                            style={{ width: '65%', height: '65%' }}
                            alt="logo_bank"
                          />
                        </div>
                        <div>
                          <h2
                            style={{
                              fontSize: '12px',
                              fontWeight: '600',
                              color: '#111827'
                            }}
                          >
                            {dataSource?.status?.selectedBank || ''}
                          </h2>
                          <p
                            style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              color: '#687588'
                            }}
                          >
                            Банк выбран
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={
                            dataSource.status.approved === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: '#0CAF60'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: 'white'
                            }}
                          >
                            {dataSource?.status?.approved || ''}
                          </p>
                        </div>
                        <div
                          style={
                            dataSource.status.expectations === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: '#FFD023'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: '#111827'
                            }}
                          >
                            {dataSource?.status?.expectations || ''}
                          </p>
                        </div>
                        <div
                          style={
                            dataSource.status.denied === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: '#E03137'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: 'white'
                            }}
                          >
                            {dataSource?.status?.denied}
                          </p>
                        </div>

                        <div
                          style={
                            dataSource.status.errors === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: 'rgb(168, 168, 168)'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: 'white'
                            }}
                          >
                            {dataSource?.status?.errors}
                          </p>
                        </div>

                        <div
                          style={
                            !dataSource.status.isDraft
                              ? { display: 'none' }
                              : {
                                  padding: '4px 16px',
                                  borderRadius: '8px',
                                  background: 'rgb(17 24 39)',
                                  width: '90px'
                                }
                          }
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '10px',
                              fontWeight: '700',
                              color: 'white'
                            }}
                          >
                            ЧЕРНОВИК
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                }
              >
                <table>
                  <thead>
                    <tr className="Table_header">
                      <th>ID заявки</th>
                      <th>Дата создания заявки</th>
                      <th>Марка и модель,год выпуска</th>
                      <th>Стоимость авто</th>
                      <th>Перв. взнос</th>
                      <th>Срок займа</th>
                      <th>Дата финансирования</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="Table_body">
                      <td> # {dataSource.client.code} </td>
                      <td>
                        {dayjs(dataSource?.created_at?.date).format(
                          'DD.MM.YYYY'
                        )}
                      </td>
                      <td>{dataSource.loan.auto}</td>
                      <td>{dataSource.loan.amount} ₸</td>
                      <td>{dataSource.loan.initialFee} ₸</td>
                      <td>
                        {termDicts.find(
                          (term) => term.code === dataSource.loan.loanTerm
                        )?.name || ''}{' '}
                        мес.
                      </td>
                      <td>
                        {dataSource.loan.financed_date
                          ? dayjs(dataSource.loan.financed_date).format(
                              'DD.MM.YYYY'
                            )
                          : ''}
                      </td>
                      {/**/}
                    </tr>
                  </tbody>
                </table>
                <Button
                  onClick={() => {
                    handleRequestInfo(dataSource);
                  }}
                >
                  Подробнее
                </Button>
              </Panel>
              );
            })
          ) : (
            <div style={{ textAlign: 'center', margin: '20px 0', fontSize: '16px' }}>
              Заявки не найдены
            </div>
          )}
      </Collapse>
      <Pagination
        showSizeChanger
        defaultCurrent={1}
        total={viewArr.length} 
        onChange={onPageChange} 
        style={{ marginTop: '20px', textAlign: 'center' }}
      />
    </div>
  );
};

export default PersonalRequestsInfo;
