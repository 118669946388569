import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAllPositions } from '@/store/admin/adminUsers.slice';
import { TPosition } from '@/types/position.type';
import { Button, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import SearchInput from '@/components/Shared/Input/SearchInput';
import { getAllDealers } from '@/store/admin/adminDealers.slice';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CreateOrEditPositionModal } from '@/components/AdminPanel/Modals/CreateOrEditPositionModal';
import { setShowPositionModal } from '@/store/admin/admin.slice';

export const PositionsTable: FC = () => {
  const dispatch = useAppDispatch();
  const { positions } = useAppSelector((state) => state.adminUsers);
  const { showPositionModal } = useAppSelector((state) => state.admin);
  const [positionsData, setPositionsData] = useState<TPosition[]>([]);
  const [positionForEdit, setPositionForEdit] = useState<TPosition | null>(
    null
  );
  useEffect(() => {
    dispatch(getAllPositions());
  }, []);
  useEffect(() => {
    if (positions) {
      setPositionsData(positions);
    }
  }, [positions]);
  return (
    <>
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <SearchInput
          style={{ width: '50%', marginRight: '10px' }}
          onSearch={(value) => {
            dispatch(
              getAllDealers({
                param: value,
                limit: 10,
                page: 1
              })
            );
          }}
        />
        {showPositionModal && (
          <CreateOrEditPositionModal
            positionItem={positionForEdit}
            editMode={!!positionForEdit}
          />
        )}

        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setPositionForEdit(null);
            dispatch(setShowPositionModal(true));
          }}
        >
          Добавить
        </Button>
      </div>

      <Table
        rowClassName="admin-dealers-table-row"
        rowKey="uuid"
        dataSource={positionsData}
        onRow={(record: TPosition) => ({
          onClick: () => {
            setPositionForEdit(record);
            dispatch(setShowPositionModal(true));
          }
        })}
      >
        <Column key="name" dataIndex="name" title="Наименование" />
        <Column key="code" dataIndex="code" title="Код" />
        <Column
          key="is_active"
          dataIndex="is_active"
          title="Статус"
          render={(_, record: TPosition) => {
            return record.is_active ? (
              <p style={{ color: 'green' }}>Активно</p>
            ) : (
              <p style={{ color: 'red' }}>Не активно</p>
            );
          }}
        />
        <Column
          key="description"
          dataIndex="description"
          title="Описание"
          render={(_, record: TPosition) => {
            return record.description ? record.description : '';
          }}
        />
      </Table>
    </>
  );
};
