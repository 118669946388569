import React, { useEffect } from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { TBankOffersAlternative } from '@/types/offers';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';

export type TAcceptModalProps = {
  selectedOffer: TBankOffersAlternative;
  showAcceptModal: boolean;
  onSelectBankOffer: () => void;
  onCancel: () => void;
  isAcceptLoading: boolean;
};

export const AcceptModal = ({
  showAcceptModal,
  onCancel,
  selectedOffer,
  onSelectBankOffer,
  isAcceptLoading
}: TAcceptModalProps) => {
  return (
    <CustomModal
      isModalOpen={showAcceptModal}
      title="Предложение выбрано"
      handleCancel={onCancel}
      handleOk={onSelectBankOffer}
      editClass="accept_offer_modal"
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          disabled={isAcceptLoading}
          className="confirm_offer"
          key="submit"
          type="primary"
          onClick={onSelectBankOffer}
        >
          {!isAcceptLoading ? (
            'Подтвердить'
          ) : (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
        </Button>
      ]}
    >
      <div>
        <p className="accept_default_text">
          Нажимая “Подтвердить” клиент соглашается с условиями Банка
        </p>
        <div className="bank_header_info">
          <div className="logo_wrapper">
            <img
              style={{ width: '40px', height: '40px' }}
              src={selectedOffer?.icon || ''}
              alt="logo"
            />
          </div>
          <div>
            <h6>Выбран банк</h6>
            <h5>{selectedOffer?.bank || ''}</h5>
          </div>
        </div>
        <div className="main_info_root">
          <div>
            <p className="accept_default_data">Ежемесячный платеж</p>
            <p className="monthly_payment_data">
              {selectedOffer?.monthlyPayment || ''} ₸
            </p>
          </div>
          <div>
            <p className="accept_default_data">Ставка</p>
            <p className="monthly_payment_data">
              <span className="accept_data_precent">
                {selectedOffer?.rate || ''} %
              </span>
            </p>
          </div>
        </div>
        <div className="sub_info_root">
          <div>
            <p>Сумма займа</p>
            <p>
              <span>{selectedOffer?.loanAmount || ''} ₸</span>
            </p>
          </div>
          <div>
            <p>Первоначальный взнос</p>
            <p>
              <span>{selectedOffer?.initialFee || ''} ₸</span>
            </p>
          </div>
          <div>
            <p>Срок</p>
            <p>
              <span>{selectedOffer?.term || ''} месяца</span>
            </p>
          </div>
          <div>
            <p>Продукт</p>
            <p>
              <span>{selectedOffer?.product || ''}</span>
            </p>
          </div>
          {selectedOffer && selectedOffer.insurance_amount ? (
            <div>
              <p>Дополнительные услуги</p>
              <p>
                <span>
                  КАСКО{' '}
                  {selectedOffer && selectedOffer.insurance_amount
                    ? selectedOffer.insurance_amount
                    : '5000'}{' '}
                  ₸
                </span>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </CustomModal>
  );
};
