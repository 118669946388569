import React from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { TPersonSpouseInfo } from '@/types/personInfo';
import { TFocusState } from '@/types/state';
import { TDict } from '@/types/store';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { personalSpouseItems } from '@/data/personalInfo.data';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { setDictionaryOptions, validateNumberInputValue } from '@/services';
import { CustomDatePicker } from '@/components/Shared/DatePicker/CustomDatePicker';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';

export type TFamilyStatusModalProps = {
  showFamilyStatusModal: boolean;
  modalFamilyStatusHandler: () => void;
  onCancel: () => void;
  isFocused: TFocusState;
  personalSpouseInfo: TPersonSpouseInfo;
  onChangeInputHandler: (val: string, label: string, type: string) => void;
  onChangeSelectHandler: (val: string, label: string, type: string) => void;
  onChangeDatePickerHandler: (val: any, label: string, type: string) => void;
  validSpouseInfo: boolean;
  dicts: TDict[];
};

export const FamilyStatusModal = ({
  showFamilyStatusModal,
  modalFamilyStatusHandler,
  onCancel,
  isFocused,
  personalSpouseInfo,
  onChangeInputHandler,
  onChangeSelectHandler,
  onChangeDatePickerHandler,
  validSpouseInfo,
  dicts
}: TFamilyStatusModalProps) => {
  return (
    <CustomModal
      editClass="familyStatus"
      title="Данные супруга(и)"
      isModalOpen={showFamilyStatusModal}
      handleOk={modalFamilyStatusHandler}
      handleCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          className="familyStatus_btn"
          key="submit"
          type="primary"
          onClick={modalFamilyStatusHandler}
          disabled={validSpouseInfo}
        >
          Добавить
        </Button>
      ]}
    >
      <div className="spouse_data">
        {personalSpouseItems.map((item) => {
          if (item.type === 'input') {
            if (item.key === 'spouseTelNum') {
              return (
                <CustomPhoneInput
                  key={item.key}
                  value={personalSpouseInfo.spouseTelNum}
                  label={item.key}
                  type={'secondStepSpouse'}
                  onChangeInputHandler={onChangeInputHandler}
                />
              );
            } else {
              return (
                <CustomInput
                  key={item.key}
                  isFocused={isFocused[item.key]}
                  value={
                    item.key === 'spouseSalary' ||
                    item.key === 'spouseAddSalary'
                      ? validateNumberInputValue(
                          personalSpouseInfo[item.key] as string,
                          9,
                          true
                        )
                      : personalSpouseInfo[item.key]
                  }
                  label={item.key}
                  type={'secondStepSpouse'}
                  labelTitle={item.label}
                  onChangeInputHandler={onChangeInputHandler}
                />
              );
            }
          } else if (item.type === 'datepicker') {
            let firstSixLetters = personalSpouseInfo.spouseIin.slice(0, 6);
            const year = firstSixLetters.slice(0, 2);
            const month = firstSixLetters.slice(2, 4);
            const day = firstSixLetters.slice(4, 6);
            const parsedDate =
              parseInt(year) > 24
                ? dayjs(`19${year}-${month}-${day}`)
                : dayjs(`20${year}-${month}-${day}`);
            return (
              <CustomDatePicker
                key={item.key}
                isFocused={isFocused[item.key]}
                value={
                  personalSpouseInfo[item.key]
                    ? dayjs(personalSpouseInfo[item.key] as Date)
                    : item.key === 'spouseDateBirth'
                    ? parsedDate
                    : null
                }
                label={item.key}
                type={'secondStepSpouse'}
                labelTitle={item.label}
                onChangeDatePickerHandler={onChangeDatePickerHandler}
                keyType='autocred'
              />
            );
          } else if (item.type === 'select') {
            return (
              <CustomSelect
                key={item.key}
                isFocused={isFocused[item.key]}
                value={personalSpouseInfo[item.key]?.toString()}
                option={
                  !item.snakeCaseValues
                    ? item.option
                    : setDictionaryOptions(item.snakeCaseValues, dicts)
                }
                label={item.key}
                type={'secondStepSpouse'}
                labelTitle={item.label}
                onChangeSelectHandler={onChangeSelectHandler}
                keyType='autocred'
              />
            );
          }
        })}
      </div>
    </CustomModal>
  );
};
