import { TNewInsuranceServer } from '@/types/insuranseServer';
import { TNewInsuranceResponse } from '@/types/insuranceMapper';
import { TStatus } from '@/types/request';

export const insuranceMapperResp = (
  insurances: TNewInsuranceServer[]
): TNewInsuranceResponse[] => {
  return insurances.map((insurance) => {
    return {
      uuid: insurance?.uuid || '',
      clientIin: insurance?.client?.client_iin || '',
      clientPhone: insurance?.client?.phone_number || '',
      lastName: insurance?.client?.last_name || '',
      middleName: insurance?.client?.middle_name || '',
      firstName: insurance?.client?.first_name || '',
      contrancDate: insurance?.contract_date || '',
      dealer: insurance?.dealer?.name || '',
      brand: insurance?.brand?.name || '',
      model: insurance?.model?.name || '',
      year: insurance?.model?.year || '',
      createDate: insurance?.create_date || '',
      status: insurance?.status as TStatus,
      user: {
        first_name: insurance?.user?.first_name || '',
        last_name: insurance?.user?.last_name || '',
        middle_name: insurance?.user?.middle_name || '',
      },
      telNumIin: insurance.telNumIin || '',
      userSearchType: insurance.userSearchType || ''
    };
  });
};
