import { VerifyModal } from './VerifyModal/VerifyModal';
import { useState } from 'react';
import { useAppDispatch } from '@/store';
import { tokenValidate } from '@/store/auth.slice';
import {
  setVerifyModalIsNewApplication,
  setVerifyModalShowType
} from '@/store/app.slice';
import { pathCalcIcon } from '@/constants';

export const AutoCredHeader = () => {
  const dispatch = useAppDispatch();

  const [verifyModalTitle, setVerifyModalTitle] = useState<string>('');

  // useEffect(() => {
  //   if (Object.values(code).every((val) => val)) {
  //     const isCodeValid = checkObjectValues(code);
  //     if (isCodeValid) {
  //       isNewAppCreation
  //         ? dispatch(
  //             validateOTP({
  //               otp:
  //                 code.firstSquare +
  //                 code.secondSquare +
  //                 code.thirdSquare +
  //                 code.fourthSquare,
  //               uuid: requestId,
  //               dicts: dicts || []
  //             })
  //           )
  //         : dispatch(
  //             validateCalcOTP({
  //               otp:
  //                 code.firstSquare +
  //                 code.secondSquare +
  //                 code.thirdSquare +
  //                 code.fourthSquare,
  //               uuid: clientCalcId
  //             })
  //           );
  //     }
  //   }
  // }, [code]);

  // const onOkHandler = async () => {
  //   setOpenModal((prevState) => ({ ...prevState, prevClient: false }));
  //   setOpenModal((prevState) => ({ ...prevState, smsVerf: true }));
  //   const obj = {
  //     iin: inputValues.iin,
  //     fullName: inputValues.fullName
  //       .split(' ')
  //       .filter((data) => data.trim() !== ''),
  //     telNum: inputValues.telNum,
  //     id: generateRandomSixDiginCode()
  //   };
  //   const createRequestDraftDto = {
  //     iin: inputValues.iin,
  //     lastName: inputValues.fullName.split(' ')[0] || ' ',
  //     firstName: inputValues.fullName.split(' ')[1] || ' ',
  //     middleName: inputValues.fullName.split(' ')[2] || ' ',
  //     clientPhone: cleanPhoneNumber(inputValues.telNum),
  //     dealer_uuid: dealer?.value || ''
  //   };
  //   dispatch(setClientInfo(obj));
  //
  //   isNewAppCreation
  //     ? await dispatch(
  //         requestCreateUnverifiedDraft({
  //           draftDto: createRequestDraftDto,
  //           dicts: dicts || []
  //         })
  //       )
  //     : await dispatch(clientCreateCalc(createRequestDraftDto));
  // };

  // const onCancelHandler = async () => {
  //   setInputValues({ iin: '', fullName: '', telNum: '' });
  //   setOpenModal({ prevClient: false, smsVerf: false });
  //   setCode({
  //     firstSquare: '',
  //     secondSquare: '',
  //     thirdSquare: '',
  //     fourthSquare: ''
  //   });
  //   setStep(1);
  // };

  return (
    <>
      <VerifyModal verifyModalTitle={verifyModalTitle} />
      <div className="header">
        <div className="title">
          <h2>Заявления</h2>
          <p>Созданные заявки</p>
        </div>
        <div className="pagination">
          <button
            onClick={() => {
              // setIsNewAppCreation(false);
              setVerifyModalTitle('Калькуляторный расчет');
              dispatch(tokenValidate());
              dispatch(
                setVerifyModalShowType({ prevClient: true, smsVerf: false })
              );
              dispatch(setVerifyModalIsNewApplication(false));
            }}
          >
            <img src={pathCalcIcon} alt="calculator_icon" />
            <p>Калькулятор</p>
          </button>
          <button
            onClick={() => {
              dispatch(setVerifyModalIsNewApplication(true));
              dispatch(tokenValidate());
              setVerifyModalTitle('Создание заявки');
              dispatch(
                setVerifyModalShowType({ prevClient: true, smsVerf: false })
              );
            }}
          >
            <p>Новая заявка</p>
          </button>
        </div>
      </div>
    </>
  );
};
