import { Button, Collapse, Segmented } from 'antd';
import React from 'react';
import CalculationAnuetetResltItem from './CalculationAnuetetResltItem';
import CalculationDifferenceResultItem from './CalculationDifferenceResultItem';
import { CALCULATION_TYPES, pathCalculatorPlaceholder } from '@/constants';

export type TCalculationResultProps = {
  calculationData: {
    rate: number;
    kaskoPerYear: number;
    loanAmount: number;
    totalLoanAmount: number;
    monthlyPaymentAnnuity: number;
    debtBalanceAnnuity: number;
    loanOverpaymentAnnuity: number;
    monthlyPaymentDiffArray: { month: number; amount: number }[];
    loanOverpaymentDiff: number;
  } | null;
  createNewRequest: () => void;
  calcVariants: CALCULATION_TYPES[];
  calcType: CALCULATION_TYPES;
  setCalcType: React.Dispatch<React.SetStateAction<CALCULATION_TYPES>>;
};

const { Panel } = Collapse;

const CalculationResultsItem = ({
  calculationData,
  createNewRequest,
  setCalcType,
  calcType,
  calcVariants
}: TCalculationResultProps) => {
  return (
    <div>
      <h2>Калькулятор</h2>
      {!calculationData ? (
        <>
          <p>
            Заполните все параметры, чтобы получить предварительные решения от
            банков
          </p>

          <img src={pathCalculatorPlaceholder} alt="results" />
        </>
      ) : (
        <>
          <Segmented
            key="calcType"
            value={calcType}
            block
            onChange={(value) => setCalcType(value as CALCULATION_TYPES)}
            options={calcVariants}
          />
          {calcType === CALCULATION_TYPES.STANDART_ANUETET && (
            <CalculationAnuetetResltItem
              calculationData={calculationData}
              key="anuetet"
            />
          )}
          {calcType === CALCULATION_TYPES.STANDART_DIFERENCE && (
            <CalculationDifferenceResultItem
              key="difference"
              calculationData={calculationData}
            />
          )}
          <Button
            style={{
              borderRadius: ' 10px',
              background: '#FF2E1F',
              color: '#FFF',
              border: 'none'
            }}
            onClick={createNewRequest}
          >
            Создать заявку
          </Button>
        </>
      )}
    </div>
  );
};

export default CalculationResultsItem;
