import React, { useEffect, useRef, useState } from 'react';
import { Button, Spin, Steps } from 'antd';
import { FirstStep } from './OrderStepItem/FirtsStep';
import { SecondStep } from './OrderStepItem/SecondStep';
import { ThirdStep } from './OrderStepItem/ThirdStep';
import { AdditionalProducts } from './Additional/AddItionalProducts';
import { PersonSpouse } from './Additional/PersonSpouse';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd/lib';
import { ProductsForm } from './ProductsForm/ProductsForm';
import '@/styles/OrderStep.scss';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { TClientDataState, TStockBondsState } from '@/types/state';
import { useAppDispatch, useAppSelector } from '@/store';
import { TNewOrder, TNewOrderDto } from '@/types/newOrder';
import { TAddProducts } from '@/types/productItem';
import {
  personSpouseInfoData,
  stepCountItemsData
} from '@/data/orderStep.data';
import { TUploadFiles } from '@/types/uploadFiles';
import { uploadFilesData } from '@/data/uploadFiles.data';
import { TPersonInfo, TPersonSpouseInfo } from '@/types/personInfo';
import { TProductInfo } from '@/types/productInfo';
import { TAdditionalInfo } from '@/types/additionalInfo';
import {
  createNewOrder,
  resetIsError,
  setLoanData,
  setPageTitle,
  setRequestData
} from '@/store/newOrder.slice';
import { countFields, findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import {
  cleanPhoneNumber,
  formatPrice,
  parsePrice
} from '@/services/formatter';
import { getPromosByModel, setProductDictsToInitial } from '@/store/dict.slice';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';

export type TOrderStepProps = {
  createRequest: () => void;
  clientInfo: TClientDataState;
};

export const OrderStep = ({ createRequest, clientInfo }: TOrderStepProps) => {
  const {
    productInfo: storeProductInfo,
    personalInfo: storePersonalInfo,
    additionalInfo: storeAdditionalInfo,
    otpCode,
    requestId
  } = useAppSelector((state) => state.orderStep);
  const { dicts, stockBoundsProductsDict, additionalProductsDict } =
    useAppSelector((state) => state.dict);
  const { orderApp, isCreateLoading, isError } = useAppSelector(
    (state) => state.newOrder
  );

  const dispatch = useAppDispatch();
  const enterButtonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [orderAppWatcher, setOrderAppWatcher] = useState<TNewOrder | null>(
    orderApp
  );

  const [current, setCurrent] = useState<number>(0);
  const [progress, setProgress] = useState<string>('step_start');
  const [open, setOpen] = useState<string>('init');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showFamilyStatusModal, setShowFamilyStatusModal] =
    useState<boolean>(false);
  const [additionalProducts, setAdditionalProducts] = useState<
    TAddProducts[] | []
  >([]);
  const [stepCount, setStepCount] = useState<number>(1);
  const [stepCountItems, setStepCountItems] =
    useState<{ title: string }[]>(stepCountItemsData);
  const [uploadFiles, setUploadFiles] = useState<TUploadFiles>(uploadFilesData);
  const [personSpouseInfo, setPersonSpouseInfo] =
    useState<TPersonSpouseInfo>(personSpouseInfoData);
  const [continueDisabled, setContinueDisabled] = useState<boolean>(true);
  const [additionalChecked, setAdditionalChecked] = useState<boolean>(false);
  const [productInfo, setProductInfo] =
    useState<TProductInfo>(storeProductInfo);
  const [personalInfo, setPersonalInfo] =
    useState<TPersonInfo>(storePersonalInfo);
  const [additionalInfo, setAdditionalInfo] =
    useState<TAdditionalInfo>(storeAdditionalInfo);
  const [stocksBonds, setStocksBonds] = useState<TStockBondsState>([]);
  const [validStep, setValidStep] = useState<{
    first: boolean;
    second: boolean;
    last: boolean;
  }>({ first: false, second: false, last: false });
  const [fieldsReadOnly, setFieldsReadOnly] = useState<boolean>(false);
  const [initFeeModal, setInitFeeModal] = useState<boolean>(false);
  const [recommendedInitFee, setRecommendedInitFee] = useState<
    number | undefined
  >();

  const enterPressedAction = () => {
    if (!fieldsReadOnly) {
      if (current === 0 && validStep.first) {
        onContinueHandler();
      } else if (current === 1 && validStep.second) {
        onContinueHandler();
      } else if (current === 2 && continueDisabled) {
        onContinueHandler();
      }
    }
  };

  const handleChangeInsuranceOrganisation = (data: {
    organisationId: string;
    addProductId: string;
  }) => {
    const addProductsHandler = [...additionalProducts];
    const productIndex = addProductsHandler.findIndex(
      (addProduct) => addProduct.id === data.addProductId
    );
    addProductsHandler[productIndex].insurance_uuid = data.organisationId;
    setAdditionalProducts(addProductsHandler);
  };

  const handleStockBoundChange = (id: string) => {
    const stockArrayHandler = [...stocksBonds];
    const stockIndex = stocksBonds.findIndex((stock) => stock.id === id);
    stockArrayHandler.forEach((stock, index) => {
      if (
        index !== stockIndex &&
        stock.bank_name === stockArrayHandler[stockIndex].bank_name
      ) {
        stock.checked = false;
      }
    });

    stockArrayHandler[stockIndex].checked =
      !stockArrayHandler[stockIndex].checked;
    setStocksBonds(stockArrayHandler);
  };

  const handleAddProductsChange = (id: string) => {
    const addProductsHandler = [...additionalProducts];
    const addIndex = additionalProducts.findIndex((prod) => prod.id === id);

    addProductsHandler[addIndex].checked =
      !addProductsHandler[addIndex].checked;
    setAdditionalProducts(addProductsHandler);
  };

  const handleChangeAddProductLoanPeriodChange = (data: {
    value: string;
    id: string;
  }) => {
    const addProductHandler = [...additionalProducts];
    const productToChangeIndex = addProductHandler.findIndex(
      (prod) => prod.id === data.id
    );
    addProductHandler[productToChangeIndex].loan_period = data.value;
    setAdditionalProducts(addProductHandler);
  };

  useEffect(() => {
    const clickHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') enterButtonRef?.current?.click();
    };

    window.addEventListener('keydown', clickHandler);

    return () => {
      window.removeEventListener('keydown', clickHandler);
    };
  }, []);

  useEffect(() => {
    dispatch(setPageTitle('Новая заявка'));
    if (orderAppWatcher) {
      setProductInfo(orderAppWatcher.productInfo.product);
      setAdditionalInfo((prevState) => ({
        ...prevState,
        files:
          orderAppWatcher?.status !== 'draft'
            ? orderAppWatcher.additionalInfo.files
            : null
      }));
      setPersonalInfo({
        socialStatus: orderAppWatcher.personalInfo.socialStatus,
        jobAddress: orderAppWatcher.personalInfo.jobAddress,
        addContactsIsRelated: orderAppWatcher.personalInfo.addContactsIsRelated,
        addContactsTelNum: orderAppWatcher.personalInfo.addContactsTelNum,
        addContactsFullName: orderAppWatcher.personalInfo.addContactsFullName,
        additionalSalary: orderAppWatcher.personalInfo.additionalSalary,
        sex: orderAppWatcher.personalInfo.sex,
        dateBirth: orderAppWatcher.personalInfo.dateBirth,
        companyType: orderAppWatcher.personalInfo.companyType,
        companyName: orderAppWatcher.personalInfo.companyName,
        jobTitle: orderAppWatcher.personalInfo.jobTitle,
        docType: orderAppWatcher.personalInfo.docType,
        familyStatus: orderAppWatcher.personalInfo.familyStatus,
        workTelNumber: orderAppWatcher.personalInfo.workTelNumber,
        children: orderAppWatcher.personalInfo.children,
        salary: orderAppWatcher.personalInfo.salary
      });
      setUploadFiles(orderAppWatcher.additionalInfo.files as TUploadFiles);
      if (orderAppWatcher.personalInfo.spouseInfo) {
        setPersonSpouseInfo(
          orderAppWatcher.personalInfo.spouseInfo as TPersonSpouseInfo
        );
      }
      if (orderAppWatcher.status !== 'draft') {
        setFieldsReadOnly(true);
      } else {
        const localDealerCode = localStorage.getItem('dealer');

        if (localDealerCode) {
          setProductInfo((prevState) => ({
            ...prevState,
            dealer: localDealerCode
          }));
        }
      }
      if (
        orderAppWatcher.productInfo.product.stocksBonds &&
        orderAppWatcher.productInfo.product.stocksBonds?.length > 0 &&
        orderAppWatcher.status !== 'draft'
      ) {
        const arrayHandler = stockBoundsProductsDict?.map((elem) => {
          const isChecked =
            orderAppWatcher.productInfo.product.stocksBonds?.some(
              (value) => value.code === elem.code
            ) as boolean;
          const res = {
            id: elem.uuid,
            value: elem.bank,
            label: elem.name,
            checked: isChecked,
            info: elem.description,
            code: elem.code,
            grace: elem.grace,
            bank_name: elem.bank_name,
            loan_period: elem.loan_period,
            loan_amount: elem.loan_amount,
            partner_code: elem.partner_code,
            product_code: elem.product_code
          };
          return res;
        });
        arrayHandler && setStocksBonds(arrayHandler);
      } else {
        if (stockBoundsProductsDict) {
          const arrayHandler = stockBoundsProductsDict.map((elem) => {
            const res = {
              id: elem.uuid,
              value: elem.bank,
              label: elem.name,
              checked: elem.enabled_by_default,
              info: elem.description,
              code: elem.code,
              grace: elem.grace,
              bank_name: elem.bank_name,
              loan_period: elem.loan_period,
              loan_amount: elem.loan_amount,
              partner_code: elem.partner_code,
              product_code: elem.product_code
            };
            return res;
          });
          setStocksBonds(arrayHandler);
        }
      }
      if (additionalProductsDict) {
        const arrayHandler = additionalProductsDict.map((elem) => {
          //Базовые значения

          return {
            id: elem.uuid,
            value: elem.bank,
            label: elem.name,
            checked: elem.name !== 'СМС-информирование',
            info: elem.description,
            code: elem.code,
            grace: elem.grace,
            bank_name: elem.bank_name,
            selected_insurance_uuid: null,
            insurance_uuid: null,
            loan_period: elem?.loan_period?.toString() || '12',
            loan_amount: elem.loan_amount,
            partner_code: elem.partner_code,
            product_code: elem.product_code
          };
        });
        setAdditionalProducts(arrayHandler);
      }
    }
  }, [orderAppWatcher]);

  useEffect(() => {
    setOrderAppWatcher(orderApp);
  }, [orderApp]);

  useEffect(() => {
    const residenceStatuses = findDictByTitle(
      DictTypes.ResidentStatuses,
      dicts
    );
  }, [personalInfo]);

  useEffect(() => {
    const residenceStatuses = findDictByTitle(
      DictTypes.ResidentStatuses,
      dicts
    );
    // const isKzResident: boolean =
    //   residenceStatuses?.content.find(
    //     (status) => status.code === Number(personalInfo.residentStatus)
    //   )?.name === 'Гражданин РК';
    const isOrganisationIp =
      findDictByTitle(DictTypes.OrganizationTypes, dicts)?.content?.find(
        (socialStatus) => socialStatus.code === Number(personalInfo.companyType)
      )?.name === 'ИП';
    const isServicemanClient =
      findDictByTitle(DictTypes.SocialStatuses, dicts)?.content?.find(
        (socialStatus) =>
          socialStatus.code === Number(personalInfo.socialStatus)
      )?.name === 'Военнослужащий';

    const progressSteps = ['step_start', 'step_middle', 'step_finish'];
    const steps = [
      {
        title: `Информация о продукте (${
          !fieldsReadOnly
            ? `${countFields(productInfo).nonEmptyFields}/${
                countFields(storeProductInfo).totalFields - 2
              }`
            : `10/10`
        })`
      },
      {
        title: `Личная информация (${
          !fieldsReadOnly
            ? `${countFields(personalInfo).nonEmptyFields}/${
                countFields(storePersonalInfo).totalFields
              }`
            : `${countFields(personalInfo).nonEmptyFields}/${
                countFields(personalInfo).totalFields
              }`
        })`
      },
      {
        title: `Доп. информация (${
          !fieldsReadOnly
            ? `${
                countFields(uploadFiles).nonEmptyFields -
                (productInfo.proofInCome ? 0 : 1)
              } /${
                countFields(uploadFiles).totalFields -
                (isOrganisationIp ? 0 : 1) -
                (isServicemanClient ? 0 : 1) -
                (productInfo.proofInCome ? 0 : 1)
              }`
            : `${countFields(uploadFiles).nonEmptyFields}/${
                countFields(uploadFiles).totalFields -
                (isOrganisationIp ? 0 : 1) -
                (isServicemanClient ? 0 : 1) -
                (productInfo.proofInCome ? 0 : 1)
              }`
        })`
      }
    ];
    const isFirstValid: boolean =
      countFields(productInfo).nonEmptyFields ===
      countFields(storeProductInfo).totalFields - 2;

    const isSecondValid =
      countFields(personalInfo).nonEmptyFields ===
      countFields(storePersonalInfo).totalFields;

    const isLastValid =
      countFields(uploadFiles).nonEmptyFields -
        (productInfo.proofInCome ? 0 : 1) ===
      countFields(uploadFiles).totalFields -
        (isOrganisationIp ? 0 : 1) -
        (isServicemanClient ? 0 : 1) -
        (productInfo.proofInCome ? 0 : 1);

    setValidStep((prevState) => ({
      ...prevState,
      first: !fieldsReadOnly ? isFirstValid : true,
      second: !fieldsReadOnly ? isSecondValid : true,
      last: !fieldsReadOnly ? isLastValid : true
    }));

    current < 3 && (steps[current].title = `${steps[current]?.title}`);
    setStepCountItems([...steps]);
    setStepCount(current + 1);
    current < 3 && setProgress(progressSteps[current]);

    setContinueDisabled(
      isFirstValid &&
        isSecondValid &&
        isLastValid &&
        uploadFiles.docPhoto !== null &&
        // uploadFiles.docPhotoFront !== null &&
        uploadFiles.clientPhoto !== null
    );
  }, [
    productInfo,
    personalInfo,
    additionalInfo,
    current,
    // personalAddressInfo,
    uploadFiles
  ]);

  const toggleAdditionalChecked = (isChecked?: boolean) => {
    if (isChecked !== undefined) setAdditionalChecked(isChecked);
  };

  const modalCancelHandler = () => {
    setPersonSpouseInfo(personSpouseInfoData);
    setShowCancelModal(false);
    navigate('/');
  };

  const onContinueHandler = async () => {
    if (!additionalChecked) {
      return notification.info({
        message: 'Внимание!',
        description: `Ознакомьтесь с дополнительными продуктами`,
        placement: 'bottomRight'
      });
    }

    if (current < 2) {
      const selectedPromo = stocksBonds.find(
        (promo) => promo.label.includes('Евразийский') && promo.checked
      );
      if (selectedPromo) {
        const initFeePercent =
          (parsePrice(productInfo.initFee) * 100) /
          parsePrice(productInfo.costCar);
        if (
          selectedPromo.checked &&
          selectedPromo.label.includes('12') &&
          initFeePercent < 20
        ) {
          const recommendedInitFee = parsePrice(productInfo.costCar) * 0.2;
          setRecommendedInitFee(Math.ceil(recommendedInitFee));
          return setInitFeeModal(true);
        }
        if (selectedPromo.checked && selectedPromo.label.includes('24')) {
          if (productInfo.proofInCome && initFeePercent < 20) {
            const recommendedInitFee = parsePrice(productInfo.costCar) * 0.2;
            setRecommendedInitFee(Math.ceil(recommendedInitFee));
            return setInitFeeModal(true);
          }
          if (!productInfo.proofInCome && initFeePercent < 30) {
            const recommendedInitFee = parsePrice(productInfo.costCar) * 0.3;
            setRecommendedInitFee(Math.ceil(recommendedInitFee));
            return setInitFeeModal(true);
          }
        }
        if (
          selectedPromo.checked &&
          selectedPromo.label.includes('36') &&
          initFeePercent < 50
        ) {
          const recommendedInitFee = parsePrice(productInfo.costCar) * 0.5;
          setRecommendedInitFee(Math.ceil(recommendedInitFee));
          return setInitFeeModal(true);
        }
      }
      setCurrent(current <= 3 ? current + 1 : current);
      setStepCount(stepCount < 3 ? stepCount + 1 : stepCount);
    }

    if (current === 2) {
      const martialDict = findDictByTitle(DictTypes.MaritalStatuses, dicts);
      const residenceStatuses = findDictByTitle(
        DictTypes.ResidentStatuses,
        dicts
      );

      const martialSingleCode = 2;

      const isSingle =
        martialDict.content.find(
          (status) => status.code === Number(personalInfo.familyStatus)
        )?.code === martialSingleCode;

      personalInfo.spouseInfo = !isSingle
        ? {
            ...personSpouseInfo,
            spouseResidentStatus: Number(personSpouseInfo.spouseResidentStatus),
            spouseAddSalary: personSpouseInfo.spouseAddSalary.replace(
              /\s/g,
              ''
            ),
            spouseSalary: personSpouseInfo.spouseSalary.replace(/\s/g, ''),
            spouseDateBirth: dayjs(personSpouseInfo.spouseDateBirth).toString()
          }
        : null;
      productInfo.stocksBonds = stocksBonds;

      Object.keys(uploadFiles).forEach((key) => {
        if (key === 'docScanSocialStatus') return;
        switch (key) {
          case 'otherDoc':
          case 'incomeCert':
            //@ts-ignore
            if (!uploadFiles[key]?.base64) {
              //@ts-ignore
              uploadFiles[key] = {
                name: 'blank',
                //@ts-ignore
                base64: 'blank'
              };
            }
            break;
        }
      });

      const newOrderDto: TNewOrderDto = {
        uuid: orderApp?.uuid || requestId,
        status: 'draft',
        otp: otpCode || orderApp?.otp || '',
        otpDate: new Date().toISOString(),
        code: clientInfo?.id.toString(),
        productInfo: {
          product: {
            ...productInfo,
            loanTerm: Number(productInfo.loanTerm),
            paymentScheme: Number(productInfo.paymentScheme),
            dealer: Number(productInfo.dealer),
            autoType: Number(productInfo.autoType),
            engineType: Number(productInfo.engineType),
            carYear:
              productInfo?.markModelYear?.year?.toString() ||
              new Date().getFullYear().toString(),
            costCar: productInfo.costCar.replace(/\s/g, ''),
            initFee: productInfo.initFee.replace(/\s/g, ''),
            markModelYear: {
              model: Number(productInfo.markModelYear.model),
              brand: Number(productInfo.markModelYear.brand),
              year:
                productInfo.markModelYear.year ||
                new Date().getFullYear().toString()
            }
          },
          additionalProducts: additionalProducts
        },
        personalInfo: {
          docType: Number(personalInfo.docType),
          sex: Number(personalInfo.sex),
          jobAddress: personalInfo.jobAddress,
          dateBirth: personalInfo.dateBirth,
          firstName: clientInfo.firstName,
          middleName: clientInfo.middleName,
          lastName: clientInfo.lastName,
          clientIin: clientInfo.iin,
          familyStatus: Number(personalInfo.familyStatus),
          phoneNumber: cleanPhoneNumber(clientInfo?.telNum),
          spouseInfo: !isSingle
            ? {
                ...personSpouseInfo,
                spouseResidentStatus:
                  dicts
                    ?.find((dict) => dict.dictType === 'Resident_Statuses')
                    ?.content.find(
                      (item) =>
                        item.code == personSpouseInfo.spouseResidentStatus
                    )
                    ?.code.toString() || ''
              }
            : null
        },
        additionalInfo: {
          files: uploadFiles,
          socialStatus: Number(personalInfo.socialStatus),
          addContactsFullName: personalInfo.addContactsFullName,
          additionalSalary: personalInfo.additionalSalary.replace(/\s/g, ''),
          salary: personalInfo.salary.replace(/\s/g, ''),
          addContactsIsRelated: Number(personalInfo.addContactsIsRelated),
          addContactsTelNum: cleanPhoneNumber(personalInfo.addContactsTelNum),
          children: personalInfo.children,
          companyName: personalInfo.companyName,
          jobTitle: personalInfo.jobTitle,
          companyType: Number(personalInfo.companyType),
          workTelNumber: cleanPhoneNumber(personalInfo.workTelNumber)
        }
      };

      await dispatch(
        createNewOrder({
          data: newOrderDto,
          dicts: dicts || []
        })
      );
      dispatch(setRequestData(newOrderDto.uuid));
      if (!isError) {
        const brandToFind = findDictByTitle(
          DictTypes.Brands,
          dicts
        ).content.find(
          (brand) =>
            brand.code === newOrderDto.productInfo.product.markModelYear.brand
        );
        const modelToFind = findDictByTitle(
          DictTypes.Models,
          dicts
        ).content.find(
          (model) =>
            model.code === newOrderDto.productInfo.product.markModelYear.model
        );

        // dispatch(setOrderApp(obj));
        dispatch(
          setLoanData({
            amount: newOrderDto.productInfo.product.costCar.replace(/\s/g, ''),
            auto: `${brandToFind?.name || ''} ${modelToFind?.name || ''}, ${
              newOrderDto.productInfo.product.markModelYear.year
            }`
          })
        );
        createRequest();
      }
    }
  };

  const onCancelHandler = () => {
    setShowCancelModal(true);
  };

  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {

    if (file && file.size > 3000000) {
      notification.warning({
        message: 'Файл не должен превышать 2mb',
        description: 'Выберите другой файл',
        duration: 5
      });
      setUploadFiles((prevState) => ({ ...prevState, [type]: null }));
    } else {
      setUploadFiles((prevState) => ({
        ...prevState,
        [type]: { base64: fileBase64, name: file?.name, size: file?.size }
      }));
      if (type === 'docPhotoBack' || type === 'docPhotoFront')
        setUploadFiles((prevState) => ({ ...prevState, docPhoto: null }));
    }
  };

  const handleStep = (val: number) => {
    setCurrent(val);
    if (val === 0) {
      setCurrent(val);
    } else if (val === 1) {
      validStep.first && setCurrent(val);
    } else if (val === 2) {
      validStep.first && validStep.second && setCurrent(val);
    }
  };

  useEffect(() => {
    if (productInfo.markModelYear.model) {
      const modelUuid = findDictByTitle(DictTypes.Models, dicts).content.find(
        (dict) => Number(dict.code) === Number(productInfo.markModelYear.model)
      )?.uuid;
      modelUuid && dispatch(getPromosByModel(modelUuid));
    } else {
      dispatch(setProductDictsToInitial());
    }
  }, [productInfo.markModelYear.model]);

  useEffect(() => {
    const localReq = localStorage.getItem(orderApp?.uuid || '');
    if (orderApp?.status === 'draft') {
      if (!localReq) {
        const arrayHandler = stockBoundsProductsDict
          ?.filter((elem) => elem.is_active) 
          .map((elem) => {
            const res = {
            id: elem.uuid,
            value: elem.bank,
            label: elem.name,
            checked: elem.name === 'Jetour субсидия от Halyk Bank',
            info: elem.description,
            code: elem.code,
            grace: elem.grace,
            bank_name: elem.bank_name,
            loan_period: elem.loan_period,
            loan_amount: elem.loan_amount,
            partner_code: elem.partner_code,
            product_code: elem.product_code,
            is_active: elem.is_active
          };
          return res;
        });
        setStocksBonds(arrayHandler || []);
      } else {
        const parsedReq = JSON.parse(localReq);
        setStocksBonds(parsedReq.productInfo.product.stocksBonds);
      }
    } else {
      const arrayHandler = stockBoundsProductsDict
      ?.filter((elem) => elem.is_active) 
      .map((elem) => {
        const isChecked = orderApp?.productInfo?.product?.stocksBonds?.some(
          (value) => value.code === elem.code
        ) as boolean;
        const res = {
          id: elem.uuid,
          value: elem.bank,
          label: elem.name,
          checked: isChecked,
          info: elem.description,
          code: elem.code,
          grace: elem.grace,
          bank_name: elem.bank_name,
          loan_period: elem.loan_period,
          loan_amount: elem.loan_amount,
          partner_code: elem.partner_code,
          product_code: elem.product_code,
          is_active: elem.is_active
        };
        return res;
      });
      arrayHandler && setStocksBonds(arrayHandler);
    }
  }, [stockBoundsProductsDict]);

  useEffect(() => {
    if (isError) {
      notification.error({
        message: 'Ошибка создания заявки',
        description: 'Произошла ошибка при создании заявки',
        duration: 5
      });
      navigate('/autocred');
      dispatch(resetIsError());
    }
  }, [isError]);

  useEffect(() => {
    if (orderApp?.status !== 'draft') {
      const arrayHandler = additionalProductsDict?.map((elem) => {
        const isChecked = orderApp?.productInfo?.additionalProducts?.some(
          (value) => value.code === elem.code
        ) as boolean;
        const res = {
          id: elem.uuid,
          value: elem.bank,
          label: elem.name,
          checked: isChecked,
          info: elem.description,
          code: elem.code,
          grace: elem.grace,
          bank_name: elem.bank_name,
          loan_period: elem.loan_period,
          loan_amount: elem.loan_amount,
          partner_code: elem.partner_code,
          product_code: elem.product_code,
          insurance_uuid: null
          // selected_insurance_uuid: null
        };
        return res;
      });
      arrayHandler && setAdditionalProducts(arrayHandler);
    }
  }, [additionalProductsDict]);

  return (
    <>
      <Spin
        spinning={isCreateLoading}
        fullscreen
        size="large"
        indicator={
          <LoadingOutlined style={{ fontSize: 70, color: 'whitesmoke' }} spin />
        }
      />
      <CustomModal
        isModalOpen={initFeeModal}
        handleOk={() => {
        
        }}
        handleCancel={() => {
          setInitFeeModal(false);
        }}
        title="" //"Корректировка ПВ"
        editClass="initFee"
        footer={[
          <Button key="back" onClick={() => setInitFeeModal(false)}>
            Закрыть
          </Button>,
          <Button
            style={{ background: '#ff2e1f', border: 'none' }}
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={() => {
              setProductInfo((prevState) => ({
                ...prevState,
                initFee: formatPrice(
                  recommendedInitFee ? recommendedInitFee : '',
                  12
                )
              }));
              setInitFeeModal(false);
              notification.success({
                message: 'Сумма ПВ успешно изменена. Нажмите кнопку ПРОДОЛЖИТЬ',
                // description: 'Выберите другой файл',
                duration: 5
              });
            }}
          >
            Изменить
          </Button>
        ]}
      >
        <p style={{ marginTop: '15px', fontSize: 16 }}>
          По выбранной акции от Евразийского банка сумма ПВ должна быть не менее{' '}
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
            {formatPrice(recommendedInitFee ? recommendedInitFee : '', 12)}
          </span>{' '}
          ₸
        </p>
      </CustomModal>
      <CustomModal
        editClass="cancel"
        title="Вы хотите отменить создание заявки?"
        isModalOpen={showCancelModal}
        handleOk={modalCancelHandler}
        handleCancel={() => setShowCancelModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowCancelModal(false)}>
            Нет
          </Button>,
          <Button
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={modalCancelHandler}
          >
            Да, отменить
          </Button>
        ]}
      >
        <p className="cancel_text">Внесенные данные не будут сохранены</p>
      </CustomModal>
      <ProductsForm
        stocksBonds={stocksBonds}
        onClose={setOpen}
        handleChangeProdInsurance={handleChangeInsuranceOrganisation}
        handleChangeAddProductLoanPeriod={
          handleChangeAddProductLoanPeriodChange
        }
        open={open}
        handleAddProductsChange={handleAddProductsChange}
        addProducts={additionalProducts}
        additionalChecked={additionalChecked}
        additionalCheckedHandler={toggleAdditionalChecked}
      />
      <div className="OrderStep">
        <div className="product_info">
          {current === 0 && (
            <FirstStep
              selectedDealerPoint={(productInfo?.dealer as string) || ''}
              productInfo={productInfo}
              setProductInfo={setProductInfo}
              dicts={dicts}
              stocksBonds={stocksBonds}
              handleStockBoundChange={handleStockBoundChange}
              fieldsReadOnly={fieldsReadOnly}
            />
          )}

          {current === 1 && (
            <SecondStep
              showFamilyStatusModal={showFamilyStatusModal}
              setShowFamilyStatusModal={setShowFamilyStatusModal}
              personSpouseInfo={personSpouseInfo}
              setPersonSpouseInfo={setPersonSpouseInfo}
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
              dicts={dicts}
              fieldsReadOnly={fieldsReadOnly}
              clientInfo={clientInfo}
            />
          )}

          {current === 2 && (
            <ThirdStep
              personalInfo={personalInfo}
              handleFileChange={handleFileChange}
              additionalInfo={additionalInfo}
              setAdditionalInfo={setAdditionalInfo}
              productInfo={productInfo}
              dicts={dicts}
              file={uploadFiles}
              fieldsReadOnly={fieldsReadOnly}
            />
          )}

          {!fieldsReadOnly ? (
            current !== 2 ? (
              <button
                style={{ marginTop: '20px' }}
                className="continue_btn"
                onClick={onContinueHandler}
                disabled={current === 0 ? !validStep.first : !validStep.second}
              >
                Продолжить
              </button>
            ) : (
              <button
                style={{ marginTop: '20px' }}
                className="continue_btn"
                onClick={onContinueHandler}
                disabled={!continueDisabled}
              >
                Создать заявку
              </button>
            )
          ) : null}
        </div>
        <div className="steps">
          <div className="step">
            <div className="step_count">
              <p>
                Шаг {stepCount} из {stepCountItems.length}
              </p>
            </div>
            <span className={`progress ${progress}`}></span>
            <Steps
              direction="vertical"
              size="small"
              current={current}
              items={stepCountItems}
              onChange={handleStep}
              className={`${!validStep.first ? 'notValidFirtsItem' : ''} ${
                !validStep.second ? 'notValidSecondItem' : ''
              } ${!validStep.last ? 'notValidLastItem' : ''}`}
            />

            <button onClick={onCancelHandler}>Отменить заявку</button>
          </div>
          {current === 0 && (
            <AdditionalProducts
              setOpen={setOpen}
              products={additionalProducts
                .filter((prod) => prod.checked)
                .map((prod) => {
                  return {
                    id: prod.id,
                    switch: prod.checked,
                    title: prod.label
                  };
                })}
              orderApp={orderApp}
            />
          )}
          {current === 1 && personSpouseInfo.isMarried && (
            <PersonSpouse
              fullName={personSpouseInfo.spouseFullName}
              iin={personSpouseInfo.spouseIin}
              onclick={() =>
                orderApp === null ? setShowFamilyStatusModal(true) : null
              }
            />
          )}
        </div>
      </div>
      <button
        style={{ display: 'none' }}
        ref={enterButtonRef}
        onClick={enterPressedAction}
      >
        Enter
      </button>
    </>
  );
};
