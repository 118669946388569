import React, { FC } from 'react';
import { Tabs } from 'antd';
import { UsersTable } from '@/components/AdminPanel/Users/UsersTable';
import { PositionsTable } from '@/components/AdminPanel/Users/PositionsTable';
import { RolesActionsTable } from '@/components/AdminPanel/Users/RolesActionsTable';

export const Users: FC = () => {
  return (
    <>
      <Tabs
        defaultActiveKey="users"
        items={[
          {
            label: 'Список пользователей',
            key: 'users',
            children: <UsersTable />
          },
          {
            label: 'Позиция (Должность)',
            key: 'positions',
            children: <PositionsTable />
          },
          {
            label: 'Roles Actions',
            key: 'roles',
            children: <RolesActionsTable />
          }
        ]}
      />
    </>
  );
};
