import { TFileAttached } from '@/types/financing';
import React, { FC, useEffect, useState } from 'react';
import { FileDownload } from '@/components/Shared/Input/FileDownload';

type ShinhanBankProps = {
  requestLoading: boolean;
  handleSendDocs: (uploadedFiles: any) => void;
  attachedFiles: TFileAttached[];
  showSendButton: boolean;
};

const ShinhanBank: FC<ShinhanBankProps> = ({
  requestLoading,
  handleSendDocs,
  showSendButton,
  attachedFiles
}) => {
  const [files, setFiles] = useState<TFileAttached[]>([]);
  const [uploadFiles, setUploadFiles] = useState({
    spouseConsent: null
  });
  const [isSendAvailable, setIsSendAvailable] = useState<boolean>(false);
  const [shinhanGuarantee, setShinhanGuarantee] = useState<string | null>(null);
  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {
    setUploadFiles((prevState) => ({
      ...prevState,
      [type]: { name: file?.name, base64: fileBase64 }
    }));
  };

  useEffect(() => {
    const filteredArr = attachedFiles
      .filter((value, index, self) => {
        return self.findIndex((item) => item.type === value.type) === index;
      })
      .filter(
        (file) =>
          file.type === 'forteSpouseAgreement' ||
          file.type === 'forteCommitmentLetter'
      );
    setFiles(filteredArr);
    if (attachedFiles.length > 0) {
      const shinhanGuarnteeFile = attachedFiles.find(
        (file) => file.type === 'guaranteeShinhan'
      );
      shinhanGuarnteeFile && setShinhanGuarantee(shinhanGuarnteeFile.uuid);
    }
  }, [attachedFiles]);

  return (
    <div>
      {shinhanGuarantee && (
        <FileDownload
          prevStateTitle={'Гарантийное письмо'}
          fileLink={shinhanGuarantee}
        />
      )}
    </div>
  );
};

export default ShinhanBank;
