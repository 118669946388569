import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowModalCreateDbTable } from '@/store/admin/admin.slice';
import { CreateDbTableModal } from '@/components/AdminPanel/Modals/CreateDbTableModal';
import { getExecutorSql } from '@/store/admin/executorSql.slice';
import { SqlResponseRender } from '@/components/AdminPanel/Executor/SqlResponseRender';

const Executor = () => {
  const dispatch = useAppDispatch();
  const { showModalCreateDbTable } = useAppSelector((state) => state.admin);
  const { response } = useAppSelector((state) => state.adminExecutor);
  const [areaValue, setAreaValue] = useState<string>('');
  const handleExecuteSQL = async () => {
    const data = await dispatch(getExecutorSql({ query: areaValue })); //sqlExecutor.executeSQL({ query: areaValue });
  };

  return (
    <div style={{ maxWidth: '1112px', margin: ' 0 auto' }}>
      <Button onClick={() => dispatch(setShowModalCreateDbTable(true))}>
        Add
      </Button>
      {showModalCreateDbTable && (
        <CreateDbTableModal
          setShow={() => dispatch(setShowModalCreateDbTable(false))}
        />
      )}
      <TextArea
        style={{ margin: '20px 0' }}
        rows={8}
        value={areaValue}
        onChange={(e) => setAreaValue(e.target.value)}
      />
      <Button onClick={handleExecuteSQL}>Выполнить</Button>
      {response.length ? <SqlResponseRender array={response} /> : null}
    </div>
  );
};

export default Executor;
