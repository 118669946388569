import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select
} from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { TPosition } from '@/types/position.type';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowPositionModal } from '@/store/admin/admin.slice';
import {
  createPosition,
  getRolesActions,
  updatePosition
} from '@/store/admin/adminUsers.slice';

type TPositionModalProps = {
  positionItem: TPosition | null;
  editMode: boolean;
};
export const CreateOrEditPositionModal: FC<TPositionModalProps> = ({
  positionItem,
  editMode
}) => {
  const dispatch = useAppDispatch();
  const { showPositionModal } = useAppSelector((state) => state.admin);
  const { roles_actions } = useAppSelector((state) => state.adminUsers);
  const [position, setPosition] = useState<TPosition>({} as TPosition);
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [actionsOptions, setActionsOption] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (editMode && positionItem) {
      setPosition(() => ({
        ...positionItem,
        uuid: positionItem.uuid
      }));
      if (positionItem.Position_Roles_Actions) {
        const actions_uuid = positionItem.Position_Roles_Actions.map(
          (action) => action.roles_action_uuid
        );
        setDeleteList(actions_uuid);
        setPosition((prevState) => ({
          ...prevState,
          Roles_Actions_UUID: actions_uuid
        }));
      }
    }

    dispatch(getRolesActions());
  }, []);

  useEffect(() => {
    if (roles_actions) {
      const actions = roles_actions.map((action) => ({
        value: action.uuid!,
        label: action.name
      }));
      setActionsOption(actions);
    }
  }, [roles_actions]);
  const onOk = () => {
    if (editMode) {
      const filteredList = deleteList.filter(
        (item) => !position.Roles_Actions_UUID!.includes(item)
      );
      dispatch(
        updatePosition({
          ...position,
          Delete_Position_Roles_Action: filteredList
        })
      );

      return dispatch(setShowPositionModal(false));
    }
    dispatch(createPosition(position));
    return dispatch(setShowPositionModal(false));
  };
  return (
    <Modal
      className="modal"
      open={showPositionModal}
      onCancel={() => dispatch(setShowPositionModal(false))}
      onOk={onOk}
    >
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Row>
              <Col span={18}>
                <Form.Item label="Наименование" required>
                  <Input
                    value={position.name}
                    onChange={(e) =>
                      setPosition((prevState) => ({
                        ...prevState,
                        name: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={3} offset={1}>
                <Form.Item label="Статус">
                  <Checkbox
                    checked={position.is_active}
                    onChange={() =>
                      setPosition((prevState) => ({
                        ...prevState,
                        is_active: !position.is_active
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Код" required>
                  <InputNumber
                    controls={false}
                    value={position.code}
                    onChange={(e) =>
                      setPosition((prevState) => ({
                        ...prevState,
                        code: e!
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={16} offset={2}>
                <Form.Item label="Описание">
                  <Input.TextArea
                    value={position.description}
                    onChange={(e) =>
                      setPosition((prevState) => ({
                        ...prevState,
                        description: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Roles Actions">
                  <Select
                    mode="multiple"
                    value={position.Roles_Actions_UUID}
                    maxTagCount={1}
                    options={actionsOptions}
                    onChange={(value) =>
                      setPosition((prevState) => ({
                        ...prevState,
                        Roles_Actions_UUID: value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
