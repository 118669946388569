import { Button } from 'antd';
import CreateAppDialogForm from './CreateAppDialogForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { setClientInfo, setCurrent } from '@/store/newOrder.slice';
import { onSetSelectedBankToInitial } from '@/store/financingStep.slice';
import {
  clientCreateCalc,
  onSetOrderOtpCode,
  requestCreateUnverifiedDraft,
  retryValidateOTP,
  validateCalcOTP,
  validateOTP
} from '@/store/orderStep.slice';
import { setVerifyModalShowType } from '@/store/app.slice';
import { cleanPhoneNumber, formatPhoneNumber } from '@/services/formatter';
import {
  generateRandomSixDiginCode,
  isValidInput,
  validateNumberInputValue
} from '@/services';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import { checkObjectValues } from '@/services/validators/checkObjectValues';
import { checkVerifyInputValuesValid } from '@/services/validators/checkVerifyInputValuesValid';
import { getClientByIin } from '@/store/clients.slice';

export type TVerifyModalProps = {
  // inputValues: { iin: string; fullName: string; telNum: string };
  verifyModalTitle: string;
  // openModal: { prevClient: boolean; smsVerf: boolean };
  // isFocused: { iin: boolean; fullName: boolean; telNum: boolean };
  // onOkHandler: () => void;
  // onCancelHandler: () => void;
  // retryOTP: () => void;
  // onChangeInputHandler: (val: string, label: string, type: string) => void;
  // step: number;
  // setStep: React.Dispatch<React.SetStateAction<number>>;
  // disabled: boolean;
};

export const VerifyModal = ({
  // isFocused,
  // disabled,
  // retryOTP,
  verifyModalTitle
}: TVerifyModalProps) => {
  const navigate = useNavigate();

  const [isFocused, setIsFocused] = useState<{
    iin: boolean;
    fullName: boolean;
    telNum: boolean;
  }>({ iin: false, fullName: false, telNum: false });

  const [verifyContinueDisabled, setVerifyContinueDisabled] =
    useState<boolean>(true);

  const [otpValidWatcher, setOtpValidWatcher] = useState<boolean | null>(null);

  const { verifyModalState } = useAppSelector((state) => state.app);

  const { dicts } = useAppSelector((state) => state.dict);

  const { dealer } = useAppSelector((state) => state.userInfo);

  const { isLoading, otpValid, requestId, clientCalcId } = useAppSelector(
    (state) => state.orderStep
  );

  const [code, setCode] = useState({
    firstSquare: '',
    secondSquare: '',
    thirdSquare: '',
    fourthSquare: ''
  });

  const dispatch = useAppDispatch();

  const [step, setStep] = useState(1);

  const [inputValues, setInputValues] = useState<{
    iin: string;
    fullName: string;
    telNum: string;
  }>({ iin: '', fullName: '', telNum: '+7' });

  useEffect(() => {
    setOtpValidWatcher(otpValid);
  }, [otpValid]);

  useEffect(() => {
    if (otpValidWatcher === true) {
      const formattedOtpCode =
        code.firstSquare +
        code.secondSquare +
        code.thirdSquare +
        code.fourthSquare;
      dispatch(setCurrent(0));
      dispatch(onSetSelectedBankToInitial());
      dispatch(onSetOrderOtpCode(formattedOtpCode));
      dispatch(setVerifyModalShowType({ prevClient: false, smsVerf: false }));
      verifyModalState.isNewApplication
        ? navigate('/newApp')
        : navigate('/calculator');
    }
  }, [otpValidWatcher]);

  const retryOTP = async () => {
    await dispatch(
      retryValidateOTP({
        clientPhone: cleanPhoneNumber(inputValues.telNum),
        uuid: requestId
      })
    );
  };

  const onCancelHandler = async () => {
    dispatch(setVerifyModalShowType({ prevClient: false, smsVerf: false }));
    setInputValues({ iin: '', fullName: '', telNum: '' });
    setCode({
      firstSquare: '',
      secondSquare: '',
      thirdSquare: '',
      fourthSquare: ''
    });
    setStep(1);
  };

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    if (label === 'fullName') {
      setInputValues((prevState) => {
        if (isValidInput(val)) {
          return { ...prevState, [label]: val };
        } else {
          return prevState;
        }
      });
    } else if (label === 'telNum') {
      val = formatPhoneNumber(val);
      setInputValues((prevState) => ({ ...prevState, [label]: val }));
    } else {
      setInputValues((prevState) => ({
        ...prevState,
        [label]: validateNumberInputValue(val, 12)
      }));
    }
  };

  const onOkHandler = async () => {
    // setOpenModal((prevState) => ({ ...prevState, prevClient: false }));
    // setOpenModal((prevState) => ({ ...prevState, smsVerf: true }));
    dispatch(setVerifyModalShowType({ prevClient: false, smsVerf: true }));
    const obj = {
      iin: inputValues.iin,
      fullName: inputValues.fullName
        .split(' ')
        .filter((data) => data.trim() !== ''),
      telNum: inputValues.telNum,
      id: generateRandomSixDiginCode()
    };
    const createRequestDraftDto = {
      iin: inputValues.iin,
      lastName: inputValues.fullName.split(' ')[0] || ' ',
      firstName: inputValues.fullName.split(' ')[1] || ' ',
      middleName: inputValues.fullName.split(' ')[2] || ' ',
      clientPhone: cleanPhoneNumber(inputValues.telNum).substring(1),
      dealer_uuid: dealer?.value || ''
    };
    dispatch(setClientInfo(obj));

    verifyModalState.isNewApplication
      ? await dispatch(
          requestCreateUnverifiedDraft({
            draftDto: createRequestDraftDto,
            dicts: dicts || []
          })
        )
      : await dispatch(clientCreateCalc(createRequestDraftDto));
  };

  useEffect(() => {
    const isInputValuesValid = checkVerifyInputValuesValid(inputValues);
    setVerifyContinueDisabled(!isInputValuesValid);
  }, [inputValues]);

  useEffect(() => {
    if (Object.values(code).every((val) => val)) {
      const isCodeValid = checkObjectValues(code);
      if (isCodeValid) {
        verifyModalState.isNewApplication
          ? dispatch(
              validateOTP({
                otp:
                  code.firstSquare +
                  code.secondSquare +
                  code.thirdSquare +
                  code.fourthSquare,
                uuid: requestId,
                dicts: dicts || []
              })
            )
          : dispatch(
              validateCalcOTP({
                otp:
                  code.firstSquare +
                  code.secondSquare +
                  code.thirdSquare +
                  code.fourthSquare,
                uuid: clientCalcId
              })
            );
      }
    }
  }, [code]);

  useEffect(() => {
    if (inputValues.iin.length === 12) {
      dispatch(getClientByIin(inputValues.iin))
        .unwrap()
        .then((clientData) => {
          if (clientData) {
            setInputValues((prevValues) => ({
              ...prevValues,
              fullName: `${clientData.last_name} ${clientData.first_name} ${clientData.middle_name || ''}`,
              telNum: clientData.phone_number
            }));
          }
        })
        .catch((error) => {
          console.error('Ошибка при получении данных клиента:', error);
        });
    }
  }, [inputValues.iin, dispatch]); 

  return (
    <>
      <CustomModal
        isModalOpen={verifyModalState.showVerifyModal.prevClient}
        handleOk={onOkHandler}
        handleCancel={onCancelHandler}
        title={verifyModalTitle}
        editClass="prevClientInfo"
        footer={[
          <Button className="cancel_btn" key="back" onClick={onCancelHandler}>
            Отмена
          </Button>,
          <Button
            disabled={verifyContinueDisabled || isLoading}
            className="continue_btn"
            key="submit"
            type="primary"
            onClick={onOkHandler}
          >
            Далее
          </Button>
        ]}
      >
        <div>
          <p className="sub_title">Заполните данные о клиенте</p>

          <CustomInput
            isFocused={isFocused.iin}
            value={validateNumberInputValue(inputValues.iin, 12)}
            label={'iin'}
            type={'iin'}
            labelTitle={'ИИН клиента'}
            onChangeInputHandler={onChangeInputHandler}
          />
          <CustomInput
            isFocused={isFocused.fullName}
            value={inputValues.fullName}
            label={'fullName'}
            type={'fullName'}
            labelTitle={'ФИО клиента'}
            onChangeInputHandler={onChangeInputHandler}
          />
          <CustomPhoneInput
            value={inputValues.telNum}
            label={'telNum'}
            type={'telNum'}
            onChangeInputHandler={onChangeInputHandler}
          />
        </div>
      </CustomModal>

      <CreateAppDialogForm
        retryOTP={retryOTP}
        openDialog={verifyModalState.showVerifyModal.smsVerf}
        onClose={onCancelHandler}
        step={step}
        checkCode={true}
        setStep={setStep}
        code={code}
        setCode={setCode}
      />
    </>
  );
};
