import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { Button, DatePicker, Select } from 'antd';
import { setShowSelectCarModal } from '@/store/app.slice';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import dayjs from 'dayjs';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';

type TSelectCarProps = {
  car?: {
    brand: string;
    model: string;
    year: string;
  };
  setSelectedCar?: (car: {
    brand: string;
    model: string;
    year: string;
  }) => void;
};

export const SelectCar: FC<TSelectCarProps> = ({ car, setSelectedCar }) => {
  const dispatch = useAppDispatch();
  const { dicts } = useAppSelector((state) => state.dict);
  const { dealer } = useAppSelector((state) => state.userInfo);
  const [brandOption, setBrandOption] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [modelOption, setModelOption] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const { showSelectCarModal } = useAppSelector((state) => state.app);
  const [newCar, setNewCar] = useState<{
    brand: string;
    model: string;
    year: string;
  }>({
    brand: '',
    model: '',
    year: new Date().getFullYear().toString()
  });

  useEffect(() => {
    const models = findDictByTitle(DictTypes.Models, dicts)
      .content.filter((model) => model.brand_uuid === newCar.brand)
      .map((m) => ({ value: m.uuid, label: m.name }));
    setModelOption(models);
  }, [newCar.brand, dicts]);

  useEffect(() => {
    const brands = findDictByTitle(DictTypes.Brands, dicts).content.map(
      (b) => ({ value: b.uuid, label: b.name })
    );
    setBrandOption(brands);
    const defaultDealer = findDictByTitle(
      DictTypes.Dealers,
      dicts
    ).content.find((_dealer) => _dealer.uuid === dealer!.value);
    const defaultBrand = findDictByTitle(DictTypes.Brands, dicts).content.find(
      (brand) => brand.uuid === defaultDealer?.sold_brands
    );
    if (defaultBrand) {
      setNewCar((prevState) => ({
        ...prevState,
        brand: defaultBrand.uuid
      }));
    }
  }, []);

  const onOk = () => {
    if (setSelectedCar) {
      setSelectedCar(newCar);
    }
    dispatch(setShowSelectCarModal(false));
  };

  return (
    <CustomModal
      editClass="markModelYear"
      title="Марка, модель и год выпуска"
      isModalOpen={showSelectCarModal}
      handleOk={onOk}
      handleCancel={() => dispatch(setShowSelectCarModal(false))}
      footer={[
        <Button
          key="back"
          onClick={() => dispatch(setShowSelectCarModal(false))}
        >
          Отмена
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          Сохранить
        </Button>
      ]}
    >
      <div className="auto_selects">
        <Select
          showSearch
          optionFilterProp={'label'}
          value={newCar.brand}
          placeholder="Марка авто"
          options={brandOption}
          onChange={(value) =>
            setNewCar((prevState) => ({
              ...prevState,
              brand: value,
              model: ''
            }))
          }
          // disabled={fieldsReadOnly}
        />
        <Select
          showSearch
          optionFilterProp={'label'}
          value={newCar.model}
          disabled={!!!newCar.brand}
          placeholder="Модель авто"
          options={modelOption}
          onChange={(value) =>
            setNewCar((prevState) => ({
              ...prevState,
              model: value
            }))
          }
          // disabled={fieldsReadOnly}
        />
        <DatePicker
          style={{ width: '100%', height: '50px', marginTop: '20px' }}
          picker="year"
          value={newCar.year ? dayjs(newCar.year, 'YYYY') : null}
          placeholder="Год выпуска"
          onChange={(value) =>
            setNewCar((prevState) => ({
              ...prevState,
              year: value ? dayjs(value).format('YYYY') : ''
            }))
          }
          // disabled={fieldsReadOnly}
        />
      </div>
    </CustomModal>
  );
};
