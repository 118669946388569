import React, { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { TFileAttached } from '@/types/financing';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import { FileUpload } from '@/components/Shared/Input/FileUpload';
import { checkArrayValues } from '@/services/validators/checkArrayValues';

type TBccBankProps = {
  requestLoading: boolean;
  handleSendDocs: (uploadedFiles: any) => void;
  attachedFiles: TFileAttached[];
  showSendButton: boolean;
  status: string;
};

export const AltynBank: FC<TBccBankProps> = ({
  attachedFiles,
  requestLoading,
  handleSendDocs,
  showSendButton,
  status
}: TBccBankProps) => {
  const [uploadFiles, setUploadFiles] = useState({
    spouseConsent: null
  });
  const [files, setFiles] = useState<TFileAttached[]>([]);

  const [letterOfGuarantee, setLetterOfGuarantee] = useState<string>('');
  const [spouseAgreement, setSpouseAgreement] = useState<string | null>(null);
  const [isSendAvailable, setIsSendAvailable] = useState<boolean>(false);

  const handleFileChange = (
    file: File | null,
    fileBase64: string | null,
    type: string
  ) => {
    setUploadFiles((prevState) => ({
      ...prevState,
      [type]: { name: file?.name, base64: fileBase64 }
    }));
  };

  useEffect(() => {
    const isValid = Object.values(uploadFiles).length
      ? checkArrayValues(Object.values(uploadFiles))
      : false;
    setIsSendAvailable(isValid);
  }, [uploadFiles]);

  useEffect(() => {
    if (attachedFiles.length > 0) {
      const guaranteeLetterLink = attachedFiles.find(
        (file) => file.type === 'grnAltyn'
      )?.link;
      guaranteeLetterLink && setLetterOfGuarantee(guaranteeLetterLink);

      const spouseAgreement = attachedFiles.find(
        (file) => file.type === 'spouseConsent'
      );
      spouseAgreement && setSpouseAgreement(spouseAgreement.link);
    }

    const filteredArr = attachedFiles
      .filter((value, index, self) => {
        return self.findIndex((item) => item.type === value.type) === index;
      })
      .filter(
        (file) => file.type === 'grnAltyn' || file.type === 'spouseConsent'
      );
    setFiles(filteredArr);
  }, [attachedFiles]);

  return (
    <>
      {/*{status === 'mobile_client' ? <p>Работа в мобильном ПО</p> : null}*/}

      {files &&
        files.map((file) => (
          <FileDownload
            key={file.type} // Используем тип файла как ключ
            prevStateTitle={
              file.type === 'grnAltyn'
                ? 'Гарантийное письмо'
                : file.type === 'spouseConsent'
                ? 'Согласие супруга(и) / Отсутствие супруга(и)'
                : ''
            }
            fileLink={file.uuid}
          />
        ))}

      <FileUpload
        fileFormat="application/pdf"
        positionLeft={'50px'}
        marginLeft={'35px'}
        isLg={true}
        onFileChange={handleFileChange}
        title={'Согласие супруга(и) / Отсутствие супруга(и) '}
        type="spouseConsent"
        preventActions={false}
      />

      {showSendButton ? (
        <button
          className="sendBtn"
          onClick={() => handleSendDocs(uploadFiles)}
          disabled={!isSendAvailable}
        >
          {!requestLoading ? (
            'Отправить документы'
          ) : (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
        </button>
      ) : null}
    </>
  );
};
