import { TSelectItem } from '../types/selectsItem';
import { DictTypes } from '../constants';

export const leasingFormDataOverallInfo: TSelectItem[] = [
  {
    key: 'registrationCountry',
    label: 'Страна регистрации',
    snakeCaseValues: DictTypes.Countries,
    editable: false,
    type: 'select'
  },
  {
    key: 'propertyType',
    label: 'Вид собственности',
    snakeCaseValues: DictTypes.PropertyTypes,
    editable: false,
    type: 'select'
  },
  {
    key: 'stateRegistrationDate',
    label: 'Дата государственной регистрации',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'placeOfStateRegistration',
    label: 'Место государственной регистрации',
    snakeCaseValues: DictTypes.DocumentIssuringAuthorities,
    editable: false,
    type: 'select'
  },
  {
    key: 'workStartDate',
    label: 'Дата фактического начала работы ИП/КХ/ФХ',
    editable: false,
    type: 'datepicker'
  },

  {
    key: 'bankRequisites',
    label: 'Банковские реквизиты',
    editable: false,
    type: 'input'
  },
  {
    key: 'mainActivity',
    label: 'Основной вид деятельности',
    snakeCaseValues: DictTypes.MainActivities,
    editable: false,
    type: 'select'
  },
  {
    key: 'okedOfMainAndSubActivity',
    label: 'ОКЭД по основной и вспомогательной деятельности ',
    editable: false,
    type: 'input'
  },
  {
    key: 'birthDate',
    label: 'Дата рождения',
    editable: false,
    type: 'datepicker'
  }
];

export const leasingFormDataDocumentInfo: TSelectItem[] = [
  {
    key: 'docName',
    label: 'Наименование документа',
    editable: false,
    snakeCaseValues: DictTypes.DocumentTypes,
    type: 'select'
  },
  {
    key: 'iin',
    label: 'Идентификационный номер ИИН/БИН',
    editable: false,
    type: 'input'
  },
  {
    key: 'stateRegistrationNumber',
    label: 'Государственный регистрационный номер',
    editable: false,
    type: 'input'
  },
  {
    key: 'documentNumber',
    label: 'Документ, удостоверяющий личность и его номер',
    editable: false,
    type: 'input'
  },
  {
    key: 'documentIssueDate',
    label: 'Дата выдачи удостоверения личности',
    editable: false,
    type: 'datepicker'
  },
  {
    key: 'documentValidEndDate',
    label: 'Окончание действительности удостоверения личности',
    editable: false,
    type: 'datepicker'
  }
];

export const leasingFormDataLicenseInfo: TSelectItem[] = [
  {
    key: 'firstSupervisorFullName',
    label: 'Ф.И.О. первого руководителя ',
    editable: false,
    type: 'input'
  },
  {
    key: 'availabilityLicenses',
    label:
      'Сведения о наличии лицензий (разрешений) на осуществление определенного вида деятельности или операций (при наличии)',
    editable: false,
    type: 'input'
  },
  {
    key: 'licenseNum',
    label: 'Номер лицензии (разрешения)',
    editable: false,
    type: 'input'
  },
  {
    key: 'licenseOrganizationAuthority',
    label: 'Кем выдана лицензия (разрешение)',
    editable: false,
    type: 'input'
  },
  {
    key: 'licenseMainActivity',
    label: 'Вид деятельности по лицензии (разрешению)',
    editable: false,
    type: 'input'
  },
  {
    key: 'firstRegistrationDate',
    label: 'Дата первичной регистрации',
    editable: false,
    type: 'datepicker'
  }
];

export const leasingFormDataAddressInfo: TSelectItem[] = [
  {
    key: 'livingAddress',
    label: 'Адрес места жительства (регистрации) и (или) места пребывания',
    editable: false,
    type: 'input'
  },
  {
    key: 'legalAddress',
    label: 'Юридический адрес',
    editable: false,
    type: 'input'
  },
  {
    key: 'actualAddress',
    label: 'Фактический адрес',
    editable: false,
    type: 'input'
  }
];

export const leasingFormDataContactInfo: TSelectItem[] = [
  {
    key: 'clientFullName',
    label:
      'ФИО Клиента',
    editable: false,
    type: 'input'
  },
  {
    key: 'email',
    label:
      'Адрес электронной почты и наименование интернет ресурса (при наличии)',
    editable: false,
    type: 'input'
  },
  {
    key: 'additionalPhoneNumber',
    label: 'Номер контактного телефона',
    editable: false,
    type: 'phoneInput'
  },

  {
    key: 'contactPhoneNumber',
    label: 'Контактные телефоны',
    editable: false,
    type: 'phoneInput'
  }
];
