import React, { useEffect, useState } from 'react';
import { BankOffers } from './BankOffers/BankOffers';
import { ReloadOutlined } from '@ant-design/icons';
import { AcceptModal } from './OfferStepModals/AcceptModal';
import { SpecialConditionsModal } from './OfferStepModals/SpecialConditionsModal';
import { PrintModal } from './OfferStepModals/PrintModal';
import { notification } from 'antd/lib';
import '@/styles/OfferStep.scss';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { TBankForPrint, TBankOffersAlternative } from '@/types/offers';
import { getRequestById, setPageTitle } from '@/store/newOrder.slice';
import {
  changeOfferStatus,
  getEblankStatus,
  getForteStatus,
  getResponseByRequestId,
  resetOfferUuid
} from '@/store/offerStep.slice';
import { changeRequestStatus } from '@/store/request.slice';
import { onSetSelectedBank } from '@/store/financingStep.slice';
import { specialConditions } from '@/data/offers.data';
import { TDownloadAdditionalFilesDto } from '@/types/uploadFiles';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';
import { apiConnector } from '@/integrations/api.connector';
import { downloadFilesApi } from '@/integrations/index.api';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';

type TOfferStepProps = { handleAcceptOffer: () => void };

export const OfferStep = ({ handleAcceptOffer }: TOfferStepProps) => {
  const { requestId, clientInfo } = useAppSelector((state) => state.newOrder);
  const { dicts } = useAppSelector((state) => state.dict);
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { offerUuid } = useAppSelector((state) => state.offerStep);
  // const { notifications } = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();
  const [showInfoForForte, setShowInfoForForte] = useState<boolean>(false);

  const [focusedBankOffer, setFocusedBankOffer] =
    useState<TBankOffersAlternative | null>(null);
  const [showSpecialConditionsModal, setShowSpecialConditionsModal] =
    useState<boolean>(false);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
  const [isAcceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [isPrintLoading, setIsPrintLoading] = useState<boolean>(false);
  const [responsesUpdating, setResponsesUpdating] = useState<boolean>(false);
  const [reCreateModal, setReCreateModal] = useState<boolean>(false);
  // const [notificationWatcher, setNotificationWatcher] =
  //   useState<TNotification[]>(notifications);

  // useEffect(() => {
  //   setNotificationWatcher(notifications);
  // }, [notifications]);

  useEffect(() => {
    dispatch(setPageTitle('Предложения'));
  }, []);

  const handleChangeEblankStatus = async (responseExtUuid: string) => {
    try {
      const data = {
        requestId: requestId,
        responseExtUuid: responseExtUuid
      };
      await dispatch(getEblankStatus(data));
      notification.success({
        message: '',
        description: 'Статусы банка обновлены',
        duration: 5
      });
    } catch (e: any) {
      notification.error({
        message: '',
        description: 'Ошибка обновления статуса',
        duration: 5
      });
      throw new Error(e);
    }
  };

  const handleChangeForteStatus = async (responseExtUuid: string) => {
    try {
      await dispatch(getForteStatus(responseExtUuid));
    } catch {}
  };

  const updateResponses = async (forte?: boolean) => {
    setResponsesUpdating(true);
    await dispatch(getRequestById({ reqId: requestId, dicts: dicts || [] }));
    await dispatch(getResponseByRequestId(requestId));
    setResponsesUpdating(false);
    if (!forte) {
      notification.success({
        message: 'Статусы успешно обновлены',
        description: 'Статусы ответов банков были успешно обновлены',
        duration: 5
      });
    }
  };

  const onAcceptOfferAlternate = (data: TBankOffersAlternative) => {
    setFocusedBankOffer(data);
    if (data.bank === 'forte') {
      dispatch(getForteStatus(data.extId)).then((action) => {
        if (action.type.endsWith('/fulfilled')) {
          const updatedOffers = action.payload;

          const forteOffer = updatedOffers.find(
            (offer: any) => offer.ext_uuid === data.extId
          );
          if (
            forteOffer?.status.code === 'declined' ||
            forteOffer?.status.code === 'cancelled' ||
            forteOffer?.status.code === 'error'
          ) {
            updateResponses(true);
            setShowInfoForForte(true);
            return; 
          }
          setShowAcceptModal(true);
        } else {
          console.error('Ошибка: запрос отклонен или завершился с ошибкой');
        }
      });
    } else {
      setShowAcceptModal(true);
    }
  };

  const toggleAcceptModal = () => setShowAcceptModal(!showAcceptModal);
  const toggleSpecialConditionsModal = () =>
    setShowSpecialConditionsModal(!showSpecialConditionsModal);
  const togglePrintModal = () => setShowPrintModal(!showPrintModal);

  const onSelectOffer = async () => {
    if (focusedBankOffer) {
      const offerStatusData: {
        res_id: string;
        is_offered: boolean;
        offered_uuid: string | null;
        status:
          | 'approved'
          | 'mobile_client'
          | 'declined'
          | 'waiting_bank_response'
          | 'error'
          | 'financed'
          | 'cancelled'
          | 'waiting_of_financing'
          | 'accepted_alternative';
      } = {
        res_id: focusedBankOffer.id,
        is_offered: true,
        offered_uuid: offerUuid,
        status:
          focusedBankOffer.bank === 'altyn'
            ? 'mobile_client'
            : 'waiting_of_financing'
      };

      const requestStatusData: {
        reqId: string;
        newStatus:
          | 'approved'
          | 'declined'
          | 'mobile_client'
          | 'waiting_bank_response'
          | 'error'
          | 'financed'
          | 'cancelled'
          | 'waiting_of_financing'
          | 'accepted_alternative';
      } = {
        reqId: requestId,
        newStatus:
          focusedBankOffer.bank === 'altyn'
            ? 'mobile_client'
            : 'waiting_of_financing'
      };

      setAcceptLoading(true);
      await dispatch(changeOfferStatus(offerStatusData));
      await dispatch(changeRequestStatus(requestStatusData));
      await dispatch(
        getRequestById({
          reqId: requestId,
          dicts: dicts || []
        })
      );
      dispatch(onSetSelectedBank(focusedBankOffer));
      if (focusedBankOffer.bank === 'halyk') {
        if (!focusedBankOffer.responseApprovedBody) return;
        const responseApprovedBody = JSON.parse(
          focusedBankOffer?.responseApprovedBody
        );
        const offerType = responseApprovedBody?.additional[0]?.offerType || '';
        const downloadFileDto: TDownloadAdditionalFilesDto = {
          bank_payload: {
            offer_type: offerType?.toString() || ''
          },
          iin: clientInfo?.iin || '',
          name: 'guaranteeCert.pdf',
          bank_id:
            findDictByTitle(DictTypes.Banks, dicts)
              ?.content?.find((bank) => bank.name === focusedBankOffer?.name)
              ?.code?.toString() || '',
          type: 'guaranteeHalyk',
          request_uuid: requestId,
          response_uuid: focusedBankOffer?.id || ''
        };

        setTimeout(async () => {
          await downloadFilesApi.downloadAdditionalFiles([downloadFileDto]);
          handleAcceptOffer();
          setAcceptLoading(false);
        }, 10000);
      } else if (focusedBankOffer.bank === 'forte') {
        if (!focusedBankOffer.responseApprovedBody) return;
        handleChangeForteStatus(focusedBankOffer.extId);
        const responseApprovedBody = JSON.parse(
          focusedBankOffer?.responseApprovedBody
        );
        const downloadFileDto = {
          bank_payload: {
            offer_type: ''
          },
          iin: clientInfo?.iin || '',
          name: 'forteCommitmentLetter.pdf',
          bank_id:
            findDictByTitle(DictTypes.Banks, dicts)
              ?.content?.find((bank) => bank.name === focusedBankOffer?.name)
              ?.code?.toString() || '',
          request_uuid: requestId,
          type: 'commitment_letter',
          response_uuid: focusedBankOffer?.id || '',
          marital_status: responseApprovedBody?.marital_status_id || '',
          commitment_letter: responseApprovedBody?.commitment_letter_id || '',
          spouse_agreement: responseApprovedBody?.spouse_agreement_id || '',
          no_spouce_agreement:
            responseApprovedBody?.no_spouse_agreement_id || '',
          responseApprovedBody: responseApprovedBody
        };
        setTimeout(async () => {
          await downloadFilesApi.downloadAdditionalFiles([downloadFileDto]);
          handleAcceptOffer();
          setAcceptLoading(false);
        }, 10000);
      } else if (focusedBankOffer.bank === 'shinhan') {
        const downloadFileDto = {
          bank_payload: {
            ext_uuid: focusedBankOffer?.extId || '',
            type: 'guaranteeShinhan',
            name: 'guaranteeShinhan.pdf'
          },
          request_uuid: requestId,
          response_uuid: focusedBankOffer?.id || '',
          bank_id:
            findDictByTitle(DictTypes.Banks, dicts)
              ?.content?.find((bank) => bank.name === focusedBankOffer?.name)
              ?.code?.toString() || '',
          iin: clientInfo?.iin || ''
        };
        setTimeout(async () => {
          await downloadFilesApi.downloadAdditionalFiles([downloadFileDto]);
          handleAcceptOffer();
          setAcceptLoading(false);
        }, 10000);
      } else if (focusedBankOffer.bank === 'ffb') {
        const downloadFileDto = {
          request_uuid: requestId,
          response_uuid: focusedBankOffer?.id || '',
          bank_id:
            findDictByTitle(DictTypes.Banks, dicts)
              ?.content?.find((bank) => bank.name === focusedBankOffer?.name)
              ?.code?.toString() || '',
          iin: clientInfo?.iin || '',
          bank_payload: {
            subject_identifier: focusedBankOffer.offerId,
            is_pre_approved: focusedBankOffer.statusCode === 'pre_approved'
          }
        };
        setTimeout(async () => {
          await downloadFilesApi.downloadAdditionalFiles([downloadFileDto]);
          handleAcceptOffer();
          setAcceptLoading(false);
        }, 10000);
      } else if (focusedBankOffer.bank === 'bcc') {
        let primaryBody;
        if (focusedBankOffer.responsePrimaryBody.trim())
          primaryBody = JSON.parse(focusedBankOffer.responsePrimaryBody);
        if (typeof primaryBody === 'string')
          primaryBody = JSON.parse(primaryBody || '');
        if (typeof primaryBody !== 'string')
          primaryBody = JSON.parse(primaryBody?.response || '');
        const downloadFileDto = {
          request_uuid: requestId,
          response_uuid: focusedBankOffer?.id || '',
          bank_id:
            findDictByTitle(DictTypes.Banks, dicts)
              ?.content?.find((bank) => bank.name === focusedBankOffer?.name)
              ?.code?.toString() || '',
          iin: clientInfo?.iin || '',
          bank_payload: {
            businessKey: primaryBody?.businessKey || '',
            processId: primaryBody?.processId || '',
            code: focusedBankOffer.code || '', // approved accepted_alternative
            viewIdBroker: primaryBody?.viewIdBroker || ''
          }
        };
        setTimeout(async () => {
          await downloadFilesApi.downloadAdditionalFiles([downloadFileDto]);
          handleAcceptOffer();
          setAcceptLoading(false);
        }, 10000);
      } else if (focusedBankOffer?.bank === 'eu_bank') {
        await downloadFilesApi.pnutUebankManually(focusedBankOffer?.id || '');
        setTimeout(async () => {
          // await downloadFilesApi.downloadAdditionalFiles([downloadFileDto]);
          handleAcceptOffer();
          setAcceptLoading(false);
        }, 10000);
      } else if (focusedBankOffer?.bank === 'altyn') {
        const downloadFileDto = {
          request_uuid: requestId,
          response_uuid: focusedBankOffer?.id || '',
          bank_id:
            findDictByTitle(DictTypes.Banks, dicts)
              ?.content?.find((bank) => bank.name === focusedBankOffer?.name)
              ?.code?.toString() || '',
          iin: clientInfo?.iin || '',
          bank_payload: {
            subject_identifier: focusedBankOffer.offerId,
            type: 'grnAltyn',
            name: 'offerAltyn.pdf'
          }
        };
        setTimeout(async () => {
          await downloadFilesApi.downloadAdditionalFiles([downloadFileDto]);
          handleAcceptOffer();
          setAcceptLoading(false);
        }, 10000);
      } else {
        handleAcceptOffer();
        setAcceptLoading(false);
      }
      dispatch(resetOfferUuid());
    }
  };

  const onPrintData = (dataToPrint: TBankForPrint[]) => {
    setIsPrintLoading(true);
    setTimeout(() => {
      notification.success({
        message: 'Данные были успешно загружены!',
        duration: 5
      });
      setIsPrintLoading(false);
    }, 2000);
  };

  const reCreateRequest = async () => {
    try {
      const reCreatedData = await apiConnector.reCreateRequest(requestId);
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const handleCancelReponse = async (bankResponse: TBankOffersAlternative) => {
    const offerStatusData: {
      res_id: string;
      is_offered: boolean;
      offered_uuid: string | null;
      status:
        | 'approved'
        | 'mobile_client'
        | 'declined'
        | 'waiting_bank_response'
        | 'error'
        | 'financed'
        | 'cancelled'
        | 'waiting_of_financing'
        | 'accepted_alternative';
    } = {
      res_id: bankResponse?.id || '',
      is_offered: false,
      offered_uuid: null,
      status: 'cancelled'
    };
    await dispatch(changeOfferStatus(offerStatusData)).then(async () => {
      await apiConnector.cancelBankResponse({
        bankName: bankResponse?.name || '',
        extId: bankResponse?.extId || '',
        requestId: requestId,
        responseId: bankResponse?.id || '',
        userLogin: userInfo?.login || ''
      });
      dispatch(resetOfferUuid());
    });
  };

  return (
    <div className="OfferStep">
      <CustomModal
        editClass="forte"
        handleCancel={() => setShowInfoForForte(false)}
        handleOk={() => setShowInfoForForte(false)}
        isModalOpen={showInfoForForte}
        title="FORTE"
        footer={[]}
      >
        <p>Отказ со стороны Forte банка</p>
      </CustomModal>
      <CustomModal
        isModalOpen={reCreateModal}
        handleOk={() => {
          reCreateRequest();
          setReCreateModal(false);
        }}
        handleCancel={() => setReCreateModal(false)}
        title="Вы уверены что хотите пересоздать заявку?"
        editClass={'cancel'}
        footer={[
          <Button key="back" onClick={() => setReCreateModal(false)}>
            Нет
          </Button>,
          <Button
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={() => {
              reCreateRequest();
              setReCreateModal(false);
            }}
          >
            Да, пересоздать
          </Button>
        ]}
      />
      <PrintModal
        showPrintModal={showPrintModal}
        onCancel={togglePrintModal}
        isPrintLoading={isPrintLoading}
        handlePrint={onPrintData}
      />
      <SpecialConditionsModal
        banksSpecialConditions={specialConditions}
        showSpecialConditionsModal={showSpecialConditionsModal}
        onCancel={toggleSpecialConditionsModal}
      />
      <AcceptModal
        selectedOffer={focusedBankOffer as TBankOffersAlternative}
        showAcceptModal={showAcceptModal}
        onSelectBankOffer={onSelectOffer}
        onCancel={toggleAcceptModal}
        isAcceptLoading={isAcceptLoading}
      />
      <div className="main_header">
        <h4>Предложения от банков</h4>
        <div style={{ display: 'flex' }}>
          {userInfo?.position.code === 5 ? (
            <Button
              onClick={() => setReCreateModal(true)}
              icon={<ReloadOutlined />}
              // loading={responsesUpdating}
            >
              <p>Пересоздать заявку</p>
            </Button>
          ) : null}

          <Button
            onClick={() => updateResponses()}
            icon={<ReloadOutlined />}
            loading={responsesUpdating}
            style={{ marginLeft: '10px' }}
          >
            <p>Обновить</p>
          </Button>
        </div>
      </div>

      <BankOffers
        handleChangeEblankStatus={handleChangeEblankStatus}
        onSelectOffer={onAcceptOfferAlternate}
        handleCancelBankOffer={handleCancelReponse}
        handleShowSpecialConditions={toggleSpecialConditionsModal}
        handleChangeForteStatus={handleChangeForteStatus}
      />
    </div>
  );
};
