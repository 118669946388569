import ClientMainPage from './ClientMain/ClientMainPage';

const MainPage = () => {
  return (
    <>
      <ClientMainPage />
    </>
  );
};

export default MainPage;
