import { PlusOutlined } from '@ant-design/icons';
import '@/styles/TemplateCreateBlock.scss';
import { Button } from 'antd';

type TTemplateCreateBlock = {
  handleShowNewTemplateForm: () => void;
};
const TemplateCreateBlock = ({
  handleShowNewTemplateForm
}: TTemplateCreateBlock) => {
  return (
    <div className="TemplateCreateBlock">
      <Button onClick={handleShowNewTemplateForm}>
        <PlusOutlined
          style={{
            fontSize: '50px'
          }}
        />
      </Button>
    </div>
  );
};

export default TemplateCreateBlock;
