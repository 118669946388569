import { useEffect, useState } from 'react';
import { Input, Select, Spin, Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import '@/styles/AutoCred.scss';
import { useAppDispatch, useAppSelector } from '@/store';
import { useRequestsChangeNavigation } from '@/hooks/useRequestsChangeNavigation';
import { TDataSource } from '@/types/dataSource';
import { cleanNameValue, cleanPhoneNumber } from '@/services/formatter';
import { apiConnector } from '@/integrations/api.connector';
import { getFullPageTableHeight } from '@/services';
import { columns } from '@/data/autoCred.data';
import { AutoCredHeader } from '@/components/App/AutoCred/AutocredHeader';
import { setPageTitle } from '@/store/newOrder.slice';
import { onSetOtpRequestToInitial } from '@/store/orderStep.slice';
import { autoCredMapper } from '@/services/mappers/autoCredMapper';

const { Search } = Input;

export const AutoCred = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { banksOffers, reqSuccessIndicator } = useAppSelector(
    (state) => state.offerStep
  );
  const { selectedBank } = useAppSelector((state) => state.financingStep);
  const { userInfo, dealer: userDealer } = useAppSelector(
    (state) => state.userInfo
  );

  const { verifyModalState } = useAppSelector((state) => state.app);

  const { handleRequestDetails, changeData, isRequestLoading } =
    useRequestsChangeNavigation();

  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<string[]>(
    []
  );
  const [selectedDealers, setSelectedDealers] = useState<string[] | '-1'>(
    userDealer?.value ? [userDealer.value] : '-1'
  );
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const [statusFilter, setStatusFilter] = useState<string[] | null>([]);

  const [filteredArray, setFilteredArray] = useState<TDataSource[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [paginationData, setPaginationData] = useState<{
    currentPage: number;
    itemsPerPage: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10
  });
  const [isTableDataLoading, setIsTableLoading] = useState<boolean>(false);
  const statusesParam = selectedStatuses.join(',');

  useEffect(() => {
    const storedFilters = localStorage.getItem('autoCredFilters');
    if (storedFilters) {
      const filters = JSON.parse(storedFilters);
      setSelectedUser(filters.selectedUser);
    } else {
      const userSearchStorage = localStorage.getItem('userSearchType');
      if (userSearchStorage) {
        setSelectedUser(userSearchStorage);
      } else {
        setSelectedUser(userInfo?.uuid || '-1');
      }
    }
  }, [userInfo]);

  useEffect(() => {
    const filters = {
      selectedUser,
      selectedPaymentTypes,
      selectedDealers,
      selectedCities,
      selectedStatuses,
      searchValue,
      paginationData
    };
    localStorage.setItem('autoCredFilters', JSON.stringify(filters));
  }, [
    selectedUser,
    selectedPaymentTypes,
    selectedDealers,
    selectedCities,
    selectedStatuses,
    searchValue,
    paginationData
  ]);

  const getRequestsPerPage = async () => {
    if (selectedDealers === '-1') return;
    if (!selectedUser) return;
    setIsTableLoading(true);

    try {
      const formattedNameSearchValue = cleanNameValue(searchValue);
      const formattedPhoneSearchValue = cleanPhoneNumber(searchValue);
      let searchType: 'fio' | 'iinTelNum' | 'empty' = 'empty';

      if (formattedNameSearchValue === '' && formattedPhoneSearchValue === '') {
        searchType = 'empty';
      } else if (
        formattedNameSearchValue !== '' &&
        formattedPhoneSearchValue === ''
      ) {
        searchType = 'fio';
      } else {
        searchType = 'iinTelNum';
      }

      const data = await apiConnector.getRequestsForPagination({
        city_uuid: selectedCities.length > 0 ? selectedCities : '-1',
        dealer_uuid: selectedDealers.length > 0 ? selectedDealers : '-1',
        status: statusFilter && statusFilter.length >= 0 ? statusFilter : [],
        repayment_type_uuid:
          selectedPaymentTypes.length > 0 ? selectedPaymentTypes : '-1',
        user_uuid: selectedUser !== '-1' ? [selectedUser] : '-1',
        search_query: {
          type: searchType === 'empty' ? 'iinTelNum' : searchType,
          value:
            searchType === 'iinTelNum' ? formattedPhoneSearchValue : searchValue
        },
        pagination: {
          page_number: paginationData.currentPage,
          rows_per_page: paginationData.itemsPerPage
        }
      });

      const dataSource: TDataSource[] = autoCredMapper(data.data);
      setFilteredArray(dataSource);
      setTotalRequests(data.count);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setPageTitle(''));
    dispatch(onSetOtpRequestToInitial());
    const paginationInfoStorage = localStorage.getItem('paginationInfo');
    if (paginationInfoStorage) {
      const parsedPaginationInfo = JSON.parse(paginationInfoStorage);
      setPaginationData(parsedPaginationInfo);
    }
  }, []);

  useEffect(() => {
    if (userDealer?.value) {
      setSelectedDealers([userDealer?.value?.toString() || '']);
    }
  }, [userDealer]);

  useEffect(() => {
    const userSearchStorage = localStorage.getItem('userSearchType');
    if (userSearchStorage) {
      setSelectedUser(userSearchStorage);
    } else {
      userInfo?.uuid ? setSelectedUser(userInfo?.uuid) : setSelectedUser('-1');
    }
  }, [userInfo]);

  useEffect(() => {
    getRequestsPerPage();
  }, [
    selectedUser,
    selectedPaymentTypes,
    selectedDealers,
    selectedCities,
    selectedStatuses,
    searchValue,
    paginationData,
    statusFilter
  ]);

  useEffect(() => {
    if (reqSuccessIndicator === true && location.pathname !== '/newApp') {
      changeData();
    }
  }, [banksOffers, selectedBank]);

  useEffect(() => {
    if (
      !verifyModalState.showVerifyModal.prevClient &&
      !verifyModalState.showVerifyModal.smsVerf
    ) {
      getRequestsPerPage();
    }
  }, [verifyModalState.showVerifyModal]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const statusParam = query.get('statuses');
    if (statusParam) {
      setStatusFilter(statusParam.split(','));
    }
  }, [location.search]);

  const handleStatusChange = (filters: any) => {
    const selectedStatuses = filters.status || [];
    setStatusFilter(selectedStatuses);
    if (selectedStatuses.length === 0) {
      navigate(`/autocred`, { replace: true });
    } else {
      const statusParam = selectedStatuses.join(',');
      navigate(`/autocred?statuses=${statusParam}`, { replace: true });
    }
  };

  const handleUserChange = (optionValue: string) => {
    setSelectedUser(optionValue);
    localStorage.setItem('userSearchType', optionValue);
  };

  return (
    <>
      {isRequestLoading ? (
        <Spin fullscreen={true} />
      ) : (
        <div className="AutoCred">
          <AutoCredHeader />
          <div className="table_editor">
            <Search
              style={{ width: '50%' }}
              size="large"
              placeholder="Поиск по ФИО / ИИН / Номер тел."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onSearch={getRequestsPerPage}
            />
            {/*<Select*/}
            {/*  showSearch*/}
            {/*  optionFilterProp={'label'}*/}
            {/*  style={{ width: '20%' }}*/}
            {/*  size="large"*/}
            {/*  // mode="multiple"*/}
            {/*  placeholder="Все заявки"*/}
            {/*  value={selectedApplications}*/}
            {/*  onChange={setSelectedApplications}*/}
            {/*  options={*/}
            {/*    [*/}
            {/*      { label: 'Мои заявки', value: userInfo?.uuid },*/}
            {/*      { label: 'Все заявки', value: '-1' }*/}
            {/*    ] || []*/}
            {/*  }*/}
            {/*  maxTagCount={'responsive'}*/}
            {/*/>*/}
            {/*<Select*/}
            {/*  showSearch*/}
            {/*  style={{ width: '16%' }}*/}
            {/*  optionFilterProp={'label'}*/}
            {/*  mode="multiple"*/}
            {/*  size="large"*/}
            {/*  placeholder="Все дилеры"*/}
            {/*  maxTagCount={'responsive'}*/}
            {/*  value={selectedDealers}*/}
            {/*  onChange={(e) => setSelectedDealers(e)}*/}
            {/*  options={activeDealersOption || []}*/}
            {/*/>*/}
            {/*<Select*/}
            {/*  showSearch*/}
            {/*  optionFilterProp={'label'}*/}
            {/*  style={{ width: '16%' }}*/}
            {/*  size="large"*/}
            {/*  mode="multiple"*/}
            {/*  value={selectedCities}*/}
            {/*  placeholder="Все города"*/}
            {/*  maxTagCount={'responsive'}*/}
            {/*  onChange={setSelectedCities}*/}
            {/*  options={cityForFilterOption || []}*/}
            {/*/>*/}
            {/*<Select*/}
            {/*  showSearch*/}
            {/*  mode="multiple"*/}
            {/*  optionFilterProp={'label'}*/}
            {/*  style={{ width: '18%' }}*/}
            {/*  size="large"*/}
            {/*  maxTagCount={'responsive'}*/}
            {/*  value={selectedPaymentTypes}*/}
            {/*  onChange={setSelectedPaymentTypes}*/}
            {/*  placeholder="Все типы оплаты"*/}
            {/*  options={paymentTypes || []}*/}
            {/*/>*/}
            {/*{userInfo?.position?.name === 'Директор' && (*/}
            <Select
              showSearch
              optionFilterProp={'label'}
              style={{ width: '22%' }}
              size="large"
              placeholder="Все заявки"
              value={selectedUser}
              onChange={handleUserChange}
              options={
                [
                  { value: userInfo?.uuid, label: 'Мои заявки' },
                  { value: '-1', label: 'Все заявки' }
                ] || []
              }
              maxTagCount={'responsive'}
            />
            {/*)}*/}
            {/*<Select*/}
            {/*  showSearch*/}
            {/*  optionFilterProp={'label'}*/}
            {/*  style={{ width: '16%' }}*/}
            {/*  size="large"*/}
            {/*  mode="multiple"*/}
            {/*  placeholder="Все статусы"*/}
            {/*  value={selectedStatuses}*/}
            {/*  onChange={setSelectedStatuses}*/}
            {/*  options={*/}
            {/*    statusForFilterOptions?.filter((status) => {*/}
            {/*      return !(*/}
            {/*        userInfo?.position?.name === 'Директор' &&*/}
            {/*        status.label === 'Черновик'*/}
            {/*      );*/}
            {/*    }) || []*/}
            {/*  }*/}
            {/*  maxTagCount={'responsive'}*/}
            {/*/>*/}
          </div>
          <div className="table">
            <Table
              rowClassName="auto-cred-table-row"
              size="small"
              showSorterTooltip={true}
              columns={columns}
              
              dataSource={filteredArray}
              rowKey={(record) => record?.id || ''}
              pagination={{
                position: ['bottomLeft'],
                showSizeChanger: true,
                total: totalRequests,
                pageSize: paginationData.itemsPerPage,
                current: paginationData.currentPage,
                onChange: (step: number, itemsPerPage: number) => {
                  setPaginationData({
                    currentPage: step,
                    itemsPerPage: itemsPerPage
                  });
                }
              }}
              scroll={{
                x: 'max-content',
                y: getFullPageTableHeight()
              }}
              onChange={(pagination, filters) => {
                handleStatusChange(filters);
              }}
              
              loading={isTableDataLoading}
              onRow={(r) => {
                return {
                  onClick: () => {
                    // console.log('request from table', r);
                    handleRequestDetails(r);
                  }
                };
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
