import '@/styles/Leasing.scss';
import LeasingHeader from './LeasingHeader';
import { Select, Table } from 'antd';
import { leasingColumnsData } from '@/data/leasingColumns.data';
import { getFullPageTableHeight } from '@/services/helpers';
import { useAppDispatch, useAppSelector } from '@/store';
import { useEffect, useState } from 'react';
import {
  getAllLeasings,
  getLeasingById,
  setSelectedLeasingToInitial
} from '@/store/leasings.slice';
import { useNavigate } from 'react-router-dom';
import Search from 'antd/es/input/Search';
import { TLeasingTableData } from '@/types/leasing';

const Leasing = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { leasings, isLoading } = useAppSelector((state) => state.leasings);

  const { userInfo } = useAppSelector((state) => state.userInfo);

  const [searchType, setSearchType] = useState<string>('all');

  const [searchValue, setSearchValue] = useState<string>('');

  const [filteredLeasingArray, setFilteredLeasingsArray] = useState<
    TLeasingTableData[]
  >([]);

  useEffect(() => {
    dispatch(setSelectedLeasingToInitial());
    dispatch(getAllLeasings());
  }, []);

  useEffect(() => {
    setFilteredLeasingsArray(leasings);
  }, [leasings]);

  const handleSearch = () => {
    const arrayHandler = [...leasings]
      .filter(
        (leasing) =>
          leasing.identificationDocument
            ?.replace(/ /g, '')
            .includes(searchValue) ||
          leasing?.personalPhoneNumber?.replace(/ /g, '').includes(searchValue)
      )
      .filter((leasing) => {
        if (searchType === 'all') {
          return true;
        } else {
          return leasing.user.userUuid === userInfo?.uuid;
        }
      });
    setFilteredLeasingsArray(arrayHandler);
  };

  useEffect(() => {
    handleSearch();
  }, [searchType]);

  return (
    <>
      <div className="Leasing">
        <LeasingHeader />
        <div className="table_editor">
          <Search
            style={{ width: '50%' }}
            size="large"
            placeholder="Поиск по ИИН / Номер тел."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
          />
          <Select
            showSearch
            optionFilterProp={'label'}
            style={{ width: '22%' }}
            size="large"
            placeholder="Все заявки"
            value={searchType}
            onChange={(value) => setSearchType(value)}
            options={[
              { label: 'Все заявки', value: 'all' },
              { label: 'Мои заявки', value: 'mine' }
            ]}
            maxTagCount={'responsive'}
          />
        </div>
        <div className="table">
          <Table
            rowClassName="auto-cred-table-row"
            size="small"
            showSorterTooltip={true}
            columns={leasingColumnsData}
            dataSource={filteredLeasingArray}
            loading={isLoading}
            rowKey={(record) => record?.uuid || ''}
            pagination={{
              position: ['bottomLeft'],
              showSizeChanger: true
            }}
            scroll={{
              x: 'max-content',
              y: getFullPageTableHeight() - 50
            }}
            onRow={(r) => {
              return {
                onClick: async () => {
                  await dispatch(getLeasingById(r.uuid));
                  navigate('/newLeasing');
                }
              };
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Leasing;
