import { TLeasingCreationDto, TServerLeasingsResponse } from '@/types/leasing';

export const leasingItemMapper = (
  leasing: TServerLeasingsResponse
): TLeasingCreationDto => {
  return {
    iin: leasing?.iin || '',
    clientFullName: leasing?.client_full_name || '',
    birthDate: leasing?.birth_date || '',
    licenseMainActivity: leasing.license_main_activity,
    registrationCountry: leasing?.registration_country?.code || null,
    workStartDate: leasing?.work_start_date || '',
    stateRegistrationNumber: leasing?.state_registration_number || '',
    stateRegistrationDate: leasing?.state_registration_date || '',
    propertyType: leasing?.property_type?.code || null,
    placeOfStateRegistration: leasing?.place_of_registration?.code || null,
    okedOfMainAndSubActivity: leasing?.oked_of_main_and_sub_activity || '',
    mainActivity: leasing?.main_activity?.code || null,
    livingAddress: leasing?.living_address || '',
    licenseOrganizationAuthority: leasing?.license_organization_authority || '',
    licenseNum: leasing?.license_num || '',
    legalAddress: leasing?.legal_address || '',
    firstSupervisorFullName: leasing?.first_supervisor_fullName || '',
    email: leasing?.email || '',
    firstRegistrationDate: leasing?.first_registration_date || '',
    documentValidEndDate: leasing?.document_valid_end_date || '',
    documentNumber: leasing?.document_number || '',
    documentIssueDate: leasing?.document_issue_date || '',
    docName: leasing?.doc_name?.code || null,
    contactPhoneNumber: leasing?.contact_phone_number || '',
    bankRequisites: leasing?.bank_requisits || '',
    availabilityLicenses: leasing?.availability_licenses || '',
    additionalPhoneNumber: leasing?.additional_phone_number || '',
    actualAddress: leasing?.actual_address || '',
    status: leasing?.status?.code || ''
  };
};
