import { Col, Input, Modal, Row, Select } from 'antd';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { TSalePoint } from '@/types/salePoint.type';
import { findDictByTitle } from '@/services';
import { DictTypes } from '@/constants';

type TSalePointModalProps = {
  setShow: () => void;
  editMode?: boolean;
  salePointItem: TSalePoint | null;
};

const CreateOrEditSimpleDictModal: FC<TSalePointModalProps> = ({
  setShow,
  editMode = false,
  salePointItem
}) => {
  const dispatch = useAppDispatch();
  const { dicts } = useAppSelector((state) => state.dict);
  const [salePoint, setSalePoint] = useState<TSalePoint>({
    uuid: '',
    name: '',
    is_active: true,
    city_uuid: '',
    dealer_uuid: '',
    address_jur: '',
    address_fact: ''
  });

  const [cityOptions, setCityOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [dealerOptions, setDealerOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (editMode && salePointItem) {
      setSalePoint(salePointItem);
    }
    const cities = findDictByTitle(DictTypes.Cities, dicts).content.map(
      (city) => {
        return { value: city.uuid, label: city.name };
      }
    );
    setCityOptions(cities);

    const dealers = findDictByTitle(DictTypes.Dealers, dicts).content.map(
      (dealer) => {
        return { value: dealer.uuid, label: dealer.name };
      }
    );
    setDealerOptions(dealers);
  }, []);

  const onOk = async () => {
    if (editMode) {
      return setShow();
    }
    setShow();
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSalePoint((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  return (
    <Modal
      title="Добавить новый элемент"
      open={true}
      onOk={onOk}
      onCancel={() => setShow()}
    >
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Input
                placeholder="Название"
                value={salePoint.name}
                onChange={onInputChange}
                style={{ marginBottom: '10px' }}
                name="name"
              />
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  )
                }
                style={{ width: '100%' }}
                options={cityOptions}
                value={salePoint.city_uuid}
                onChange={(value) => {
                  setSalePoint((prevState) => ({
                    ...prevState,
                    city_uuid: value
                  }));
                }}
              />
            </Col>
            <Col span={10} offset={2}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  )
                }
                style={{ width: '100%' }}
                options={dealerOptions}
                value={salePoint.dealer_uuid}
                onChange={(value) => {
                  setSalePoint((prevState) => ({
                    ...prevState,
                    dealer_uuid: value
                  }));
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        {/*<Input*/}
        {/*  placeholder="Код"*/}
        {/*  value={salePoint.code}*/}
        {/*  name="code"*/}
        {/*  type="text"*/}
        {/*  onChange={onInputChange}*/}
        {/*/>*/}
      </div>
    </Modal>
  );
};

export default CreateOrEditSimpleDictModal;
