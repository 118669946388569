import { TSpecialCondition } from '@/types/offers';
import React from 'react';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { formatPrice } from '@/services/formatter';

export type TSpecialConditionsModalProps = {
  banksSpecialConditions: TSpecialCondition[];
  showSpecialConditionsModal: boolean;
  onCancel: () => void;
};
export const SpecialConditionsModal = ({
  showSpecialConditionsModal,
  banksSpecialConditions,
  onCancel
}: TSpecialConditionsModalProps) => {
  return (
    <CustomModal
      isModalOpen={showSpecialConditionsModal}
      handleOk={onCancel}
      handleCancel={onCancel}
      title="Особые условия"
      editClass="special_conditions_modal"
      footer={[]}
    >
      <div>
        {banksSpecialConditions.map((condition: TSpecialCondition) => {
          return (
            <div
              className="special_condition_data_root"
              key={condition.contract_id}
            >
              <div>
                <h5>Номер контракта</h5>
                <p>{condition.contract_id}</p>
              </div>
              <div>
                <h5>Банк</h5>
                <p>{condition.bank}</p>
              </div>
              <div>
                <h5>Месячная оплата по кредиту</h5>
                <p>{formatPrice(condition.monthlyLoanPayment)} ₸</p>
              </div>
              <div>
                <h5>Общая сумма кредита</h5>
                <p>{formatPrice(condition.overallLoanAmount)} ₸</p>
              </div>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};
