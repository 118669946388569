import { AxiosInstance } from 'axios';
import { TDealer, TDealerDto } from '@/types/dealer.type';

export class DealersApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllDealers(
    limit?: number,
    page?: number,
    param?: string,
    filterParam?: string
  ): Promise<{
    total: number;
    dealers: TDealer[];
  }> {
    let path = `admin/dealers/getDealers/${param ? param : ''}?`;
    if (limit) {
      path = path + `&limit=${limit}`;
    }
    if (page) {
      path = path + `&page=${page}`;
    }
    if (filterParam) {
      path = path + `&is_active=${filterParam}`;
    }
    const res = await this.api.get(path);
    return res.data;
  }

  public async getAdminDealerByParam(
    param: string | number
  ): Promise<TDealer[]> {
    return await this.api.get(`admin/dealers/${param}/findByParam`);
  }

  public async createAdminDealer(dealer: TDealerDto) {
    return await this.api.post(`admin/dealers/createDealer`, dealer);
  }

  public async updateAdminDealer(dealer: TDealer) {
    return await this.api.patch(
      `admin/dealers/${dealer.uuid}/updateDealer`,
      dealer
    );
  }
}
