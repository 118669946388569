import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  registerables,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

export type TVerticalChartBarProps = {
  labels: string[];
  datasets: {
    label: string;
    borderRadius?: number;
    bgColor: string;
    data: number[];
  }[];
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
);

export const options = {
  responsive: true,
  labels: {
    display: false
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        paddingContentVertical: 20,
        font: {
          size: 10
        }
      },
      padding: {
        bottom: 50
      }
    }
  },
  scales: {
    x: {
      ticks: {
        min: 10,
        max: 100,
        stepSize: 1,
        maxBarThickness: 20,
        display: false
      },
      grid: {
        display: false,
        stepSize: 1
      }
    },
    y: {
      ticks: {
        min: 100,
        max: 100,
        stepSize: 10
      },
      grid: {
        display: false,
        stepSize: 1
      }
    }
  }
};

const VerticalChartBar = ({ datasets, labels }: TVerticalChartBarProps) => {
  return (
    <div>
      <Bar
        //@ts-ignore
        options={options}
        data={{
          labels: labels,
          datasets: datasets.map((set) => {
            return {
              label: set.label,
              borderRadius: set?.borderRadius || 4,
              data: set.data,
              backgroundColor: set.bgColor,
              barThickness: 20,
              barPercentage: 2
            };
          })
        }}
      />
    </div>
  );
};

export default VerticalChartBar;
