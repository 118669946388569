import {
  pathExpandLogo,
  pathLogo,
  pathQuestionicon,
  pathSettingIcon,
  sideBarItems
} from '@/constants';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { onSetSelectedBankToInitial } from '@/store/financingStep.slice';
import { setTargetedClientToInitial } from '@/store/clients.slice';
import { resetReqSuccessIndicator } from '@/store/offerStep.slice';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { AdminNavBar } from '@/components/AdminPanel/NavBar/AdminNavBar';

export const Navbar = () => {
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState<string>('main');
  const [isSidebarExpanded, setSidebarExpanded] = useState<boolean>(true);
  const [menuItems, setMenuItems] = useState<
    {
      label: string;
      key: string;
      iconPath: string;
      rolesPermitted: string[];
    }[]
  >(sideBarItems); // Устанавливаем начальное значение меню

  const onClick = (key: string) => {
    dispatch(onSetSelectedBankToInitial());
    dispatch(setTargetedClientToInitial());
    dispatch(resetReqSuccessIndicator());
    setCurrent(key === 'main' ? '' : key);
    navigate(`/${key === 'main' ? '' : key}`, { replace: true });
  };

  useEffect(() => {
    if (
      userInfo?.position?.name !== 'Отчеты' &&
      userInfo?.position?.name !== 'Администратор'
    ) {
      const filteredMenu = sideBarItems.filter(
        (item) => item.key !== 'reports'
      );
      setMenuItems(filteredMenu);
    } else {
      setMenuItems(sideBarItems); // Для позиций "Отчеты" и "Администратор" показать все элементы меню
    }
  }, [userInfo]);

  useEffect(() => {
    let key = location.pathname.replace('/', '');
    if (key === '') key = 'main';
    if (key !== current) setCurrent(key);
  }, [location]);

  return (
    <aside
      className={`side-bar ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}
    >
      <div className="logo_box">
        <NavLink to="/autocred">
          <img src={pathLogo} alt="logo" />
        </NavLink>
        <button onClick={() => setSidebarExpanded(!isSidebarExpanded)}>
          <img src={pathExpandLogo} alt="logo_expand" />
        </button>
      </div>
      <nav className={'navigation_box'}>
        {location.pathname.includes('/adminPage') ? (
          <AdminNavBar isSidebarExpanded={isSidebarExpanded} />
        ) : (
          userInfo?.frontendBlock
            .slice()
            .filter((item) => item.is_active)
            .sort((a, b) => a.position - b.position)
            .map((e) => {
              return (
                <button
                  style={{ width: isSidebarExpanded ? '220px' : '56px' }}
                  className={current === e.code ? 'active' : ''}
                  onClick={() => onClick(e.code)}
                  key={e.uuid}
                >
                  <Tooltip title={e?.name} placement="right">
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={e.icon}
                      alt="setting_icon"
                    />
                  </Tooltip>
                  <p>{e?.name}</p>
                </button>
              );
            })
          // menuItems.map((e) => {
          //   return (
          //     <button
          //       style={{ width: isSidebarExpanded ? '220px' : '56px' }}
          //       className={current === e.key ? 'active' : ''}
          //       onClick={() => onClick(e.key)}
          //       key={e.label}
          //     >
          //       <Tooltip title={e?.label} placement="right">
          //         <img
          //           style={{ width: '24px', height: '24px' }}
          //           src={e.iconPath}
          //           alt="setting_icon"
          //         />
          //       </Tooltip>
          //       <p>{e?.label}</p>
          //     </button>
          //   );
          // })
        )}
      </nav>
      {!location.pathname.includes('/adminPage') ? (
        <div className="support_setting_box">
          <button>
            <img src={pathQuestionicon} alt="support_icon" />
            <p>Поддержка</p>
          </button>
          <button>
            <img src={pathSettingIcon} alt="setting_icon" />
            <p>Настройки</p>
          </button>
        </div>
      ) : null}
    </aside>
  );
};
