import { FC } from 'react';
import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import '@/styles/adminTablePagination.scss';

type TSqlProps = {
  array: any[];
};

export const SqlResponseRender: FC<TSqlProps> = ({ array }) => {
  return (
    <div style={{ maxWidth: '1112px' }}>
      {array && (
        <Table
          style={{ marginTop: '20px' }}
          rowKey="uuid"
          size="small"
          dataSource={array}
          scroll={{ x: true }}
          pagination={{ position: ['bottomLeft'], showSizeChanger: true }}
        >
          {array
            ? Object.keys(array[0]).map((key) => (
                <Column
                  ellipsis={true}
                  width={100}
                  title={key}
                  dataIndex={key}
                  key={key}
                />
              ))
            : []}
        </Table>
      )}
    </div>
  );
};
