import { FC, useEffect, useState } from 'react';
import { Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { TBank, TBankDto } from '@/types/bank.type';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  createBank,
  getAllBanks,
  updateBank
} from '@/store/admin/adminBanks.slice';

type TBankModal = {
  editMode: boolean;
  setShow: () => void;
  bankItem: TBank | null;
};
export const CreateOrEditBankModal: FC<TBankModal> = ({
  editMode,
  setShow,
  bankItem
}) => {
  const dispatch = useAppDispatch();
  const { showModalCreateOrEditBank } = useAppSelector((state) => state.admin);
  const [bank, setBank] = useState<TBank | TBankDto>({} as TBank);

  useEffect(() => {
    if (editMode && bankItem) {
      setBank(bankItem);
    }
  }, [editMode, bankItem]);

  const onOk = () => {
    if (editMode) {
      dispatch(updateBank(bank as TBank)).then(() => {
        dispatch(getAllBanks());
      });

      return;
    }
    dispatch(createBank(bank)).then(() => {
      dispatch(getAllBanks());
    });
  };

  return (
    <Modal
      className="modal"
      open={showModalCreateOrEditBank}
      onCancel={setShow}
      onOk={onOk}
    >
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Row>
              <Col span={11}>
                <Form.Item
                  // name="name"
                  label="Краткое название банка"
                  rules={[{ required: true }]}
                >
                  <Input
                    value={bank.name}
                    onChange={(e) =>
                      setBank((prevState) => ({
                        ...prevState,
                        name: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  // name="fullname"
                  label="Полное название банка"
                  rules={[{ required: true }]}
                >
                  <Input
                    value={bank.fullname}
                    onChange={(e) =>
                      setBank((prevState) => ({
                        ...prevState,
                        fullname: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Код">
                  <Input
                    value={bank.code}
                    onChange={(e) =>
                      setBank((prevState) => ({
                        ...prevState,
                        code: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={3} offset={2}>
                <Form.Item label="Статус">
                  <Checkbox
                    checked={bank.is_active}
                    onChange={() =>
                      setBank((prevState) => ({
                        ...prevState,
                        is_active: !bank.is_active
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Имя хоста">
                  <Input
                    value={bank.host_name || ''}
                    onChange={(e) =>
                      setBank((prevState) => ({
                        ...prevState,
                        host_name: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item label="Логин">
                  <Input
                    value={bank.login}
                    onChange={(e) =>
                      setBank((prevState) => ({
                        ...prevState,
                        login: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Пароль">
                  <Input
                    value={bank.password}
                    onChange={(e) =>
                      setBank((prevState) => ({
                        ...prevState,
                        password: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item label="Send token">
                  <Input
                    value={bank.send_token}
                    onChange={(e) =>
                      setBank((prevState) => ({
                        ...prevState,
                        send_token: e.target.value
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item label="Timeout">
                  <Input
                    value={bank.timeout}
                    onChange={(e) =>
                      setBank((prevState) => ({
                        ...prevState,
                        timeout: Number(e.target.value)
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
