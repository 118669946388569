import { AxiosInstance } from 'axios';
import { TPosition } from '@/types/position.type';

export class PositionsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllPositions(): Promise<{
    total?: number;
    positions: TPosition[];
  }> {
    const res = await this.api.get('admin/positions/getAll');
    return res.data;
  }

  public async createPosition(data: TPosition) {
    const res = await this.api.post('admin/positions/create', data);
    return res.data;
  }

  public async updatePosition(data: TPosition) {
   
    const res = await this.api.patch(
      `admin/positions/${data.uuid}/update`,
      data
    );
    return res.data;
  }
}
